import React from 'react';
import { Input } from '@mui/material';

const SwitchDiscount = (props: any) => {
  const item: any = props.data.item;
  const value =
    props.data.discType === 'absolute'
      ? props.data.discountConfig.absolute
      : props.data.discountConfig.percentage;
  return (
    <>
      <div className="desc-wrapper">
        <div className="select-desc-wrapper">
          <div
            className={`select-desc-item ${
              props.data.discType === 'absolute' ? 'selected' : ''
            }`}
            onClick={() => props.data.handleChangeDiscType('absolute')}
          >
            <p
              className={`${
                props.data.discType === 'absolute' ? 'selected' : ''
              }`}
            >
              Per unit
            </p>
          </div>
          <div
            className={`select-desc-item ${
              props.data.discType === 'percentage' ? 'selected' : ''
            }`}
            onClick={() => props.data.handleChangeDiscType('percentage')}
          >
            <p
              className={`${
                props.data.discType === 'percentage' ? 'selected' : ''
              }`}
            >
              %
            </p>
          </div>
        </div>
        <div className="catalog-modal-item-qty">
          <Input
            type="number"
            value={value || ''}
            onChange={(event: any) =>
              props.data.handleChangeDiscount(
                item.product_code,
                props.data.index,
                item,
                event.target.value
              )
            }
            onBlur={(event: any) => {
              props.data.handleChangeDiscountBlur(
                item.product_code,
                props.data.index,
                item['product_quantity'],
                event.target.value
              );
            }}
            onKeyDown={(event: any) => {
              props.formatInput(event, false);
            }}
            placeholder="Discount"
            className="catalog-qty-input"
          />
        </div>
      </div>
      {value && !props.data.isAllow ? (
        <p style={{ margin: 0, fontSize: 12, color: 'red', textAlign: 'end' }}>
          Discount corrected to max discount allowed
        </p>
      ) : (
        ''
      )}
    </>
  );
};

export default SwitchDiscount;
