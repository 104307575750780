import * as types from '../../../actions/types';
import { decodeAccessToken } from '../../../utils/helper';

const initialState = {
  error: {
    displayMessage: '',
    data: null,
  },
  open: false,
  accessTokenForTeam: '',
  getaccessTokenForTeam: false,
  userTeamDetails: {},
  teamUserDetailsSuccess: false,
  userDesignationDetails: {},
  userDesignationDetailsReqSuccess: false,
  userDirectoryDesignation: [],
  userDirectoryDesignationReqSuccess: false,
  userDirectoryDesignationSpinner: false,
};

const homeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.RESET_ERROR_MESSAGE:
      return {
        ...state,
        open: false,
        error: {
          displayMessage: '',
        },
      };

    case types.ACCESS_TOKEN_FOR_TEAM_REQUEST:
      return {
        ...state,
        accessTokenForTeam: '',
        getaccessTokenForTeam: false,
      };

    case types.ACCESS_TOKEN_FOR_TEAM_FAILURE:
      return {
        ...state,
        accessTokenForTeam: '',
        getaccessTokenForTeam: false,
      };

    case types.ACCESS_TOKEN_FOR_TEAM_SUCCESS:
      return {
        ...state,
        accessTokenForTeam: action.payload['access_token'],
        permissions: decodeAccessToken(action.payload['access_token'])
          .permissions,
        getaccessTokenForTeam: true,
      };

    case types.RESET_GET_ACCESS_TOKEN_TEAM_REQUEST:
      return {
        ...state,
        getaccessTokenForTeam: false,
      };

    case types.GET_TEAM_USER_DETAILS_REQUEST:
      return {
        ...state,
        teamUserDetailsSuccess: false,
      };

    case types.GET_TEAM_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userTeamDetails: action.payload,
        teamUserDetailsSuccess: true,
      };

    case types.GET_TEAM_USER_DETAILS_FAILURE:
      return {
        ...state,
        teamUserDetailsSuccess: false,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_TEAM_USER_DETAILS_REQUEST:
      return {
        ...state,
        teamUserDetailsSuccess: false,
      };

    case types.GET_USER_DESIGNATION_DETAILS_REQUEST:
      return {
        ...state,
        userDesignationDetails: {},
        userDesignationDetailsReqSuccess: false,
      };

    case types.GET_USER_DESIGNATION_DETAILS_SUCCESS:
      return {
        ...state,
        userDesignationDetails: action.payload,
        userDesignationDetailsReqSuccess: true,
      };

    case types.GET_USER_DESIGNATION_DETAILS_FAILURE:
      return {
        ...state,
        userDesignationDetailsReqSuccess: false,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_USER_DESIGNATION_DETAILS_REQUEST:
      return {
        ...state,
        userDesignationDetailsReqSuccess: false,
      };

    case types.GET_USER_DIRECTORY_DESIGNATION_REQUEST:
      return {
        ...state,
        userDirectoryDesignationReqSuccess: false,
        userDirectoryDesignationSpinner: true,
      };

    case types.GET_USER_DIRECTORY_DESIGNATION_SUCCESS:
      return {
        ...state,
        userDirectoryDesignation: action.payload,
        userDirectoryDesignationReqSuccess: true,
        userDirectoryDesignationSpinner: false,
      };

    case types.GET_USER_DIRECTORY_DESIGNATION_FAILURE:
      return {
        ...state,
        userDirectoryDesignationReqSuccess: false,
        userDirectoryDesignationSpinner: false,
        userDirectoryDesignation: [],
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_USER_DIRECTORY_DESIGNATION_REQUEST:
      return {
        ...state,
        userDirectoryDesignationReqSuccess: false,
        userDirectoryDesignationSpinner: false,
      };

    default:
      return state;
  }
};

export default homeReducer;
