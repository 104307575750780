import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Drawer from './feature/drawer/pages/drawer';
import Home from './feature/workflow/pages';
import Inbox from './feature/workflow/pages/inbox/index';
import Open from './feature/workflow/pages/open/index';
import Form1 from './feature/workflow/pages/form/form';
import CreateLookupForm from './feature/workflow/pages/form/createLookupForm';
import Details from './feature/workflow/pages/inbox/details';
import PrivateRoute from './privateRoutesWrapper';

const MemberRoutes = () => (
  <>
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/*" element={<Drawer />}>
          <Route path="home" element={<Home />} />
          {/* <Route path={`reports`} element={<Home />} /> */}
          <Route path=":teamId/inbox/:templateId" element={<Inbox />} />
          <Route path=":teamId/open/:templateId" element={<Open />} />
          <Route path=":teamId/create/:templateId" element={<Form1 />} />
          <Route
            path=":teamId/inbox/:templateId/update/:instanceId/:stageName"
            element={<Form1 />}
          />
          <Route
            path=":teamId/create/secondary/:templateId"
            element={<CreateLookupForm />}
          />
          <Route
            path=":teamId/inbox/:templateId/details/:instanceId"
            element={<Details />}
          />
        </Route>
      </Route>
    </Routes>
  </>
);

export default MemberRoutes;
