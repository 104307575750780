import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TableFooter,
  TableRow,
  TablePagination,
  Button,
  Table,
  CircularProgress,
  Modal,
  Badge,
} from '@mui/material';
import * as _ from 'lodash';

import {
  getCatalogData,
  getNeProductFilterOptions,
} from './../../../actions/report';
import CatalogItemNaveenEnt from './../../catalog/catalogItemNaveenEnt';
import SummaryViewNaveenEnt from './../../catalog/summaryViewNaveenEnt';
import AlertDailog from './../../../component/alertDailog';
import SearchData from './../../../component/searchData';
import ProductFilterData from './../../catalog/productFilterData';
import {
  isMaxDiscountAllow,
  getMaxAllowDiscount,
  getMaxAllowDiscountPercentage,
  getAbsoluteValueFromPercentage,
  catalogAggregateDisplay,
  totalPrice,
  totalDiscAmount,
  taxAmount,
  amountPreTax,
  amountPostTax,
} from './../../catalog/helper';
import * as types from '../../../../../actions/types';
import './../../../styles/catalog/catalog.css';
import { isNull } from 'utils/helper';
import withRouter from 'withRouter';
import { filterQuery } from 'feature/workflow/component/helper';

class CatalogNaveenEnt extends Component<any, any> {
  wrapperRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      rowsPerPage: 10,
      page: 0,
      totalItems: 0,
      currency: 'INR',
      selectedItemsList: [],
      data: [],
      info: false,
      addMore: false,
      openAlertModal: false,
      productCode: '',
      blurIndex: null,
      quantity: null,
      searchText: '',
      expandProductCode: '',
      discountConfig: {
        percentage: '',
        absolute: '',
      },
      discType: 'absolute',
      openQuantityDailog: false,
      isAllow: true,
      showClearButton: false,
      filterCount: 0,
      openFilter: true,
      tabs: [
        {
          name: 'brand',
          label: 'Brand Name',
          type: 'picker',
          options: [],
          value: [],
        },
        {
          name: '_mo_subdivision',
          label: 'Subdivision',
          type: 'picker',
          options: [],
          value: [],
        },
        {
          name: 'category',
          label: 'Category',
          type: 'picker',
          options: [],
          value: [],
        },
      ],
    };
  }

  /**
   * Check any data present, if yes then show the selected item list page
   * Otherwise show all the catalog items
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (
      this.props.attributes &&
      this.props.attributes.value &&
      this.props.attributes.value.values &&
      this.props.attributes.value.values.length > 0
    ) {
      this.setState({
        selectedItemsList: this.props.attributes.value.values,
      });
    } else {
      this.getCatalogDataReq(0, '');
    }
    this.initDefaultFilterOptions();
    this.props.getNeProductFilterOptions(this.getCatalogTemplateId(), '');
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate() {
    if (this.props.catalogDataSuccess) {
      this.props.dispatch({ type: types.RESET_GET_CATALOG_DATA_REQUEST });
      this.generateCatalogData(this.props.catalogData);
    }
    if (this.props.productFilterOptionsSuccess) {
      this.props.dispatch({
        type: types.RESET_NE_PRODUCT_FILTER_OPTIONS_REQUEST,
      });
      this.initOptions(this.props.productFilterOptions);
    }
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  };

  /**
   * Store catalog data from API to data
   */
  generateCatalogData = (catalogData: any) => {
    const data: any = [];
    catalogData.data &&
      catalogData.data.length > 0 &&
      catalogData.data.map((item: any) => {
        data.push(item.data);
        return null;
      });
    this.setState({ data, totalItems: catalogData.total_items }, () => {
      this.addCatalogQty();
    });
  };

  initDefaultFilterOptions = () => {
    const { tabs, filterCount } = this.getUpdateTabsAndCount();
    this.setState({ tabs, filterCount });
  };

  initOptions = (data: any) => {
    const updatedTabs: any = this.state.tabs;
    this.state.tabs.length > 0 &&
      this.state.tabs.map((item: any, index: number) => {
        const options =
          data[item.name] && data[item.name]['options']
            ? data[item.name]['options']
            : null;
        const label =
          data[item.name] && data[item.name]['label']
            ? data[item.name]['label']
            : '';
        if (options) {
          const sortedOptions = _.sortBy(options);
          updatedTabs[index].options = sortedOptions;
          updatedTabs[index].label = label ? label : updatedTabs[index].label;
        }
      });
    this.setState({ tabs: updatedTabs });
  };

  getUpdateTabsAndCount = () => {
    const updatedTabs: any = this.state.tabs;
    let filterCount = 0;
    this.state.tabs.length > 0 &&
      this.state.tabs.map((item: any, index: number) => {
        if (this.props.searchParams && this.props.searchParams[item.name]) {
          updatedTabs[index].value =
            this.props.searchParams[item.name].split(',');
          filterCount += 1;
        }
      });
    return { tabs: updatedTabs, filterCount };
  };
  /**
   * Add quantity from old or new selected to local state data
   */
  addCatalogQty = () => {
    const stateCopy: any = Object.assign({}, this.state);
    const allItems = [...stateCopy.selectedItemsList];
    allItems.length > 0 &&
      allItems.map((item: any) => {
        const index = _.findIndex(stateCopy.data, {
          product_code: item.product_code,
        });
        if (index >= 0) {
          stateCopy.data[index].product_quantity = item.product_quantity;
        }
        return null;
      });
    this.setState(stateCopy);
  };

  getCatalogTemplateId = () => {
    return (
      this.props.attributes &&
      this.props.attributes.data_source &&
      this.props.attributes.data_source.referenceId
    );
  };

  /**
   * get catalog data request
   */
  getCatalogDataReq = (offset: number, qs: string) => {
    let query = !isNull(this.props.queryParams) ? this.props.queryParams : '';
    if (!isNull(qs)) {
      query = !isNull(query) ? `${query}&${qs}` : `${qs}`;
    }
    this.props.getCatalogData({
      templateId: this.getCatalogTemplateId(),
      teamId: this.props.router.params.teamId,
      offset,
      limit: 10,
      queryString: query,
    });
  };

  handleChangeProductQty = (productCode: any, index: number, value: any) => {
    const stateCopy: any = Object.assign({}, this.state);
    const selectedItemsList: any = stateCopy.selectedItemsList;
    const maxLength = this.props.attributes.max_length || 4;
    if (value >= 0 && value.length <= maxLength) {
      const checkItemIndex = _.findIndex(stateCopy.selectedItemsList, {
        product_code: productCode,
      });

      /**
       * Check record exists in selectedItemsList, if exists update product_quantity
       * Otherwise update product_quantity in total data and push the record to selectedItemsList
       */
      if (checkItemIndex >= 0) {
        selectedItemsList[checkItemIndex].product_quantity = parseFloat(value);
        const itemData = this.getLineItemFields(
          selectedItemsList[checkItemIndex]
        );
        selectedItemsList[checkItemIndex] = itemData;
        if (stateCopy.data[index]) {
          stateCopy.data[index].product_quantity = parseFloat(value);
        }
      } else {
        stateCopy.data[index].product_quantity = parseFloat(value);
        const itemData = this.getLineItemFields(stateCopy.data[index]);
        selectedItemsList.push(itemData);
      }

      stateCopy.selectedItemsList = selectedItemsList;
      stateCopy.productCode = '';
      stateCopy.blurIndex = null;
      stateCopy.quantity = null;
      this.setState(stateCopy);
    }
  };

  openValidateQuantity = (productCode: any, blurIndex: any, quantity: any) => {
    if (
      this.props.attributes.threshold &&
      quantity > this.props.attributes.threshold
    ) {
      this.setState({ openAlertModal: true, productCode, blurIndex, quantity });
    }
  };

  closeValidateQuantity = (option: string) => {
    if (option === 'yes') {
      this.setState({ openAlertModal: false }, () => {
        this.handleChangeProductQty(
          this.state.productCode,
          this.state.blurIndex,
          this.state.quantity
        );
      });
    } else {
      this.setState({ openAlertModal: false }, () => {
        this.handleChangeProductQty(
          this.state.productCode,
          this.state.blurIndex,
          ''
        );
      });
    }
  };

  getFilterSearchQuery = () => {
    let queryString = filterQuery(this.state.tabs);
    if (!isNull(this.state.searchText)) {
      queryString = queryString
        ? `${queryString}&_ts_=${this.state.searchText}`
        : `_ts_=${this.state.searchText}`;
    }
    return queryString;
  };

  searchData = (data: string) => {
    if (this.state.searchText.trim() !== '' && data === 'search') {
      this.setState({ page: 0, showClearButton: true }, () => {
        this.getCatalogDataReq(0, this.getFilterSearchQuery());
      });
    } else {
      this.setState(
        {
          searchText: '',
          page: 0,
          showClearButton: false,
        },
        () => {
          this.getCatalogDataReq(0, this.getFilterSearchQuery());
        }
      );
    }
  };

  onChangeSearchData = (event: any) => {
    this.setState(
      { searchText: event.target.value, showClearButton: false },
      () => {
        if (this.state.searchText.trim() === '') this.searchData('search');
      }
    );
  };

  handleChangeOpenFilter = () => {
    this.setState({ openFilter: !this.state.openFilter });
  };

  handleChangeOption = (name: any) => (value: any) => {
    const stateCopy: any = Object.assign({}, this.state);
    const index = this.state.tabs.findIndex((item: any) => item.name === name);
    stateCopy.tabs[index].value = value;
    this.setState(stateCopy, () => {
      this.getFilterOptions();
    });
  };

  getFilterOptions = () => {
    let queryString = '';
    queryString = filterQuery(this.state.tabs);
    this.props.getNeProductFilterOptions(
      this.getCatalogTemplateId(),
      queryString
    );
  };

  getFilterIcon = () => {
    if (this.showSelectedItemsCond()) {
      return '';
    } else
      return (
        <Badge
          badgeContent={this.state.filterCount}
          classes={{ badge: 'badge', root: 'icon-root' }}
        >
          <i
            className="fas fa-filter"
            style={{
              paddingRight: 5,
              opacity: 1,
              cursor: 'pointer',
              fontSize: '18px',
              color: '#424c9f',
            }}
            onClick={() => {
              this.handleChangeOpenFilter();
            }}
          />
        </Badge>
      );
  };

  catalogTitle = () => {
    const attributes = this.props.attributes;
    return (
      <div
        className="catalog-modal-title-wrapper catalog-new-wrapper"
        style={{
          marginBottom: 0,
          marginTop: 0,
          height: '15%',
          justifyContent: 'space-evenly',
        }}
      >
        <div
          className="catalog-new-close-icon"
          style={{ paddingBottom: 0, marginRight: '2.5%' }}
        >
          <i
            className="far fa-times"
            onClick={() => {
              this.props.closeModal();
            }}
          />
        </div>
        <div className="catalog-new-title-search-wrapper guest-title-search-wrapper">
          <div className={`title ne-title`}>
            <p>{`${attributes['label']}`}</p>
          </div>
          <div className="search-filter-wrapper">
            {this.getFilterIcon()}
            {this.showSelectedItemsCond() ? (
              ''
            ) : (
              <SearchData
                search={this.searchData}
                searchText={this.state.searchText}
                onChangeSearchData={this.onChangeSearchData}
                wrapperWidth="150px"
                background="white"
                searchInputWidth="100px"
                color="black"
                showClearButton={this.state.showClearButton}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  openDiscount = (productCode: string) => {
    const discountConfig = {
      percentage: '',
      absolute: '',
    };
    if (this.state.expandProductCode !== productCode) {
      const obj = [...this.state.selectedItemsList].filter(
        (item: any) => item.product_code === productCode
      );
      if (obj.length > 0) {
        discountConfig.absolute = obj[0]['discount'];
      }
      this.setState({
        expandProductCode: productCode,
        discType: 'absolute',
        discountConfig,
      });
    } else {
      this.setState({
        expandProductCode: '',
        discType: 'absolute',
      });
    }
  };

  closeDiscount = () => {
    const discountConfig = {
      percentage: '',
      absolute: '',
    };
    this.setState({
      expandProductCode: '',
      discType: 'absolute',
      discountConfig,
    });
  };

  handleChangeDiscType = (discType: string) => {
    this.setState({ discType });
  };

  handleChangeDiscount = (
    productCode: any,
    index: number,
    item: any,
    value: any
  ) => {
    if (value >= 0) {
      const stateCopy: any = Object.assign({}, this.state);
      const selectedItemsList: any = stateCopy.selectedItemsList;
      const itemPrice = item['price_per_unit'];
      const itemMaxDiscPercentage = item['discount_percentage'];
      /**
       * Check item exists in selectedItems, if exists update product_quantity
       * Otherwise update product_quantity in total data and newSelectedItems
       */
      let data: any = 0;
      if (stateCopy.discType === 'absolute') {
        const absoluteValue = getMaxAllowDiscount(
          value,
          itemMaxDiscPercentage,
          itemPrice
        );
        const isAllow = isMaxDiscountAllow(
          value,
          itemMaxDiscPercentage,
          itemPrice
        );
        stateCopy.discountConfig.absolute = absoluteValue;
        stateCopy.isAllow = isAllow;
        data = absoluteValue;
      } else {
        const discountPercentage = getMaxAllowDiscountPercentage(
          value,
          itemMaxDiscPercentage
        );
        const discount = getAbsoluteValueFromPercentage(
          itemPrice,
          discountPercentage
        );
        const isAllow = isMaxDiscountAllow(
          getAbsoluteValueFromPercentage(itemPrice, value),
          itemMaxDiscPercentage,
          itemPrice
        );
        stateCopy.isAllow = isAllow;
        stateCopy.discountConfig.percentage = discountPercentage;
        data = discount;
      }

      const checkOldItemIndex = _.findIndex(stateCopy.selectedItemsList, {
        product_code: productCode,
      });
      if (checkOldItemIndex >= 0) {
        selectedItemsList[checkOldItemIndex].discount = data;
        const itemData = this.getLineItemFields(
          selectedItemsList[checkOldItemIndex]
        );
        selectedItemsList[checkOldItemIndex] = itemData;
        if (stateCopy.data[index]) {
          stateCopy.data[index].discount = data;
        }
      } else {
        stateCopy.data[index].discount = data;
        const itemData = this.getLineItemFields(stateCopy.data[index]);
        selectedItemsList.push(itemData);
      }
      stateCopy.selectedItemsList = selectedItemsList;
      this.setState(stateCopy);
    }
  };

  openDiscQtyValidation = (
    productCode: any,
    blurIndex: number,
    quantity: any,
    value: any
  ) => {
    if (!quantity && value) {
      this.setState({ productCode, blurIndex, openQuantityDailog: true });
    }
  };

  closeDiscQtyValidation = (option: string) => {
    if (option === 'yes') {
      this.setState({
        openQuantityDailog: false,
        productCode: '',
        blurIndex: null,
      });
    } else {
      const stateCopy: any = Object.assign({}, this.state);
      const checkOldItemIndex = _.findIndex(stateCopy.selectedItemsList, {
        product_code: this.state.productCode,
      });
      if (checkOldItemIndex >= 0) {
        stateCopy.selectedItemsList[checkOldItemIndex].discount = 0;
      }
      if (stateCopy.data[this.state.blurIndex]) {
        stateCopy.data[this.state.blurIndex].discount = 0;
      }
      stateCopy.openQuantityDailog = false;
      stateCopy.blurIndex = null;
      stateCopy.productCode = '';
      this.setState(stateCopy);
      this.closeDiscount();
    }
  };

  allCatalogItemsUI = () => {
    return !this.props.catalogDataSpinner ? (
      this.state.data &&
        this.state.data.length > 0 &&
        this.state.data.map((item: any, index: number) => {
          return (
            <CatalogItemNaveenEnt
              key={index}
              index={index}
              item={item}
              handleChangeProductQty={this.handleChangeProductQty}
              attributes={this.props.attributes}
              handleChangeBlur={this.openValidateQuantity}
              openDiscount={this.openDiscount}
              closeDiscount={this.closeDiscount}
              expandProductCode={this.state.expandProductCode}
              discType={this.state.discType}
              discountConfig={this.state.discountConfig}
              handleChangeDiscType={this.handleChangeDiscType}
              handleChangeDiscount={this.handleChangeDiscount}
              handleChangeDiscountBlur={this.openDiscQtyValidation}
              isAllow={this.state.isAllow}
            />
          );
        })
    ) : (
      <div className="catalog-data-spinner">
        <CircularProgress
          style={{ width: '80px', height: '80px', color: '#6471de' }}
        />
      </div>
    );
  };

  selectedCatalogItemsUI = () => {
    const data = [...this.state.selectedItemsList];
    return (
      data.length > 0 &&
      data.map((item: any, index: number) => {
        return (
          <CatalogItemNaveenEnt
            key={index}
            index={index}
            item={item}
            handleChangeProductQty={this.handleChangeProductQty}
            attributes={this.props.attributes}
            handleChangeBlur={this.openValidateQuantity}
            openDiscount={this.openDiscount}
            closeDiscount={this.closeDiscount}
            expandProductCode={this.state.expandProductCode}
            discType={this.state.discType}
            discountConfig={this.state.discountConfig}
            handleChangeDiscType={this.handleChangeDiscType}
            handleChangeDiscount={this.handleChangeDiscount}
            handleChangeDiscountBlur={this.openDiscQtyValidation}
            isAllow={this.state.isAllow}
          />
        );
      })
    );
  };

  handleChangeAddMore = () => {
    const { tabs, filterCount } = this.getUpdateTabsAndCount();
    this.getCatalogDataReq(0, '');
    this.setState({
      addMore: true,
      info: false,
      expandProductCode: '',
      tabs,
      filterCount,
    });
  };

  handleClickInfo = () => {
    this.setState({
      info: true,
      addMore: false,
      page: 0,
      expandProductCode: '',
    });
  };

  showSelectedItemsCond = () => {
    return (
      ((this.props.attributes &&
        this.props.attributes.value &&
        this.props.attributes.value.values &&
        this.props.attributes.value.values.length > 0) ||
        this.state.info) &&
      !this.state.addMore
    );
  };

  generateCatalogListUI = () => {
    return (
      <div
        className={`catalog-modal-content-wrapper guest-catalog-modal-content-wrapper guest-ne-catalog-modal-content-wrapper`}
      >
        {((this.props.attributes &&
          this.props.attributes.value &&
          this.props.attributes.value.values &&
          this.props.attributes.value.values.length > 0) ||
          this.state.info) &&
        !this.state.addMore
          ? this.selectedCatalogItemsUI()
          : this.allCatalogItemsUI()}
        {((this.props.attributes &&
          this.props.attributes.value &&
          this.props.attributes.value.values &&
          this.props.attributes.value.values.length > 0) ||
          this.state.info) &&
        !this.state.addMore ? (
          <div className={`add-more-wrapper ne-add-more-wrapper`}>
            <p onClick={() => this.handleChangeAddMore()}>
              Add More{' '}
              <i
                className="far fa-angle-right"
                style={{ color: '#fa9917' }}
              ></i>
            </p>
          </div>
        ) : (
          ''
        )}
        <SummaryViewNaveenEnt
          currency={this.state.currency}
          selectedItems={this.state.selectedItemsList}
          info={this.state.info}
          addMore={this.state.addMore}
          handleClickInfo={this.handleClickInfo}
        />
      </div>
    );
  };

  handleChangePage = (event: any, page: any) => {
    this.setState({ page }, () => {
      const query = this.getFilterSearchQuery();
      if (query !== '') {
        this.getCatalogDataReq(this.state.rowsPerPage * page, query);
      } else {
        this.getCatalogDataReq(this.state.rowsPerPage * page, '');
      }
      const element: any = document.getElementById('catalog-modal-wrapper');
      element.scrollIntoView(true);
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  pagination = () => {
    return (
      <Table>
        <TableFooter className="tabel-footer">
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              count={this.state.totalItems}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              style={{ borderBottom: 'none' }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  };

  getLineItemFields = (data: any) => {
    const item = { ...data };
    const quantity = item.product_quantity;
    const totalPriceAmount = totalPrice(item, quantity);
    item['total_price'] = totalPriceAmount;
    const discountAmount = totalDiscAmount(item, quantity);
    item['total_dis_amt'] = discountAmount;
    const postTaxAmount = amountPostTax(totalPriceAmount, discountAmount);
    item['amt_post_tx'] = postTaxAmount;
    const totalTax = taxAmount(item, postTaxAmount);
    item['tx_amt'] = totalTax;
    const preTaxAmount = amountPreTax(postTaxAmount, totalTax);
    item['amt_pre_tx'] = preTaxAmount;
    return item;
  };

  /**
   * Update catalog value
   */
  updateCatalog = () => {
    const selectedItems: any = [];
    const allSelectedItems: any = [...this.state.selectedItemsList];
    allSelectedItems.length > 0 &&
      allSelectedItems.map((item: any) => {
        if (item.product_quantity) {
          selectedItems.push(item);
        }
        return null;
      });
    const summary = catalogAggregateDisplay(selectedItems);

    this.props.extProps.updateValue(this.props.attributes.name, summary);
    this.props.closeModal();
  };

  buttonUI = () => {
    return (
      <div
        className="buttons-wrapper"
        style={{
          height: 'unset',
          justifyContent: 'center',
          marginTop: '10px ',
        }}
      >
        <Button
          className="form-create-button"
          onClick={() => this.updateCatalog()}
        >
          Done
        </Button>
      </div>
    );
  };

  applyFilter = (data: any) => {
    const count = this.state.tabs.filter(
      (item: any) => item && item.value && item.value.length !== 0
    );
    const queryString = this.getFilterSearchQuery();
    if (data.type === 'apply' && queryString !== '') {
      this.setState({ filterCount: count ? count.length : 0, page: 0 }, () => {
        this.getCatalogDataReq(0, queryString);
      });
    } else {
      const stateCopy: any = Object.assign({}, this.state);
      let filterCount = 0;
      this.state.tabs.map((item: any, index: number) => {
        stateCopy.tabs[index].value = [];
        if (this.props.searchParams && this.props.searchParams[item.name]) {
          stateCopy.tabs[index].value =
            this.props.searchParams[item.name].split(',');
          filterCount += 1;
        }
        return null;
      });
      stateCopy.page = 0;
      stateCopy.searchText = '';
      stateCopy.filterCount = filterCount;

      this.setState(stateCopy, () => {
        this.getCatalogDataReq(0, '');
      });
    }
  };

  render() {
    return (
      <Modal open={true}>
        <div className="catalog-modal-root" style={{ width: '100%' }}>
          <div
            className="catalog-modal-root guest-catalog-modal-root"
            ref={(node) => (this.wrapperRef = node)}
          >
            <div
              id="catalog-modal-wrapper"
              className={'ne-catalog-modal-wrapper'}
            >
              {this.catalogTitle()}
              {this.state.openFilter && !this.showSelectedItemsCond() ? (
                <ProductFilterData
                  applyFilter={this.applyFilter}
                  tabs={this.state.tabs}
                  handleChangeOption={this.handleChangeOption}
                />
              ) : null}
              {this.generateCatalogListUI()}
              {this.buttonUI()}
              {this.state.info && !this.state.addMore
                ? null
                : this.pagination()}
            </div>
            {this.state.openAlertModal ? (
              <AlertDailog
                open={this.state.openAlertModal}
                message="Are you sure about the quantity"
                handleClose={this.closeValidateQuantity}
              />
            ) : (
              ''
            )}
            {this.state.openQuantityDailog ? (
              <AlertDailog
                open={this.state.openQuantityDailog}
                message="Add the quantity value"
                handleClose={this.closeDiscQtyValidation}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    error: state.reportReducer.error,
    open: state.reportReducer.open,
    catalogDataSuccess: state.reportReducer.catalogDataSuccess,
    catalogDataSpinner: state.reportReducer.catalogDataSpinner,
    catalogData: state.reportReducer.catalogData,
    productFilterOptionsSuccess:
      state.reportReducer.productFilterOptionsSuccess,
    productFilterOptions: state.reportReducer.productFilterOptions,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      getCatalogData,
      getNeProductFilterOptions,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CatalogNaveenEnt));
