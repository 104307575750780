export const fields = [
  {
    type: 'status_picker',
    name: 'status',
    label: 'Status',
    required: true,
    editable: true,
    hidden: false,
    searchable: true,
    options: [
      'NEW REQUEST',
      'VERIFIED',
      'REJECTED AT VERIFICATION',
      'APPROVED',
      'REJECTED AT APPROVAL',
      'DEACTIVATED',
    ],
    filter_options: [
      {
        create: ['NEW REQUEST'],
      },
      {
        _mo_details: ['VERIFIED', 'REJECTED AT VERIFICATION'],
      },
      {
        _mo_approval: ['APPROVED', 'REJECTED AT APPROVAL'],
      },
      {
        _mo_deactivate: ['APPROVED', 'DEACTIVATED'],
      },
    ],
    defaultValue: 'NEW REQUEST',
  },
  {
    type: 'text',
    name: '_mo_licensee_stall_no',
    label: 'Licensee Stall No',
    required: true,
    editable: true,
    hidden: false,
    searchable: true,
    primary_key: false,
    expression: false,
  },
  {
    type: 'text',
    name: '_mo_name_of_staff_member',
    label: 'Name of Staff Member',
    required: true,
    editable: true,
    hidden: false,
    searchable: true,
    primary_key: false,
    expression: false,
  },
  {
    type: 'picker',
    name: '_mo_designation',
    label: 'Designation',
    required: true,
    editable: true,
    hidden: false,
    searchable: true,
    primary_key: false,
    options: ['STALL VENDOR', 'PF VENDOR', 'MANAGER', 'LICENSEE'],
  },
  {
    type: 'phone',
    name: '_mo_staff_mobile_no',
    label: 'Staff Mobile No',
    required: true,
    editable: true,
    hidden: false,
    searchable: true,
    primary_key: false,
  },
  {
    type: 'image',
    name: '_mo_staff_member_photo',
    label: 'Staff Member Photo',
    required: true,
    editable: true,
    hidden: false,
    searchable: false,
    primary_key: false,
    additional_config: {
      mode: 'high-resolution',
      multiple: false,
      max_files: 1,
      hide_gallery: false,
    },
  },
  {
    type: 'text',
    name: '_mo_govt_id_no',
    label: 'Govt ID No',
    required: true,
    editable: true,
    hidden: false,
    searchable: false,
    primary_key: false,
    expression: false,
  },
  {
    type: 'image',
    name: '_mo_govt_id_photo',
    label: 'Govt ID Photo',
    required: true,
    editable: true,
    hidden: false,
    searchable: false,
    primary_key: false,
    additional_config: {
      mode: 'high-resolution',
      multiple: true,
      max_files: 2,
      hide_gallery: false,
    },
  },
  {
    type: 'image',
    name: '_mo_police_verification_certificate',
    label: 'Police Verification Certificate',
    required: true,
    editable: true,
    hidden: false,
    searchable: false,
    primary_key: false,
    additional_config: {
      mode: 'high-resolution',
      multiple: true,
      max_files: 2,
      hide_gallery: false,
    },
  },
  {
    type: 'image',
    name: '_mo_medical_certificate',
    label: 'Medical Certificate',
    required: true,
    editable: true,
    hidden: false,
    searchable: false,
    primary_key: false,
    additional_config: {
      mode: 'high-resolution',
      multiple: true,
      max_files: 3,
      hide_gallery: false,
    },
  },
  {
    type: 'image',
    name: '_mo_fssai_certificate',
    label: 'FSSAI Certificate',
    required: true,
    editable: true,
    hidden: false,
    searchable: false,
    primary_key: false,
    additional_config: {
      mode: 'high-resolution',
      multiple: true,
      max_files: 2,
      hide_gallery: false,
    },
  },
];

export const createStageFields = [
  'status',
  '_mo_licensee_stall_no',
  '_mo_name_of_staff_member',
  '_mo_designation',
  '_mo_staff_mobile_no',
  '_mo_staff_member_photo',
  '_mo_govt_id_no',
  '_mo_govt_id_photo',
  '_mo_police_verification_certificate',
  '_mo_medical_certificate',
  '_mo_fssai_certificate',
];
