/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';

import '../../styles/form/vendorOnboardingSuccessForm.css';
import withRouter from 'withRouter';

class VendorOnboardingSuccessForm extends Component<any, any> {
  render() {
    return (
      <div id="vendor-success-root">
        <div className="vendor-success-logo-wrapper">
          <img
            src={require('./../../../../assets/images/userOnBoarding/logo-big.png')}
            alt="logo"
            className="logo"
          />
        </div>
        <div className="vendor-success-content-wrapper">
          <p>{`ID card data for ${this.props.router.params.vendorname} uploaded.`}</p>
          <Button
            className="add-new-button"
            onClick={() => this.props.router.navigate(`/vendoronboarding`)}
          >
            Add New
          </Button>
        </div>
      </div>
    );
  }
}

export default connect<any, any, any>(
  null,
  null
)(withRouter(VendorOnboardingSuccessForm));
