import React, { Component } from 'react';
import { Modal } from '@mui/material';

import './../../styles/inbox/imageModal.css';

class LongTextModal extends Component<any, any> {
  wrapperRef: any;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  };

  render() {
    const { open, data, closeModal } = this.props;
    return (
      <Modal open={open}>
        <div id="image-preview-modal-wrapper">
          <div
            className="image-preview-modal-container"
            ref={(node) => (this.wrapperRef = node)}
          >
            <div className="image-preview-modal-header">
              <p>{data && data.label ? data.label : 'Longtext'}</p>
              <i
                className="far fa-times-circle close-button"
                onClick={closeModal}
              />
            </div>
            <div className="image-preview-modal-body">
              <p>{data.value}</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default LongTextModal;
