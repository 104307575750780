import React, { Component } from 'react';

import Prospect from './prospect';
import BasicUser from './basicUser';
import User from './user';
import '../styles/info.css';

export default class Info extends Component<any, any> {
  render() {
    return (
      <div>
        {this.props.router.params.userType === 'prospect' ? (
          <Prospect />
        ) : this.props.router.params.userType === 'basicuser' ? (
          <BasicUser />
        ) : (
          <User />
        )}
      </div>
    );
  }
}
