import { call, put } from 'redux-saga/effects';
import bugsnagClient from './../bugsnag/config';
function headers() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    dataType: 'json',
  };
}

export function* callAPI(api: any, payload: object, failureType: string) {
  try {
    const accessToken = sessionStorage.getItem('accessToken');
    const header = headers();
    if (accessToken) {
      header['Authorization'] = 'bearer ' + accessToken;
    }
    const response: { status: any; data: any } = yield call(
      api,
      payload,
      header
    );

    if (
      response &&
      response.data.error &&
      response.data.error.status &&
      response.data.error.status === 401
    ) {
      // clear all localstorage
      sessionStorage.clear();
      // redirect to '/login'
      window.location.href = '/login';
    }

    if (!response.status) {
      const message =
        response.data &&
        response.data.data &&
        response.data.data[0] &&
        response.data.data[0].message
          ? response.data.data[0].message
          : response.data && response.data.message
            ? response.data.message
            : '';

      yield put({ type: failureType, payload: message });
      return null;
    }
    return response;
  } catch (e: any) {
    bugsnagClient.notify(e);
    yield put({ type: failureType });
  }
  return null;
}

export function* callFirestoreAPI(api: any, payload: any, failureType: any) {
  try {
    const response: { status: any; data: any; error: any } = yield call(
      api,
      payload
    );

    if (!response.status) {
      yield put({ type: failureType, payload: response.error });
      return null;
    }

    return response;
  } catch (e) {
    console.log(e);
    yield put({ type: failureType });
  }
  return null;
}
