import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
} from '@mui/material';

const AlertDailog = (props: any) => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {props.headerMessage && (
        <DialogTitle id="alert-dialog-title">{props.headerMessage}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {(props.showNoButton === undefined || props.showNoButton) && (
          <Button onClick={() => props.handleClose('no')} color="primary">
            No
          </Button>
        )}
        <Button onClick={() => props.handleClose('yes')} color="primary">
          {props.yesButtonText ? props.yesButtonText : 'Yes'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDailog;
