import loginReducer from './feature/userOnBoarding/reducers/login';
import drawerReducer from './feature/drawer/reducers/drawerReducer';
import homeReducer from './feature/workflow/reducers/homeReducer';
import reportReducer from './feature/workflow/reducers/reportReducer';

const RootReducer = (state: any, action: any) => {
  if (action.type === 'INITIALIZE_STATE') {
    state = undefined;
  }

  return {
    loginReducer: loginReducer(state && state.loginReducer, action),
    drawerReducer: drawerReducer(state && state.drawerReducer, action),
    homeReducer: homeReducer(state && state.homeReducer, action),
    reportReducer: reportReducer(state && state.reportReducer, action),
  };
};

export default RootReducer;
