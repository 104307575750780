import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import '../styles/login.css';

const options = {
  items: 1,
  nav: false,
  autoplay: true,
  loop: true,
  smartSpeed: 400,
  animateOut: 'fadeOut',
  responsive: {
    '0': { items: 1 },
  },
};

const Carousel = () => {
  return (
    <OwlCarousel options={options}>
      <div className="owl-item-holder">
        <div className="img-holder">
          <img
            src={require('../../../assets/images/userOnBoarding/ExplodeProductivity.png')}
            className="img-fluid"
            alt="carousel"
          />
        </div>
        <h1>
          <i>Explode Productivity</i>
          <br />
          <span>with Seamless Workflow</span>
        </h1>
      </div>
      <div className="owl-item-holder">
        <div className="img-holder">
          <img
            src={require('../../../assets/images/userOnBoarding/RevolutionaryChange.png')}
            className="img-fluid"
            alt="carousel"
          />
        </div>
        <h1>
          <i>Revolutionary Change</i>
          <br />
          <span>with Adaptive Platform</span>
        </h1>
      </div>
      <div className="owl-item-holder">
        <div className="img-holder">
          <img
            src={require('../../../assets/images/userOnBoarding/LightingSpeed.png')}
            className="img-fluid"
            alt="carousel"
          />
        </div>
        <h1>
          <i>Lightning Speed</i>
          <br />
          <span>with Paperless Processes​</span>
        </h1>
      </div>
    </OwlCarousel>
  );
};

export default Carousel;
