import React from 'react';
import { Input } from '@mui/material';
import SwitchDiscount from './switchDiscount';
import { formatInput } from './helper';

const label = (item: any) => {
  return (
    <div className="catalog-modal-item-label">
      <div className="code-name-wrapper">
        <p>{item.product_code}</p>
        <p>{item.product_name}</p>
      </div>
      <div className="price-wrapper">
        <p>{`${item.currency} ${item.price_per_unit}/${item.unit_of_measure} ${
          item.discount ? ` - ${item.discount}` : ''
        }`}</p>
      </div>
    </div>
  );
};

const inputElement = (props: any, item: any) => {
  return (
    <Input
      type="number"
      value={item['product_quantity'] ? item['product_quantity'] : ''}
      onChange={(event: any) =>
        props.handleChangeProductQty(
          item.product_code,
          props.index,
          event.target.value
        )
      }
      onBlur={(event: any) => {
        props.handleChangeBlur(
          item.product_code,
          props.index,
          event.target.value
        );
      }}
      onKeyDown={(event: any) =>
        formatInput(event, props.attributes['allow_decimal'])
      }
      placeholder="Quantity"
      className="catalog-qty-input"
    />
  );
};

const openDiscountUI = (props: any, item: any) => {
  if (props.expandProductCode === item.product_code) {
    return (
      <div className="disc-wrapper" onClick={() => props.closeDiscount('')}>
        Discount
        <i className="far fa-angle-double-up disc-tax-down-icon"></i>
      </div>
    );
  } else {
    return (
      <div
        className="disc-wrapper"
        onClick={() => props.openDiscount(item.product_code)}
      >
        Discount
        <i className="far fa-angle-double-down disc-tax-down-icon"></i>
      </div>
    );
  }
};

const CatalogItem = (props: any) => {
  const item: any = props.item;
  return (
    <div key={props.index} className="catalog-item-wrapper">
      <div className="catalog-modal-item-wrapper">
        {label(item)}
        <div
          className={`catalog-modal-item-qty ${
            item['discount_percentage'] ? 'catalog-modal-item-input' : ''
          }`}
        >
          {inputElement(props, item)}
          {item['discount_percentage'] ? openDiscountUI(props, item) : ''}
        </div>
      </div>
      {props.expandProductCode === item.product_code ? (
        <SwitchDiscount data={props} formatInput={formatInput} />
      ) : (
        ''
      )}
    </div>
  );
};

export default CatalogItem;
