import React, { Component } from 'react';
import { Modal } from '@mui/material';
import ImageGallery from 'react-image-gallery';

import { isNull } from '../../../../utils/helper';
import './../../styles/inbox/imageModal.css';
import './../../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class ImagePreviewModal extends Component<any, any> {
  wrapperRef: any;
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  };

  escFunction = (event: any) => {
    if (event.keyCode === 27) {
      this.props.closeModal();
    }
  };

  renderImage = () => {
    if (!isNull(this.props.imgObj) && !isNull(this.props.imgObj.imageList)) {
      return (
        <ImageGallery
          items={this.props.imgObj.imageList}
          showPlayButton={false}
          showThumbnails={false}
          showBullets={true}
          startIndex={
            this.props.imgObj && this.props.imgObj.startIndex
              ? this.props.imgObj.startIndex
              : 0
          }
        />
      );
    } else {
      return <p style={{ textAlign: 'center' }}>No image</p>;
    }
  };

  render() {
    return (
      <Modal open={this.props.open}>
        <div id="image-preview-modal-wrapper">
          <div
            className="image-preview-modal-container"
            ref={(node) => (this.wrapperRef = node)}
          >
            <div className="image-preview-modal-header">
              <p>
                {this.props.imgObj && this.props.imgObj.label
                  ? this.props.imgObj.label
                  : 'Image'}
              </p>
              <i
                className="far fa-times-circle close-button"
                onClick={this.props.closeModal}
              />
            </div>
            <div className="image-preview-modal-body">{this.renderImage()}</div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ImagePreviewModal;
