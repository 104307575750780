import React, { Component } from 'react';
import '../styles/footer.css';

class Footer extends Component {
  render() {
    return (
      <div id="mo-footer">
        <footer>
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-sm col-md-4 col-lg">
                <div className="social-icon-wrapper">
                  <a
                    href="https://mobileoffize.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={require('../../../assets/images/logo-white.png')}
                      className="logo-img"
                      alt="logo"
                    />
                  </a>
                  <p>Find us on</p>
                  <ul className="play-google">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.mobileoffize"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={require('../../../assets/images/google-play-badge.png')}
                          alt="google-play"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://itunes.apple.com/us/app/mobileoffize/id1403158727?mt=8"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <img
                          src={require('../../../assets/images/App_Store.svg')}
                          alt="app-store"
                        />
                      </a>
                    </li>
                  </ul>
                  <div className="icon-holder">
                    <p>© MobileOffize&trade; | Xpedi Apps Pvt. Ltd</p>
                    <div className="footer-sym">
                      <a
                        href="https://in.linkedin.com/company/mobileoffize"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                      <a
                        href="https://twitter.com/MOffize"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      {/* <i className="fab fa-twitter"></i>
                          <i className="fab fa-facebook-f"></i> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm col-md-6 col-lg">
                <div className="company-info">
                  <ul>
                    <li>Contact</li>
                    <li>
                      Xpedi Apps Private Limited
                      <br />
                      A2, Casa Lavelle 4, Lavelle Road, <br />
                      Bangalore 560001
                    </li>
                    <li>
                      Phone: <a href="tel:+919742441144">+91 97424 41144</a>
                    </li>
                    <li>
                      Email:{' '}
                      <a href="mailto:navin@mobileoffize.com">
                        navin@mobileoffize.com
                      </a>
                    </li>
                  </ul>
                  <ul>
                    <li>Help</li>
                    {/* <li>FAQs</li> */}
                    <li>
                      <a
                        href="https://mobileoffize.com/privacypolicy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://mobileoffize.com/termsofuse"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://mobileoffize.com/shippingpolicy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Shipping Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://mobileoffize.com/shippingpolicy"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Refund & Cancellation
                      </a>
                    </li>
                  </ul>
                  <div>
                    <ul>
                      <li>Manage</li>
                      <li>
                        <a
                          href="https://app.mobileoffize.com/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Login
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
