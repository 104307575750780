import React from 'react';
import { Picky } from 'react-picky';
import { Button } from '@mui/material';
import * as _ from 'lodash';

import 'react-picky/dist/picky.css';
import './../../styles/filter.css';

const ProductFilterData = (props: any) => {
  return (
    <div className="filter-data-container ne-filter-data-container">
      <div className="filter-data-tab-wrapper">
        {props.tabs &&
          props.tabs.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="filter-data-select ne-filter-data-select"
              >
                <p>{item.label}</p>
                <Picky
                  className={`${item.options.length === 0 ? 'disabled' : ''}`}
                  options={item.options}
                  value={props.tabs[index].value}
                  multiple={true}
                  numberDisplayed={0}
                  includeSelectAll={item.options.length > 0}
                  includeFilter={item.options.length > 0}
                  onChange={props.handleChangeOption(item.name)}
                  dropdownHeight={250}
                />
              </div>
            );
          })}
      </div>
      <div className="filter-data-buttons-wrapper">
        <Button
          className="filter-data-clear-button ne-filter-data-clear-button"
          onClick={() => props.applyFilter({ type: 'clear' })}
          variant="outlined"
        >
          Clear
        </Button>
        <Button
          className="filter-data-apply-button ne-filter-data-apply-button"
          onClick={() => props.applyFilter({ type: 'apply' })}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ProductFilterData;
