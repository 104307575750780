import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressSpinner = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress
        style={{ width: '30px', height: '30px', color: '#FFFFFF' }}
      />
    </div>
  );
};

export default ProgressSpinner;
