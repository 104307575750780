import React, { Component } from 'react';
import { connect } from 'react-redux';
import Recaptcha from 'react-recaptcha';

import { getSWRIDCard } from '../actions/actions';
import { isNull } from './../../../utils/helper';
import '../styles/userIdCard.css';
import withRouter from 'withRouter';

class SWRIDCard extends Component<any, any> {
  verifyCallback = (response: any) => {
    const query = new URLSearchParams(this.props.router.location.search);
    if (!isNull(query.get('id'))) {
      const isIrctcCard =
        this.props.router.location &&
        this.props.router.location.pathname.includes('/irctc');
      this.props.getSWRIDCard(query.get('id'), isIrctcCard);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadCallback = () => {};

  render() {
    if (!isNull(this.props.swrIdCardHtml)) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.swrIdCardHtml,
          }}
        ></div>
      );
    } else if (!isNull(this.props.failureMsg)) {
      return (
        <div className="user-id-card-container">
          <div className="company-logo" style={{ paddingBottom: '10px' }}>
            <img
              src={require('../../../assets/images/userOnBoarding/logo-big.png')}
              alt="logo"
            />
          </div>
          <h2 style={{ paddingTop: 0 }}>Smart ID Card</h2>
          <p>{this.props.failureMsg}</p>
        </div>
      );
    }

    return (
      <div className="user-id-card-container">
        <h2>Smart ID Card</h2>
        <Recaptcha
          sitekey="6Lck6noUAAAAAKhl_U6YGmjDhv7IAK2pH9JDNKsJ"
          render="explicit"
          verifyCallback={this.verifyCallback}
          onloadCallback={this.loadCallback}
        />
        <div className="company-logo">
          <img
            src={require('../../../assets/images/userOnBoarding/logo-big.png')}
            alt="logo"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    swrIdCardHtml: state.loginReducer.swrIdCardHtml,
    failureMsg: state.loginReducer.failureMsg,
  };
};

const mapDispatchToProps = {
  getSWRIDCard,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SWRIDCard));
