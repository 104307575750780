import Bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import React from 'react';

const bugsnagClient = Bugsnag.start({
  apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
  plugins: [new bugsnagReact(React)],
  enabledReleaseStages: ['production'],
});

export default bugsnagClient;
