/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
// import { customAlphabet } from 'nanoid'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import moment from 'moment';
import * as _ from 'lodash';
import Recaptcha from 'react-recaptcha';

import Spinner from '../../../../common/spinner';
import SnackbarMessage from '../../../../common/snackbarMessage';
import * as types from '../../../../actions/types';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../userOnBoarding/actions/actions';
import { createVendorOnboardingForm } from '../../actions/report';
import {
  getDefaultValue,
  isNull,
  validateCustomForm,
} from '../../../../utils/helper';
import { fields, createStageFields } from './vendorOnboarding';
import '../../styles/form/vendorOnboardingForm.css';
import withRouter from 'withRouter';

// const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ$@',10)

interface IState {
  fields: any[];
  openCatalogModal: boolean;
  extProps: any;
  openChecklistModal: boolean;
  openSelectModal: boolean;
  openUserDirectoryModal: boolean;
  creatFormdata: any;
  openDialog: boolean;
  dialogMsg: string;
  teamId: string;
  templateId: string;
  captchaVerified: boolean;
  startTime: any;
}

interface IProps {
  customCreateFormDataSuccess: boolean;
  customCreateFormDataSpinner: boolean;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  createVendorOnboardingForm: (formData: any) => void;
  router: any;
}

class VendorOnboardingForm extends Component<IProps, IState> {
  state = {
    fields: [],
    openCatalogModal: false,
    extProps: {},
    openChecklistModal: false,
    openSelectModal: false,
    openUserDirectoryModal: false,
    creatFormdata: [],
    openDialog: false,
    dialogMsg: '',
    teamId: 'DSLC97M3JMTCMR1',
    templateId: '6225acd801da671e6ec4e8ba',
    captchaVerified: false,
    startTime: moment().utc().valueOf(),
  };

  componentDidMount() {
    this.getStageFields();
  }

  componentDidUpdate() {
    if (this.props.customCreateFormDataSuccess) {
      this.props.dispatch({
        type: types.RESET_CREATE_VENDOR_ONBOARDING_FORM_REQUEST,
      });
      this.props.router.navigate(
        `/vendor/${this.getStaffMemberName()}/success`
      );
    }
  }

  getStaffMemberName = () => {
    const obj = _.find(this.state.creatFormdata, {
      name: '_mo_name_of_staff_member',
    });
    return !isNull(obj) && !isNull(obj.value) ? obj.value : 'Vendor';
  };

  /**
   * Stage fields for form0
   */
  getStageFields = () => {
    const data: any = [];
    const creatFormdata: any = [];
    if (fields.length > 0 && createStageFields.length > 0) {
      createStageFields.map((item: any) => {
        let field = _.find(fields, {
          name: item,
        });
        if (field) {
          /**
           * For status_picker and picker, get stage specific options to render and add -Select- as first value
           */
          if (['status_picker', 'picker'].includes(field.type)) {
            if (field.filter_options && field.filter_options.length > 0) {
              field = this.updateOption(field);
            } else {
              const options = field['options'];
              const index = _.findIndex(
                options,
                (option: any) => option === '-Select-'
              );
              if (index === -1) options.unshift('-Select-');
              field['options'] = options;
            }
          }
          data.push(field);
          creatFormdata.push({
            ...field,
            error: false,
            errorMsg: '',
            value: getDefaultValue(field),
          });
        }
        return null;
      });
    }

    this.setState({
      fields: data,
      creatFormdata,
    });
  };

  /**
   * Get stage specific options to render and add -Select- as first value
   */
  updateOption = (field: any) => {
    const options = field.filter_options;
    const stage = 'create';
    const filterOption = _.find(options, stage);

    if (filterOption) {
      const stageOption = filterOption[stage];
      const index = _.findIndex(
        stageOption,
        (option: any) => option === '-Select-'
      );
      if (index === -1) stageOption.unshift('-Select-');
      field['options'] = stageOption;
    }
    return field;
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  getFormattedData = () => {
    const formData = new FormData();
    this.state.creatFormdata.forEach((field: any) => {
      if (field.type === 'image') {
        if (field.value && field.value[0]) {
          formData.append(field.name, field.value[0]);
        }
      } else {
        formData.append(field.name, field.value);
      }
    });

    const recordedTime: any = moment().utc().valueOf();
    const startTime: any = this.state.startTime;

    formData.append('_mo_start_time', startTime);
    formData.append('_mo_recorded_at', recordedTime);
    formData.append('_mo_end_time', recordedTime);
    return formData;
  };

  onValidateFields = () => {
    const newFields: any = [];
    this.state.creatFormdata.forEach((field: any) => {
      if (field) {
        if (field.required !== undefined && field.required) {
          const validate = validateCustomForm(field);
          field.error = validate.error;
          field.errorMsg = validate.errorMsg;
        }

        newFields.push({ ...field });
      }
    });
    this.setState({ creatFormdata: newFields });
  };

  submitForm = () => {
    this.onValidateFields();
    const error = this.state.creatFormdata.some((ele: any) => ele.error);
    if (!error) {
      const formData = this.getFormattedData();
      if (formData) {
        this.props.createVendorOnboardingForm(formData);
      }
    }
  };

  handleCloseDialog = (dialogMsg: string) => {
    this.setState({ openDialog: !this.state.openDialog, dialogMsg });
  };

  renderDialog = () => {
    return (
      <Dialog
        open={this.state.openDialog}
        onClose={() => this.handleCloseDialog('')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.state.dialogMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleCloseDialog('')}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleFile = (name: string, config: any, images: any) => {
    if (!isNull(images)) {
      if (config['multiple'] && images.length > config['maxFiles']) {
        this.handleCloseDialog(
          `Please note maximum files allowed is ${config['maxFiles']}`
        );
        return;
      }

      for (const img of images) {
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(img['type'])) {
          this.handleCloseDialog(`${img['name']} file type is not allowed`);
          return;
        } else if (isNull(img['size'])) {
          this.handleCloseDialog(
            `${img['name']} file size is empty. Please choose proper file`
          );
          return;
        } else if (img['size'] > 528384) {
          this.handleCloseDialog(
            `${img['name']} file size is greater than 512kb. Please select file size less than 512kb`
          );
          return;
        }
      }
      const imgValue: any = [];
      Object.keys(images).forEach((key: any) => {
        imgValue.push(images[key]);
      });
      const creatFormdata: any = this.state.creatFormdata;
      const dataIndex: any = _.findIndex(creatFormdata, { name });
      creatFormdata[dataIndex]['value'] = imgValue;
      creatFormdata[dataIndex]['error'] = false;
      creatFormdata[dataIndex]['errorMsg'] = '';
      this.setState({ creatFormdata });
    }
  };

  handleChangeForm = (name: string, value: any) => {
    const formDataObj: any = this.state.creatFormdata;
    const dataIndex: any = _.findIndex(this.state.creatFormdata, { name });
    formDataObj[dataIndex]['value'] = value;
    formDataObj[dataIndex]['error'] = false;
    formDataObj[dataIndex]['errorMsg'] = '';
    this.setState({ creatFormdata: formDataObj });
  };

  renderForm = () => {
    return (
      !isNull(this.state.creatFormdata) &&
      this.state.creatFormdata.map((item: any) => {
        switch (item.type) {
          case 'text':
          case 'phone':
            return (
              <TextField
                key={item.name}
                id={item.name}
                name={item.name}
                type={item['type'] || 'text'}
                value={item.value || ''}
                label={`${item.label}${item.required ? '*' : ''}`}
                variant="outlined"
                fullWidth={true}
                onChange={(event) =>
                  this.handleChangeForm(item.name, event.target.value)
                }
                style={{ marginBottom: '20px' }}
                error={item.error}
                helperText={item.error ? item.errorMsg : ''}
              />
            );
          case 'picker':
          case 'status_picker':
            return (
              <FormControl
                key={item.name}
                fullWidth={true}
                style={{ marginBottom: '20px' }}
                error={item.error}
              >
                <InputLabel
                  id={item.name}
                  error={item.error}
                  className="vendor-picker-label"
                >
                  {`${item.label}${item.required ? '*' : ''}`}
                </InputLabel>
                <Select
                  labelId={item.name}
                  id={item.name}
                  value={item.value || ''}
                  label={`${item.label}${item.required ? '*' : ''}`}
                  onChange={(event: any) =>
                    this.handleChangeForm(item.name, event.target.value)
                  }
                  error={item.error}
                  variant="outlined"
                >
                  {!isNull(item.options) &&
                    item.options.map((ele: any, index: any) => {
                      return (
                        <MenuItem value={ele} key={`${ele}-${index}`}>
                          {ele}
                        </MenuItem>
                      );
                    })}
                </Select>
                {item.error && (
                  <FormHelperText error={item.error}>
                    {item.errorMsg}
                  </FormHelperText>
                )}
              </FormControl>
            );
          case 'image':
            const additionalConfig =
              !isNull(item) && !isNull(item['additional_config'])
                ? item['additional_config']
                : null;
            return (
              <div key={item.name} style={{ marginBottom: '20px' }}>
                <div>
                  <input
                    type="file"
                    className="vendor-file-input"
                    accept="image/jpeg,image/png,image/jpg"
                    onChange={(event: any) =>
                      this.handleFile(
                        item.name,
                        additionalConfig,
                        event.target.files
                      )
                    }
                    id={item.name}
                    multiple={false}
                  />
                  <label
                    htmlFor={item.name}
                    style={{ marginBottom: 0, width: '100%' }}
                  >
                    <Button
                      component="span"
                      className={`vendor-choose-file ${
                        item.error ? 'error' : ''
                      }`}
                    >
                      {`${item.label}${item.required ? '*' : ''}`}
                      <i className="fas fa-image" />
                    </Button>
                  </label>
                </div>
                {!isNull(item.value) &&
                  item.value.map((img: any, index: number) => {
                    return (
                      <div
                        key={`${img.name}-${index}`}
                        style={{ marginTop: '10px' }}
                      >
                        {img.name}
                      </div>
                    );
                  })}
              </div>
            );
          default:
            return null;
        }
      })
    );
  };

  verifyCallback = (response: object) => {
    if (response) {
      this.setState({
        captchaVerified: true,
      });
    }
  };

  render() {
    return (
      <div
        id="vendor-create-form-root"
        style={{
          marginTop: '50px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div className="vendor-logo-wrapper">
          <img
            src={require('./../../../../assets/images/userOnBoarding/logo-big.png')}
            alt="logo"
            className="logo"
          />
        </div>
        <div className="vendor-create-form-wrapper">
          <div className="vendor-create-form-header">
            <p>Create Vendor ID Card</p>
          </div>
          {this.props.customCreateFormDataSpinner ? <Spinner /> : ''}
          <div
            className="vendor-form-content"
            style={{ justifyContent: 'center' }}
          >
            <div className="vendor-form-left">
              {this.renderForm()}
              {process.env.NODE_ENV === 'development' ? (
                <div />
              ) : (
                <div style={{ marginBottom: '20px' }}>
                  <Recaptcha
                    sitekey="6Lck6noUAAAAAKhl_U6YGmjDhv7IAK2pH9JDNKsJ"
                    render="explicit"
                    verifyCallback={(response: any) =>
                      this.verifyCallback(response)
                    }
                  />
                </div>
              )}
              <div className="vendor-buttons-wrapper">
                <Button
                  type="submit"
                  className={
                    this.props.customCreateFormDataSpinner
                      ? 'form-disabled-button'
                      : 'form-create-button'
                  }
                  onClick={this.submitForm}
                  disabled={this.props.customCreateFormDataSpinner}
                >
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage || this.props.success.displayMessage
          }
          open={this.props.open}
        />
        {this.state.openDialog && this.renderDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    customCreateFormDataSpinner:
      state.reportReducer.customCreateFormDataSpinner,
    customCreateFormDataSuccess:
      state.reportReducer.customCreateFormDataSuccess,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      createVendorOnboardingForm,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorOnboardingForm));
