import React from 'react';
import {
  sumAmountPostTax,
  getTotalItem,
  sumTotalDisc,
  sumTotalTax,
  isDiscountOrTaxApplicable,
} from './helper';

const SummaryView = (props: any) => {
  return (
    <div className="summary-view-wrapper ne-summary-view-wrapper">
      <div className="item-units-wrapper">
        <p className="ne-p">{`${
          props.selectedItems.length
        } items|${getTotalItem(props.selectedItems)} units`}</p>
        {isDiscountOrTaxApplicable(props.selectedItems) ? (
          <p className="ne-p">{`Disc ${sumTotalDisc(
            props.selectedItems
          )} + Tax ${sumTotalTax(props.selectedItems)} `}</p>
        ) : (
          ''
        )}
      </div>
      <div className="price-wrapper">
        <p className="ne-p">{`${props.currency} ${sumAmountPostTax(
          props.selectedItems
        )}`}</p>
        {props.info && !props.addMore ? (
          ''
        ) : (
          <i
            className="fal fa-info-circle"
            style={{ paddingLeft: 20, paddingTop: 3 }}
            onClick={() => props.handleClickInfo()}
          ></i>
        )}
      </div>
    </div>
  );
};

export default SummaryView;
