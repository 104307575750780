import React, { Component } from 'react';
import { connect } from 'react-redux';
import Recaptcha from 'react-recaptcha';

import {
  getOTPForUserName,
  resetErrorMessage,
  resetGetOtpRequest,
} from '../actions/actions';
import { validateEmail } from '../../../utils/helper';
import SnackbarMessage from '../../../common/snackbarMessage';
import ProgressSpinner from './progressSpinner';
import '../styles/loginCard.css';
import withRouter from 'withRouter';

interface IState {
  isVerified: boolean;
  email: string;
  emailVerified: boolean;
  message: string;
  open: boolean;
}

interface IProps {
  getOtpSuccess: boolean;
  loginRequest: boolean;
  error: {
    displayMessage: string;
  };
  open: boolean;
  resetGetOtpRequest: () => void;
  resetErrorMessage: () => void;
  getOTPForUserName: (emailId: string, type: string, history: any) => void;
  router: any;
}

class EmailLogin extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isVerified: process.env.NODE_ENV === 'development' ? true : false,
      email: '',
      emailVerified: false,
      message: '',
      open: false,
    };
  }

  componentDidUpdate() {
    if (this.props.getOtpSuccess) {
      this.props.router.navigate('/otp');
      this.props.resetGetOtpRequest();
    }
  }

  componentWillUnmount() {
    this.props.resetErrorMessage();
  }

  verifyCallback = (response: object) => {
    if (response) {
      this.setState({
        isVerified: true,
        open: false,
        message: '',
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  callback = () => {};

  onChangeValue = (e: any) => {
    this.setState({
      email: e.target.value,
      emailVerified: validateEmail(e.target.value),
    });
  };

  handleClose = () => {
    this.setState({ open: false, message: '' });
    this.props.resetErrorMessage();
  };

  emailSubmit = (e: any) => {
    e.preventDefault();
    if (this.state.emailVerified && this.state.isVerified) {
      this.props.getOTPForUserName(
        this.state.email.toLowerCase(),
        'EMAIL',
        this.props.router.navigate
      );
    } else {
      this.setState({
        message: 'Please verify that you are a human!',
        open: true,
      });
    }
  };

  render() {
    return (
      <div>
        <form onSubmit={this.emailSubmit}>
          <input
            type="text"
            autoFocus={true}
            className="form-control input-field"
            id="exampleInputEmail1"
            placeholder="Enter your email address"
            required={true}
            autoComplete="off"
            onChange={this.onChangeValue}
            onPaste={this.onChangeValue}
          />
          <div className="under-line" />
          {process.env.NODE_ENV === 'development' ? (
            <div />
          ) : (
            <Recaptcha
              sitekey="6Lck6noUAAAAAKhl_U6YGmjDhv7IAK2pH9JDNKsJ"
              render="explicit"
              verifyCallback={(response: any) => this.verifyCallback(response)}
              onloadCallback={this.callback}
            />
          )}
          <div style={{ paddingTop: 10 }} />

          <button
            type="submit"
            className="btn btn-primary submit-button"
            disabled={!this.state.emailVerified || this.props.loginRequest}
          >
            {this.props.loginRequest ? (
              <ProgressSpinner />
            ) : (
              <React.Fragment>
                {' '}
                Get OTP
                <i className="fas fa-chevron-right paddingLeft-20" />
              </React.Fragment>
            )}
          </button>
        </form>
        <SnackbarMessage
          handleClose={this.handleClose}
          message={this.props.error.displayMessage || this.state.message}
          open={this.props.open || this.state.open}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    error: state.loginReducer.error,
    getOtpSuccess: state.loginReducer.getOtpSuccess,
    open: state.loginReducer.open,
    loginRequest: state.loginReducer.loginRequest,
  };
};

const mapDispatchToProps = {
  getOTPForUserName,
  resetErrorMessage,
  resetGetOtpRequest,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailLogin));
