import React, { Component } from 'react';
import '../styles/basicUser.css';

class Prospect extends Component<any, any> {
  intervalId: any;
  componentDidMount() {
    this.intervalId = setInterval(this.redirect.bind(this), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  redirect = () => {
    window.location.href = 'https://mobileoffize.com/';
  };

  render() {
    return (
      <div className="basic-container ">
        <div className="upgrade-company-logo">
          <img
            src={require('../../../assets/images/userOnBoarding/logo-big.png')}
            alt="logo"
            className="logo"
          />
        </div>
        <h1>Thank you for your interest in MobileOffize</h1>
        <h6>
          Get started by downloading the all-in-one work management application
          <a href="https://mobileoffize.com/"> NOW</a>
        </h6>
      </div>
    );
  }
}

export default Prospect;
