import * as types from '../../../actions/types';

const initialState = {
  getOtpFailed: false,
  getOtpSuccess: false,
  error: {
    displayMessage: '',
    data: null,
  },
  requestId: '',
  otpValidationFailed: false,
  userId: '',
  userName: '',
  memberTeams: [],
  pendingInvitations: [],
  refreshToken: '',
  otpValidationSuccess: false,
  accessToken: '',
  getAccessTokenSuccess: false,
  permissionProfile: '',
  open: false,
  loginRequest: false,
  userIdCardHtml: null,
  userIdCardSuccess: false,
  swrIdCardHtml: null,
};

const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.RESET_ERROR_MESSAGE:
      return {
        ...state,
        open: false,
        error: {
          displayMessage: '',
        },
      };

    case types.GET_OTP_FOR_USERNAME_REQUEST:
      return {
        ...state,
        getOtpFailed: false,
        getOtpSuccess: false,
        loginRequest: true,
      };

    case types.GET_OTP_FOR_USERNAME_FAILURE:
      return {
        ...state,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
        getOtpFailed: true,
        getOtpSuccess: false,
        loginRequest: false,
      };

    case types.GET_OTP_FOR_USERNAME_SUCCESS:
      return {
        ...state,
        requestId: action.payload['request_id'],
        userDetails: action.payload,
        getOtpFailed: false,
        getOtpSuccess: true,
        loginRequest: false,
      };

    case types.RESET_GET_OTP_REQUEST:
      return {
        ...state,
        getOtpSuccess: false,
      };

    case types.RESEND_OTP_REQUEST:
      return {
        ...state,
        open: true,
        getOtpFailed: false,
        error: { displayMessage: 'OTP resent' },
      };

    case types.RESEND_OTP_FAILURE:
      return {
        ...state,
        getOtpFailed: true,
        error: { displayMessage: 'Unable to process the request' },
      };
    case types.RESEND_OTP_SUCCESS:
      return {
        ...state,
        requestId: action.payload['request_id'],
        getOtpFailed: false,
      };

    case types.VALIDATE_OTP_REQUEST:
      return {
        ...state,
        otpValidationFailed: false,
        otpValidationSuccess: false,
        loginRequest: true,
      };

    case types.VALIDATE_OTP_FAILURE:
      return {
        ...state,
        open: true,
        error: { displayMessage: 'Invalid OTP' },
        otpValidationFailed: true,
        otpValidationSuccess: false,
        loginRequest: false,
      };

    case types.VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        userId: action.payload['user_id'],
        userName: action.payload['user_name'],
        memberTeams: action.payload['member_teams'],
        pendingInvitations: action.payload['pending_invitations'],
        refreshToken: action.payload['refresh_token'],
        permissionProfile: action.payload['permission_profile'],
        otpValidationFailed: false,
        otpValidationSuccess: true,
        loginRequest: false,
      };

    case types.ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        accessToken: '',
        getAccessTokenSuccess: false,
      };

    case types.ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        accessToken: '',
        getAccessTokenSuccess: false,
      };

    case types.ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload['access_token'],
        getAccessTokenSuccess: true,
      };

    case types.LOGIN_REQUEST_FALSE:
      return {
        ...state,
        loginRequest: false,
      };

    case types.GET_SWR_ID_CARD_REQUEST:
      return {
        ...state,
        swrIdCardHtml: null,
        failureMsg: '',
      };

    case types.GET_SWR_ID_CARD_SUCCESS:
      return {
        ...state,
        swrIdCardHtml: action.payload,
        failureMsg: '',
      };

    case types.GET_SWR_ID_CARD_FAILURE:
      return {
        ...state,
        swrIdCardHtml: null,
        failureMsg: 'No associated smart ID card',
      };

    default:
      return state;
  }
};

export default loginReducer;
