const GET_TEAM_DETAILS_REQUEST = 'GET_TEAM_DETAILS_REQUEST';
const GET_TEAM_DETAILS_SUCCESS = 'GET_TEAM_DETAILS_SUCCESS';
const GET_TEAM_DETAILS_FAILURE = 'GET_TEAM_DETAILS_FAILURE';
const RESET_GET_TEAM_DETAILS_REQUEST = 'RESET_GET_TEAM_DETAILS_REQUEST';

export {
  GET_TEAM_DETAILS_REQUEST,
  GET_TEAM_DETAILS_SUCCESS,
  GET_TEAM_DETAILS_FAILURE,
  RESET_GET_TEAM_DETAILS_REQUEST,
};
