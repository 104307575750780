import { put, takeEvery } from 'redux-saga/effects';
import * as types from '../../../actions/types';
import * as TeamAPI from '../api/api.drawer';
import { callAPI } from '../../../api/api';

export function* getMyTeams(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    TeamAPI.getMyTeams,
    action.payload,
    types.GET_MY_TEAMS_FAILURE
  );
  if (response) {
    yield put({ type: types.GET_MY_TEAMS_SUCCESS, payload: response.data });
  }
}

export function* drawerSaga() {
  yield takeEvery(types.GET_MY_TEAMS_REQUEST, getMyTeams);
}
