import React from 'react';
import {
  sumAmountPostTax,
  getTotalItem,
  sumTotalDisc,
  sumTotalTax,
  isDiscountOrTaxApplicable,
} from './helper';

const SummaryView = (props: any) => {
  return (
    <div className="summary-view-wrapper">
      <div className="item-units-wrapper">
        <p>{`${props.selectedItems.length} items|${getTotalItem(
          props.selectedItems
        )} units`}</p>
        {isDiscountOrTaxApplicable(props.selectedItems) ? (
          <p>{`Disc ${sumTotalDisc(props.selectedItems)} + Tax ${sumTotalTax(
            props.selectedItems
          )} `}</p>
        ) : (
          ''
        )}
      </div>
      <div className="price-wrapper">
        <p>{`${props.currency} ${sumAmountPostTax(props.selectedItems)}`}</p>
        {props.info && !props.addMore ? (
          ''
        ) : (
          <i
            className="fal fa-info-circle"
            style={{ paddingLeft: 20 }}
            onClick={() => props.handleClickInfo()}
          ></i>
        )}
      </div>
    </div>
  );
};

export default SummaryView;
