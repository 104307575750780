import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetRequestFalse } from '../actions/actions';
import OtpCard from '../components/otpCard';
import Carousel from '../components/carousel';
import Footer from '../components/footer';
import '../styles/login.css';
import withRouter from 'withRouter';

interface IProps {
  resetRequestFalse: () => void;
  router: any;
}

class Otp extends Component<IProps, any> {
  componentDidMount() {
    this.props.resetRequestFalse();
    if (sessionStorage.getItem('accessToken')) {
      this.props.router.navigate(`/member/home`);
    }
  }
  render() {
    return (
      <>
        <div id="login">
          <div className="image-topright" />
          <div className="container-fluid">
            <div style={{ display: 'flex' }}>
              <div className="content-login">
                <Carousel />
              </div>
              <div style={{ width: '35%' }}>
                <div className="card1">
                  <OtpCard {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapDispatchToProps = {
  resetRequestFalse,
};

export default connect<any, any, any>(
  null,
  mapDispatchToProps
)(withRouter(Otp));
