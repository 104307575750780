import * as types from '../../../actions/types';
import * as teamTypes from '../../../actions/teamTypes';
import { decodeAccessToken } from '../../../utils/helper';

const initialState = {
  error: {
    displayMessage: '',
    data: null,
  },
  success: {
    displayMessage: '',
  },
  open: false,
  getMyReports: false,
  getMyReportsSpinner: false,
  allMyReports: [],
  getInboxDataSuccess: false,
  inboxDataSpinner: false,
  inboxData: [],
  getReportDetailsSuccess: false,
  reportDetailsSpinner: false,
  reportDetails: {},
  getFormDetails: false,
  getFormDetailsSpinner: false,
  formDataDetails: {},
  createFormDataSuccess: false,
  createFormDataSpinner: false,
  createFormDataResponse: {},
  updateFormDataSuccess: false,
  updateFormDataSpinner: false,
  updateFormDataResponse: {},
  getLookupInstanceIdsSuccess: false,
  getLookupInstanceIdsSpinner: false,
  lookupIds: [],
  getExternalLookupDataSuccess: false,
  getExternalLookupDataSpinner: false,
  externalLookupData: [],
  catalogDataSuccess: false,
  catalogDataSpinner: false,
  catalogData: [],
  timedOut: false,
  getInboxInstanceIdsSuccess: false,
  inboxInstancesIds: [],
  getInboxInstanceIdsSpinner: false,
  externalProps: null,
  unassignedDataSuccess: false,
  unassignedDataSpinner: false,
  unassignedData: [],
  updateAssigneeSuccess: false,
  updateAssigneeSpinner: false,
  updateAssigneeDetails: [],
  getImgSuccess: false,
  getImgSpinner: false,
  blobUrl: null,
  allInboxConfigReqSuccess: false,
  inboxConfigSpinner: false,
  allInboxConfigs: null,
  inboxTotalCount: null,
  inboxView: null,
  inboxSearchText: '',
  unassignedDataTotalItems: 0,
  unassignedActiveStage: null,
  unassignedSearchText: '',
  customCreateFormDataSuccess: false,
  customCreateFormDataSpinner: false,

  guestUserAccessToken: null,
  permissions: [],
  guestTemplateId: '',
  guestTeamId: '',
  guestUserAccessTokenSuccess: false,
  guestUserSpinner: false,
  guestUserAccessTokenFailure: false,

  guestUserRequestId: null,
  guestUserOtpSuccess: false,
  guestUserOtpRequest: false,

  validateGuestUserOtpSuccess: false,
  validateGuestUserOtpRequest: false,

  activityLog: null,
  activityLogSuccess: false,
  activityLogSpinner: false,

  teamdesignations: [],
  teamdesignationsSpinner: false,
  teamdesignationsSuccess: false,
  teamdesignationsFailure: false,

  naveenCreateFormDataSuccess: false,
  naveenCreateFormDataSpinner: false,
  naveenCreateFormDataResponse: {},
  guestFormDetails: {},

  naveenUpdateFormDataSuccess: false,
  naveenUpdateFormDataSpinner: false,
  naveenUpdateFormDataResponse: {},

  orderPayRequest: false,
  orderPaySuccess: false,
  orderPayDetails: {},

  paymentConfirmRequest: false,
  paymentConfirmSuccess: false,
  paymentConfirmDetails: {},

  razoResp: {},

  teamDetails: {},
  teamDetailsSuccess: false,
  teamDetailsReq: false,
  teamDetailsFailure: false,

  irctcOrderPayRequest: false,
  irctcOrderPaySuccess: false,
  irctcOrderPayDetails: {},

  irctcPayConfirmRequest: false,
  irctcPayConfirmSuccess: false,
  irctcPayConfirmDetails: {},

  irctcCardListReq: false,
  irctcCardListSuccess: false,
  irctcCardList: [],

  otpFormSuccess: false,
  otpFormFailure: false,
  otpFormData: null,

  productFilterOptionsSuccess: false,
  productFilterOptions: {},
};

const reportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.RESET_ERROR_MESSAGE:
      return {
        ...state,
        open: false,
        error: {
          displayMessage: '',
        },
      };

    case types.RESET_SUCCESS_MESSAGE:
      return {
        ...state,
        open: false,
        success: {
          displayMessage: '',
        },
      };

    case types.GET_ALL_MY_REPORTS_REQUEST:
      return {
        ...state,
        getMyReports: false,
        getMyReportsSpinner: true,
      };

    case types.GET_ALL_MY_REPORTS_FAILURE:
      return {
        ...state,
        getMyReports: false,
        getMyReportsSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.GET_ALL_MY_REPORTS_SUCCESS:
      return {
        ...state,
        getMyReports: true,
        getMyReportsSpinner: false,
        allMyReports: action.payload,
      };

    case types.RESET_GET_ALL_REPORTS_REQUEST:
      return {
        ...state,
        getMyReports: false,
        getMyReportsSpinner: false,
      };

    case types.RESET_ALL_REPORTS:
      return {
        ...state,
        allMyReports: [],
      };

    case types.GET_INBOX_DATA_REQUEST:
    case types.GET_INBOX_VIEWS_DATA_REQUEST:
      return {
        ...state,
        getInboxDataSuccess: false,
        inboxDataSpinner: true,
      };

    case types.GET_INBOX_DATA_SUCCESS:
    case types.GET_INBOX_VIEWS_DATA_SUCCESS:
      return {
        ...state,
        getInboxDataSuccess: true,
        inboxDataSpinner: false,
        inboxData: action.payload,
      };

    case types.GET_INBOX_DATA_FAILURE:
    case types.GET_INBOX_VIEWS_DATA_FAILURE:
      return {
        ...state,
        getInboxDataSuccess: false,
        inboxDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_INBOX_DATA_REQUEST:
      return {
        ...state,
        getInboxDataSuccess: false,
        inboxDataSpinner: false,
      };

    case types.GET_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        getReportDetailsSuccess: false,
        reportDetailsSpinner: true,
      };

    case types.GET_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        getReportDetailsSuccess: true,
        reportDetailsSpinner: false,
        reportDetails: action.payload[0],
      };

    case types.GET_REPORT_DETAILS_FAILURE:
      return {
        ...state,
        getReportDetailsSuccess: false,
        reportDetailsSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_REPORT_DETAILS_REQUEST:
      return {
        ...state,
        getReportDetailsSuccess: false,
        reportDetailsSpinner: false,
      };

    case types.RESET_REPORT_DETAILS_ON_TEAM_CHANGE:
      return {
        ...state,
        reportDetails: {},
      };

    case types.GET_FORM_DATA_DETAILS_REQUEST:
      return {
        ...state,
        getFormDetails: false,
        getFormDetailsSpinner: true,
      };

    case types.GET_FORM_DATA_DETAILS_SUCCESS:
      return {
        ...state,
        getFormDetails: true,
        getFormDetailsSpinner: false,
        formDataDetails: action.payload,
      };

    case types.GET_FORM_DATA_DETAILS_FAILURE:
      return {
        ...state,
        getFormDetails: false,
        getFormDetailsSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_FORM_DETAILS_REQUEST:
      return {
        ...state,
        getFormDetails: false,
        getFormDetailsSpinner: false,
      };

    case types.CREATE_FORM_DATA_REQUEST:
      return {
        ...state,
        createFormDataSuccess: false,
        createFormDataSpinner: true,
      };

    case types.CREATE_FORM_DATA_SUCCESS:
      return {
        ...state,
        createFormDataSuccess: true,
        createFormDataSpinner: false,
        open: true,
        success: {
          displayMessage: action.payload.message,
        },
        timedOut: action.payload.timedOut,
        createFormDataResponse: action.payload.data,
      };

    case types.CREATE_FORM_DATA_FAILURE:
      return {
        ...state,
        createFormDataSuccess: false,
        createFormDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_CREATE_FORM_DATA_REQUEST:
      return {
        ...state,
        createFormDataSuccess: false,
        createFormDataSpinner: false,
        timedOut: false,
      };

    case types.UPDATE_FORM_DATA_REQUEST:
      return {
        ...state,
        updateFormDataSuccess: false,
        updateFormDataSpinner: true,
      };

    case types.UPDATE_FORM_DATA_SUCCESS:
      return {
        ...state,
        updateFormDataSuccess: true,
        updateFormDataSpinner: false,
        open: true,
        success: {
          displayMessage: action.payload.message,
        },
        timedOut: action.payload.timedOut,
        updateFormDataResponse: action.payload.data,
      };

    case types.UPDATE_FORM_DATA_FAILURE:
      return {
        ...state,
        updateFormDataSuccess: false,
        updateFormDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_UPDATE_FORM_DATA_REQUEST:
      return {
        ...state,
        updateFormDataSuccess: false,
        updateFormDataSpinner: false,
        timedOut: false,
      };

    case types.GET_LOOKUP_INSTANCEIDS_REQUEST:
      return {
        ...state,
        getLookupInstanceIdsSuccess: false,
        getLookupInstanceIdsSpinner: true,
      };

    case types.GET_LOOKUP_INSTANCEIDS_SUCCESS:
      return {
        ...state,
        getLookupInstanceIdsSuccess: true,
        getLookupInstanceIdsSpinner: false,
        lookupIds:
          action.payload && action.payload.modified.length > 0
            ? action.payload.modified
            : [],
      };

    case types.GET_LOOKUP_INSTANCEIDS_FAILURE:
      return {
        ...state,
        getLookupInstanceIdsSuccess: false,
        getLookupInstanceIdsSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_LOOKUP_INSTANCEIDS_REQUEST:
      return {
        ...state,
        getLookupInstanceIdsSuccess: false,
        getLookupInstanceIdsSpinner: false,
      };

    case types.GET_EXTERNAL_LOOKUP_DATA_REQUEST:
      return {
        ...state,
        getExternalLookupDataSuccess: false,
        getExternalLookupDataSpinner: true,
      };

    case types.GET_EXTERNAL_LOOKUP_DATA_SUCCESS:
      return {
        ...state,
        getExternalLookupDataSuccess: true,
        getExternalLookupDataSpinner: false,
        externalLookupData: action.payload,
      };

    case types.GET_EXTERNAL_LOOKUP_DATA_FAILURE:
      return {
        ...state,
        getExternalLookupDataSuccess: false,
        getExternalLookupDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_EXTERNAL_LOOKUP_DATA_REQUEST:
      return {
        ...state,
        getExternalLookupDataSuccess: false,
        getExternalLookupDataSpinner: false,
      };

    case types.GET_CATALOG_DATA_REQUEST:
      return {
        ...state,
        catalogDataSuccess: false,
        catalogDataSpinner: true,
      };

    case types.GET_CATALOG_DATA_SUCCESS:
      return {
        ...state,
        catalogDataSuccess: true,
        catalogDataSpinner: false,
        catalogData: action.payload,
      };

    case types.GET_CATALOG_DATA_FAILURE:
      return {
        ...state,
        catalogDataSuccess: false,
        catalogDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_CATALOG_DATA_REQUEST:
      return {
        ...state,
        catalogDataSuccess: false,
        catalogDataSpinner: false,
      };

    case types.GET_INBOX_INSTANCEIDS_REQUEST:
      return {
        ...state,
        getInboxInstanceIdsSpinner: true,
        getInboxInstanceIdsSuccess: false,
      };

    case types.GET_INBOX_INSTANCEIDS_SUCCESS:
      return {
        ...state,
        getInboxInstanceIdsSpinner: false,
        getInboxInstanceIdsSuccess: true,
        inboxInstancesIds: action.payload ? action.payload.modified : [],
      };

    case types.GET_INBOX_INSTANCEIDS_FAILURE:
      return {
        ...state,
        getInboxInstanceIdsSpinner: false,
        getInboxInstanceIdsSuccess: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_INBOX_INSTANCEIDS_REQUEST:
      return {
        ...state,
        getInboxInstanceIdsSpinner: false,
        getInboxInstanceIdsSuccess: false,
      };

    case types.SET_EXTPROPS:
      return {
        ...state,
        externalProps: action.payload.externalProps,
      };

    case types.GET_UNASSIGNED_DATA_REQUEST:
      return {
        ...state,
        unassignedDataSuccess: false,
        unassignedDataSpinner: true,
      };

    case types.GET_UNASSIGNED_DATA_SUCCESS:
      return {
        ...state,
        unassignedDataSuccess: true,
        unassignedDataSpinner: false,
        unassignedData: action.payload['data'],
        unassignedDataTotalItems: action.payload['total_items'],
      };

    case types.GET_UNASSIGNED_DATA_FAILURE:
      return {
        ...state,
        unassignedDataSuccess: false,
        unassignedDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_UNASSIGNED_DATA_REQUEST:
      return {
        ...state,
        unassignedDataSuccess: false,
        unassignedDataSpinner: false,
      };

    case types.UPDATE_ASSIGNEE_REQUEST:
      return {
        ...state,
        updateAssigneeSuccess: false,
        updateAssigneeSpinner: true,
      };

    case types.UPDATE_ASSIGNEE_SUCCESS:
      return {
        ...state,
        updateAssigneeSuccess: true,
        updateAssigneeSpinner: false,
        updateAssigneeDetails: action.payload,
      };

    case types.UPDATE_ASSIGNEE_FAILURE:
      return {
        ...state,
        updateAssigneeSuccess: false,
        updateAssigneeSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_UPDATE_ASSIGNEE_REQUEST:
      return {
        ...state,
        updateAssigneeSuccess: false,
        updateAssigneeSpinner: false,
      };

    case types.GET_FIREBASE_IMAGE_REQUEST:
      return {
        ...state,
        getImgSuccess: false,
        getImgSpinner: true,
      };

    case types.GET_FIREBASE_IMAGE_SUCCESS:
      return {
        ...state,
        getImgSuccess: true,
        getImgSpinner: false,
        blobUrl: action.payload,
      };

    case types.GET_FIREBASE_IMAGE_FAILURE:
      return {
        ...state,
        getImgSuccess: false,
        getImgSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_FIREBASE_IMAGE_REQUEST:
      return {
        ...state,
        getImgSuccess: false,
        getImgspinner: false,
      };

    case types.GET_ALL_INBOX_CONFIGS_REQUEST:
      return {
        ...state,
        allInboxConfigReqSuccess: false,
        inboxConfigSpinner: true,
      };

    case types.GET_ALL_INBOX_CONFIGS_SUCCESS:
      return {
        ...state,
        allInboxConfigReqSuccess: true,
        inboxConfigSpinner: false,
        allInboxConfigs: action.payload,
      };

    case types.GET_ALL_INBOX_CONFIGS_FAILURE:
      return {
        ...state,
        allInboxConfigReqSuccess: false,
        inboxConfigSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_ALL_INBOX_CONFIGS_REQUEST:
      return {
        ...state,
        allInboxConfigReqSuccess: false,
        inboxConfigSpinner: false,
      };

    case types.SET_INBOX_VIEWS_TOTAL_COUNT:
      return {
        ...state,
        inboxTotalCount: action.payload['totalCount'],
      };

    case types.SET_SELECTED_INBOX_VIEW:
      return {
        ...state,
        inboxView: action.payload['inboxView'],
      };

    case types.SET_INBOX_SEARCH_TEXT:
      return {
        ...state,
        inboxSearchText: action.payload['searchText'],
      };

    case types.SET_ACTIVE_UNASSIGNED_STAGE:
      return {
        ...state,
        unassignedActiveStage: action.payload['unassignedActiveStage'],
      };

    case types.SET_UNASSIGNED_SEARCH_TEXT:
      return {
        ...state,
        unassignedSearchText: action.payload['unassignedSearchText'],
      };

    case types.SET_SELECTED_FIELD_VALUE:
      return {
        ...state,
        open: action.payload['open'],
        selectedMsg: action.payload['message'],
      };

    case types.CREATE_VENDOR_ONBOARDING_FORM_REQUEST:
      return {
        ...state,
        customCreateFormDataSuccess: false,
        customCreateFormDataSpinner: true,
      };

    case types.CREATE_VENDOR_ONBOARDING_FORM_SUCCESS:
      return {
        ...state,
        customCreateFormDataSuccess: true,
        customCreateFormDataSpinner: false,
      };

    case types.CREATE_VENDOR_ONBOARDING_FORM_FAILURE:
      return {
        ...state,
        customCreateFormDataSuccess: false,
        customCreateFormDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_CREATE_VENDOR_ONBOARDING_FORM_REQUEST:
      return {
        ...state,
        customCreateFormDataSuccess: false,
        customCreateFormDataSpinner: false,
      };

    case types.ACCESS_TOKEN_FOR_GUEST_USER_REQUEST:
      return {
        ...state,
        guestUserAccessToken: null,
        guestUserAccessTokenSuccess: false,
        guestUserAccessTokenFailure: false,
        guestUserSpinner: true,
      };

    case types.ACCESS_TOKEN_FOR_GUEST_USER_SUCCESS:
      return {
        ...state,
        guestUserAccessToken: action.payload['access_token'],
        guestTemplateId: action.payload['template_id'],
        guestTeamId: action.payload['team_id'],
        permissions: decodeAccessToken(action.payload['access_token'])
          .permissions,
        guestUserAccessTokenSuccess: true,
        guestUserAccessTokenFailure: false,
        guestUserSpinner: false,
      };

    case types.ACCESS_TOKEN_FOR_GUEST_USER_FAILURE:
      return {
        ...state,
        guestUserAccessToken: null,
        guestUserAccessTokenSuccess: false,
        guestUserAccessTokenFailure: true,
        guestUserSpinner: false,
      };

    case types.RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST:
      return {
        ...state,
        guestUserAccessTokenSuccess: false,
        guestUserSpinner: false,
        guestUserAccessTokenFailure: false,
      };

    case types.GET_OTP_FOR_GUEST_USER_REQUEST:
      return {
        ...state,
        guestUserOtpSuccess: false,
        guestUserOtpRequest: true,
      };

    case types.GET_OTP_FOR_GUEST_USER_SUCCESS:
      return {
        ...state,
        guestUserRequestId: action.payload['request_id'],
        guestUserOtpSuccess: true,
        guestUserOtpRequest: false,
      };

    case types.GET_OTP_FOR_GUEST_USER_FAILURE:
      return {
        ...state,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
        guestUserOtpSuccess: false,
        guestUserOtpRequest: false,
      };

    case types.RESET_GET_OTP_FOR_GUEST_USER_REQUEST:
      return {
        ...state,
        guestUserOtpSuccess: false,
        guestUserOtpRequest: false,
      };

    case types.VALIDATE_GUEST_USER_OTP_REQUEST:
      return {
        ...state,
        validateGuestUserOtpSuccess: false,
        validateGuestUserOtpRequest: true,
      };

    case types.VALIDATE_GUEST_USER_OTP_SUCCESS:
      return {
        ...state,
        validateGuestUserOtpSuccess: true,
        validateGuestUserOtpRequest: false,
      };

    case types.VALIDATE_GUEST_USER_OTP_FAILURE:
      return {
        ...state,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
        validateGuestUserOtpSuccess: false,
        validateGuestUserOtpRequest: false,
      };

    case types.RESET_VALIDATE_GUEST_USER_OTP_REQUEST:
      return {
        ...state,
        validateGuestUserOtpSuccess: false,
        validateGuestUserOtpRequest: false,
      };

    case types.RESEND_GUEST_USER_OTP_SUCCESS:
      return {
        ...state,
        guestUserRequestId: action.payload['request_id'],
      };

    case types.GET_ACTIVITY_LOG_REQUEST:
      return {
        ...state,
        activityLogSuccess: false,
        activityLogSpinner: true,
      };

    case types.GET_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        activityLog: action.payload,
        activityLogSuccess: true,
        activityLogSpinner: false,
      };

    case types.GET_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        activityLogSuccess: false,
        activityLogSpinner: false,
      };

    case types.GET_TEAM_DESIGNATIONS_REQUEST:
      return {
        ...state,
        teamdesignations: [],
        teamdesignationsSpinner: true,
        teamdesignationsSuccess: false,
        teamdesignationsFailure: false,
      };

    case types.GET_TEAM_DESIGNATIONS_SUCCESS:
      return {
        ...state,
        teamdesignations: action.payload,
        teamdesignationsSpinner: false,
        teamdesignationsSuccess: true,
        teamdesignationsFailure: false,
      };

    case types.GET_TEAM_DESIGNATIONS_FAILURE:
      return {
        ...state,
        teamdesignationsSpinner: false,
        teamdesignationsSuccess: false,
        teamdesignationsFailure: true,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_TEAM_DESIGNATIONS_REQUEST:
      return {
        ...state,
        teamdesignationsSpinner: false,
        teamdesignationsSuccess: false,
        teamdesignationsFailure: false,
      };

    case types.CREATE_NAVEEN_FORM_DATA_REQUEST:
      return {
        ...state,
        naveenCreateFormDataSuccess: false,
        naveenCreateFormDataSpinner: true,
      };

    case types.CREATE_NAVEEN_FORM_DATA_SUCCESS:
      return {
        ...state,
        naveenCreateFormDataSuccess: true,
        naveenCreateFormDataSpinner: false,
        naveenCreateFormDataResponse: action.payload.data,
      };

    case types.CREATE_NAVEEN_FORM_DATA_FAILURE:
      return {
        ...state,
        naveenCreateFormDataSuccess: false,
        naveenCreateFormDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_NAVEEN_CREATE_FORM_DATA_REQUEST:
      return {
        ...state,
        naveenCreateFormDataSuccess: false,
        naveenCreateFormDataSpinner: false,
      };

    case types.SET_GUEST_FORMDATA:
      return {
        ...state,
        guestFormDetails: action.payload.guestFormDetails,
      };

    case types.UPDATE_NAVEEN_FORM_DATA_REQUEST:
      return {
        ...state,
        naveenUpdateFormDataSuccess: false,
        naveenUpdateFormDataSpinner: true,
      };

    case types.UPDATE_NAVEEN_FORM_DATA_SUCCESS:
      return {
        ...state,
        naveenUpdateFormDataSuccess: true,
        naveenUpdateFormDataSpinner: false,
        naveenUpdateFormDataResponse: action.payload.data,
      };

    case types.UPDATE_NAVEEN_FORM_DATA_FAILURE:
      return {
        ...state,
        naveenUpdateFormDataSuccess: false,
        naveenUpdateFormDataSpinner: false,
        open: true,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_NAVEEN_UPDATE_FORM_DATA_REQUEST:
      return {
        ...state,
        naveenUpdateFormDataSuccess: false,
        naveenUpdateFormDataSpinner: false,
      };

    case types.ORDER_PAY_REQUEST:
      return {
        ...state,
        orderPayRequest: true,
        orderPaySuccess: false,
      };

    case types.ORDER_PAY_SUCCESS:
      return {
        ...state,
        orderPayRequest: false,
        orderPaySuccess: true,
        orderPayDetails: action.payload,
      };

    case types.ORDER_PAY_FAILURE:
      return {
        ...state,
        orderPayRequest: false,
        orderPaySuccess: false,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
      };

    case types.RESET_ORDER_PAY_REQUEST:
      return {
        ...state,
        orderPayRequest: false,
        orderPaySuccess: false,
      };

    case types.PAYMENT_CONFIRM_REQUEST:
      return {
        ...state,
        paymentConfirmRequest: true,
        paymentConfirmSuccess: false,
      };

    case types.PAYMENT_CONFIRM_SUCCESS:
      return {
        ...state,
        paymentConfirmRequest: false,
        paymentConfirmSuccess: true,
        paymentConfirmDetails: action.payload,
      };

    case types.PAYMENT_CONFIRM_FAILURE:
      return {
        ...state,
        paymentConfirmRequest: false,
        paymentConfirmSuccess: false,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
      };

    case types.RESET_PAYMENT_CONFIRM_REQUEST:
      return {
        ...state,
        paymentConfirmRequest: false,
        paymentConfirmSuccess: false,
      };

    case types.SET_RAZORPAY_RESPONSE:
      return {
        ...state,
        razoResp: action.payload.razoResp,
      };

    case types.RESET_CUSTOM_DATA:
      return {
        ...state,
        razoResp: {},
        paymentConfirmDetails: {},
        orderPayDetails: {},
        guestFormDetails: {},
        irctcPayConfirmDetails: {},
        irctcOrderPayDetails: {},
      };

    case teamTypes.GET_TEAM_DETAILS_REQUEST:
      return {
        ...state,
        teamDetails: {},
        teamDetailsSuccess: false,
        teamDetailsFailure: false,
        teamDetailsReq: true,
      };

    case teamTypes.GET_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        teamDetails: action.payload,
        teamDetailsSuccess: true,
        teamDetailsFailure: false,
        teamDetailsReq: false,
      };

    case teamTypes.GET_TEAM_DETAILS_FAILURE:
      return {
        ...state,
        teamDetailsSuccess: false,
        teamDetailsReq: false,
        teamDetailsFailure: true,
      };

    case teamTypes.RESET_GET_TEAM_DETAILS_REQUEST:
      return {
        ...state,
        teamDetailsSuccess: false,
        teamDetailsReq: false,
        teamDetailsFailure: false,
      };

    case types.IRCTC_ORDER_PAY_REQUEST:
      return {
        ...state,
        irctcOrderPayRequest: true,
        irctcOrderPaySuccess: false,
      };

    case types.IRCTC_ORDER_PAY_SUCCESS:
      return {
        ...state,
        irctcOrderPayRequest: false,
        irctcOrderPaySuccess: true,
        irctcOrderPayDetails: action.payload,
      };

    case types.IRCTC_ORDER_PAY_FAILURE:
      return {
        ...state,
        irctcOrderPayRequest: false,
        irctcOrderPaySuccess: false,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
      };

    case types.RESET_IRCTC_ORDER_PAY_REQUEST:
      return {
        ...state,
        irctcOrderPayRequest: false,
        irctcOrderPaySuccess: false,
      };

    case types.IRCTC_PAYMENT_CONFIRM_REQUEST:
      return {
        ...state,
        irctcPayConfirmRequest: true,
        irctcPayConfirmSuccess: false,
      };

    case types.IRCTC_PAYMENT_CONFIRM_SUCCESS:
      return {
        ...state,
        irctcPayConfirmRequest: false,
        irctcPayConfirmSuccess: true,
        irctcPayConfirmDetails: action.payload,
      };

    case types.IRCTC_PAYMENT_CONFIRM_FAILURE:
      return {
        ...state,
        irctcPayConfirmRequest: false,
        irctcPayConfirmSuccess: false,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
      };

    case types.RESET_IRCTC_PAYMENT_CONFIRM_REQUEST:
      return {
        ...state,
        irctcPayConfirmRequest: false,
        irctcPayConfirmSuccess: false,
      };

    case types.GET_IRCTC_CARD_LIST_REQUEST:
      return {
        ...state,
        irctcCardListReq: true,
        irctcCardListSuccess: false,
      };

    case types.GET_IRCTC_CARD_LIST_SUCCESS:
      return {
        ...state,
        irctcCardListReq: false,
        irctcCardListSuccess: true,
        irctcCardList: action.payload,
      };

    case types.GET_IRCTC_CARD_LIST_FAILURE:
      return {
        ...state,
        irctcCardListReq: false,
        irctcCardListSuccess: false,
        open: true,
        error: { displayMessage: 'Unable to process the request' },
      };

    case types.RESET_GET_IRCTC_CARD_LIST_REQUEST:
      return {
        ...state,
        irctcCardListReq: false,
        irctcCardListSuccess: false,
      };

    case types.GET_OTP_FOR_FORM_REQUEST:
      return {
        ...state,
        otpFormSuccess: false,
        otpFormFailure: false,
      };

    case types.GET_OTP_FOR_FORM_SUCCESS:
      return {
        ...state,
        otpFormSuccess: true,
        otpFormFailure: false,
        otpFormData: action.payload,
      };

    case types.GET_OTP_FOR_FORM_FAILURE:
      return {
        ...state,
        otpFormSuccess: false,
        otpFormFailure: true,
      };

    case types.GET_NE_PRODUCT_FILTER_OPTIONS_REQUEST:
      return {
        ...state,
        productFilterOptionsSuccess: false,
      };

    case types.GET_NE_PRODUCT_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        productFilterOptionsSuccess: true,
        productFilterOptions: action.payload,
      };

    case types.GET_NE_PRODUCT_FILTER_OPTIONS_FAILURE:
    case types.RESET_NE_PRODUCT_FILTER_OPTIONS_REQUEST:
      return {
        ...state,
        productFilterOptionsSuccess: false,
      };

    default:
      return state;
  }
};

export default reportReducer;
