import EndPointConfig from '../../../utils/endPointConfig';

export async function getMyTeams(data: any, header: any) {
  const url = EndPointConfig.Teams.getMyTeams(data.userId);
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });

    return {
      status: response.ok,
      data: await response.json(),
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
}
