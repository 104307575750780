import * as types from '../../../actions/types';

export function getOTPForUserName(
  userName: string,
  loginType: string,
  navigate: any
) {
  return {
    type: types.GET_OTP_FOR_USERNAME_REQUEST,
    payload: {
      userName,
      loginType,
      navigate,
    },
  };
}

export function resetGetOtpRequest() {
  return {
    type: types.RESET_GET_OTP_REQUEST,
  };
}

export function validateOTP(requestId: string, otp: number, navigate: any) {
  return {
    type: types.VALIDATE_OTP_REQUEST,
    payload: {
      requestId,
      otp,
      navigate,
    },
  };
}

export function resendOTP(requestId: string) {
  return {
    type: types.RESEND_OTP_REQUEST,
    payload: {
      requestId,
    },
  };
}

export function resetErrorMessage() {
  return {
    type: types.RESET_ERROR_MESSAGE,
  };
}

export function resetSuccessMessage() {
  return {
    type: types.RESET_SUCCESS_MESSAGE,
  };
}

export function resetRequestFalse() {
  return {
    type: types.LOGIN_REQUEST_FALSE,
  };
}

export function getSWRIDCard(id: string, isFromIrctc = false) {
  return {
    type: types.GET_SWR_ID_CARD_REQUEST,
    payload: {
      id,
      isFromIrctc,
    },
  };
}
