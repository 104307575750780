import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetRequestFalse } from '../actions/actions';
import EmailLogin from '../components/emailLogin';
import MobileLogin from '../components/mobileLogin';
import Carousel from '../components/carousel';
import Footer from '../components/footer';
import '../styles/login.css';
import withRouter from 'withRouter';

interface IState {
  emailMobile: boolean;
}

interface IProps {
  resetRequestFalse: () => void;
  router: any;
}

class Login extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      emailMobile: false,
    };
  }

  componentDidMount() {
    this.props.resetRequestFalse();
    if (sessionStorage.getItem('accessToken')) {
      this.props.router.navigate(`/member/home`);
    }
  }

  changeValue = (value: boolean) => {
    this.setState({ emailMobile: value });
  };

  render() {
    return (
      <>
        <div id="login">
          <div className="image-topright" />
          <div className="container-fluid">
            <div style={{ display: 'flex' }}>
              <div className="content-login">
                <Carousel />
              </div>
              <div style={{ width: '35%' }}>
                <div className="card1">
                  <div className="logincard">
                    <div className="powered-by">
                      <img
                        src={require('../../../assets/images/userOnBoarding/logo-big.png')}
                        alt="logo"
                      />
                    </div>
                    <div className="content">
                      <h2>Sign into your Team</h2>
                      <div className="login-input-title">
                        <span
                          className={this.state.emailMobile ? '' : 'active'}
                          onClick={() => this.changeValue(false)}
                        >
                          Mobile
                        </span>
                        <span>|</span>
                        <span
                          className={this.state.emailMobile ? 'active' : ''}
                          onClick={() => this.changeValue(true)}
                        >
                          Email
                        </span>
                      </div>
                      {this.state.emailMobile ? (
                        <EmailLogin {...this.props} />
                      ) : (
                        <MobileLogin {...this.props} />
                      )}
                      <p>
                        By going forward you agree to our{' '}
                        <a
                          style={{ color: '#00ACF1' }}
                          href="https://mobileoffize.com/termsofuse"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          terms of service
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapDispatchToProps = {
  resetRequestFalse,
};

export default connect<any, any, any>(
  null,
  mapDispatchToProps
)(withRouter(Login));
