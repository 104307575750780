import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../../userOnBoarding/actions/actions';
import { isNull } from '../../../../../utils/helper';
import * as types from '../../../../../actions/types';
import '../../../styles/form/guestSummary.css';
import withRouter from 'withRouter';

interface IState {}

interface IProps {
  dispatch: Dispatch;
  razoResp: any;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  irctcPayConfirmDetails: any;
  router: any;
}

class Success extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (isNull(this.props.razoResp)) {
      this.props.router.navigate(
        `/p/irctc/smartid/${this.props.router.params.publicId}/${this.props.router.params.desigId}`
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: types.RESET_CUSTOM_DATA,
    });
  }

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  getCurrentFinancialYear = () => {
    let fiscalyear = '';
    const today = new Date();
    if (today.getMonth() + 1 <= 3) {
      fiscalyear =
        today.getFullYear() -
        1 +
        '-' +
        today.getFullYear().toString().slice(-2);
    } else {
      fiscalyear =
        today.getFullYear() +
        '-' +
        (today.getFullYear() + 1).toString().slice(-2);
    }

    return fiscalyear;
  };

  getOrderNumber = () => {
    const orderNum =
      this.props.irctcPayConfirmDetails?.data?.order_number ||
      this.props.razoResp?.orderId;
    return `L-${orderNum}/${this.getCurrentFinancialYear()}`;
  };

  render() {
    return (
      <div id="guest-summary-root">
        <div className="guest-success-wrapper">
          <div className="guest-success-content-wrapper">
            <div className="circle-check-icon">
              <i className="fas fa-check" />
            </div>
            <p className="order-successfull">Order Successfully Placed</p>
            <span className="order-no">Order No: {this.getOrderNumber()}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    razoResp: state.reportReducer.razoResp,
    irctcPayConfirmDetails: state.reportReducer.irctcPayConfirmDetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Success));
