import React from 'react';
import Snackbar from '@mui/material/Snackbar';

const SnackbarMessage = (props: any) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={props.open}
      onClose={props.handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      autoHideDuration={4000}
      message={<span id="message-id">{props.message}</span>}
    />
  );
};

export default SnackbarMessage;
