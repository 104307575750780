export const INITIALIZE_STATE = 'INITIALIZE_STATE';

export const GET_OTP_FOR_USERNAME_REQUEST = 'GET_OTP_FOR_USERNAME_REQUEST';
export const GET_OTP_FOR_USERNAME_SUCCESS = 'GET_OTP_FOR_USERNAME_SUCCESS';
export const GET_OTP_FOR_USERNAME_FAILURE = 'GET_OTP_FOR_USERNAME_FAILURE';
export const RESET_GET_OTP_REQUEST = 'RESET_GET_OTP_REQUEST';

export const VALIDATE_OTP_REQUEST = 'VALIDATE_OTP_REQUEST';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILURE = 'VALIDATE_OTP_FAILURE';

export const RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';

export const ACCESS_TOKEN_REQUEST = 'ACCESS_TOKEN_REQUEST';
export const ACCESS_TOKEN_SUCCESS = 'ACCESS_TOKEN_SUCCESS';
export const ACCESS_TOKEN_FAILURE = 'ACCESS_TOKEN_FAILURE';

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const RESET_SUCCESS_MESSAGE = 'RESET_SUCCESS_MESSAGE';

export const LOGIN_REQUEST_FALSE = 'LOGIN_REQUEST_FALSE';

export const GET_ALL_MY_REPORTS_REQUEST = 'GET_ALL_MY_REPORTS_REQUEST';
export const GET_ALL_MY_REPORTS_SUCCESS = 'GET_ALL_MY_REPORTS_SUCCESS';
export const GET_ALL_MY_REPORTS_FAILURE = 'GET_ALL_MY_REPORTS_FAILURE';
export const RESET_GET_ALL_REPORTS_REQUEST = 'RESET_GET_ALL_REPORTS_REQUEST';

export const ACCESS_TOKEN_FOR_TEAM_REQUEST = 'ACCESS_TOKEN_FOR_TEAM_REQUEST';
export const ACCESS_TOKEN_FOR_TEAM_SUCCESS = 'ACCESS_TOKEN_FOR_TEAM_SUCCESS';
export const ACCESS_TOKEN_FOR_TEAM_FAILURE = 'ACCESS_TOKEN_FOR_TEAM_FAILURE';
export const RESET_GET_ACCESS_TOKEN_TEAM_REQUEST =
  'RESET_GET_ACCESS_TOKEN_TEAM_REQUEST';

export const GET_MY_TEAMS_REQUEST = 'GET_MY_TEAMS_REQUEST';
export const GET_MY_TEAMS_SUCCESS = 'GET_MY_TEAMS_SUCCESS';
export const GET_MY_TEAMS_FAILURE = 'GET_MY_TEAMS_FAILURE';
export const RESET_GET_MY_TEAMS_REQUEST = 'RESET_GET_MY_TEAMS_REQUEST';

export const SET_TEAM_ID = 'SET_TEAM_ID';
export const RESET_ALL_REPORTS = 'RESET_ALL_REPORTS';

export const GET_INBOX_DATA_REQUEST = 'GET_INBOX_DATA_REQUEST';
export const GET_INBOX_DATA_SUCCESS = 'GET_INBOX_DATA_SUCCESS';
export const GET_INBOX_DATA_FAILURE = 'GET_INBOX_DATA_FAILURE';
export const RESET_INBOX_DATA_REQUEST = 'RESET_INBOX_DATA_REQUEST';

export const GET_REPORT_DETAILS_REQUEST = 'GET_REPORT_DETAILS_REQUEST';
export const GET_REPORT_DETAILS_SUCCESS = 'GET_REPORT_DETAILS_SUCCESS';
export const GET_REPORT_DETAILS_FAILURE = 'GET_REPORT_DETAILS_FAILURE';
export const RESET_REPORT_DETAILS_REQUEST = 'RESET_REPORT_DETAILS_REQUEST';

export const GET_FORM_DATA_DETAILS_REQUEST = 'GET_FORM_DATA_DETAILS_REQUEST';
export const GET_FORM_DATA_DETAILS_SUCCESS = 'GET_FORM_DATA_DETAILS_SUCCESS';
export const GET_FORM_DATA_DETAILS_FAILURE = 'GET_FORM_DATA_DETAILS_FAILURE';
export const RESET_GET_FORM_DETAILS_REQUEST = 'RESET_GET_FORM_DETAILS_REQUEST';

export const CREATE_FORM_DATA_REQUEST = 'CREATE_FORM_DATA_REQUEST';
export const CREATE_FORM_DATA_SUCCESS = 'CREATE_FORM_DATA_SUCCESS';
export const CREATE_FORM_DATA_FAILURE = 'CREATE_FORM_DATA_FAILURE';
export const RESET_CREATE_FORM_DATA_REQUEST = 'RESET_CREATE_FORM_DATA_REQUEST';

export const UPDATE_FORM_DATA_REQUEST = 'UPDATE_FORM_DATA_REQUEST';
export const UPDATE_FORM_DATA_SUCCESS = 'UPDATE_FORM_DATA_SUCCESS';
export const UPDATE_FORM_DATA_FAILURE = 'UPDATE_FORM_DATA_FAILURE';
export const RESET_UPDATE_FORM_DATA_REQUEST = 'RESET_UPDATE_FORM_DATA_REQUEST';

export const GET_LOOKUP_INSTANCEIDS_REQUEST = 'GET_LOOKUP_INSTANCEIDS_REQUEST';
export const GET_LOOKUP_INSTANCEIDS_SUCCESS = 'GET_LOOKUP_INSTANCEIDS_SUCCESS';
export const GET_LOOKUP_INSTANCEIDS_FAILURE = 'GET_LOOKUP_INSTANCEIDS_FAILURE';
export const RESET_GET_LOOKUP_INSTANCEIDS_REQUEST =
  'RESET_GET_LOOKUP_INSTANCEIDS_REQUEST';

export const GET_EXTERNAL_LOOKUP_DATA_REQUEST =
  'GET_EXTERNAL_LOOKUP_DATA_REQUEST';
export const GET_EXTERNAL_LOOKUP_DATA_SUCCESS =
  'GET_EXTERNAL_LOOKUP_DATA_SUCCESS';
export const GET_EXTERNAL_LOOKUP_DATA_FAILURE =
  'GET_EXTERNAL_LOOKUP_DATA_FAILURE';
export const RESET_GET_EXTERNAL_LOOKUP_DATA_REQUEST =
  'RESET_GET_EXTERNAL_LOOKUP_DATA_REQUEST';

export const RESET_REPORT_DETAILS_ON_TEAM_CHANGE =
  'RESET_REPORT_DETAILS_ON_TEAM_CHANGE';

export const GET_CATALOG_DATA_REQUEST = 'GET_CATALOG_DATA_REQUEST';
export const GET_CATALOG_DATA_SUCCESS = 'GET_CATALOG_DATA_SUCCESS';
export const GET_CATALOG_DATA_FAILURE = 'GET_CATALOG_DATA_FAILURE';
export const RESET_GET_CATALOG_DATA_REQUEST = 'RESET_GET_CATALOG_DATA_REQUEST';

export const GET_TEAM_USER_DETAILS_REQUEST = 'GET_TEAM_USER_DETAILS_REQUEST';
export const GET_TEAM_USER_DETAILS_SUCCESS = 'GET_TEAM_USER_DETAILS_SUCCESS';
export const GET_TEAM_USER_DETAILS_FAILURE = 'GET_TEAM_USER_DETAILS_FAILURE';
export const RESET_GET_TEAM_USER_DETAILS_REQUEST =
  'RESET_GET_TEAM_USER_DETAILS_REQUEST';

export const GET_INBOX_INSTANCEIDS_REQUEST = 'GET_INBOX_INSTANCEIDS_REQUEST';
export const GET_INBOX_INSTANCEIDS_SUCCESS = 'GET_INBOX_INSTANCEIDS_SUCCESS';
export const GET_INBOX_INSTANCEIDS_FAILURE = 'GET_INBOX_INSTANCEIDS_FAILURE';
export const RESET_GET_INBOX_INSTANCEIDS_REQUEST =
  'RESET_GET_INBOX_INSTANCEIDS_REQUEST';

export const SET_EXTPROPS = 'SET_EXTPROPS';

export const GET_UNASSIGNED_DATA_REQUEST = 'GET_UNASSIGNED_DATA_REQUEST';
export const GET_UNASSIGNED_DATA_SUCCESS = 'GET_UNASSIGNED_DATA_SUCCESS';
export const GET_UNASSIGNED_DATA_FAILURE = 'GET_UNASSIGNED_DATA_FAILURE';
export const RESET_GET_UNASSIGNED_DATA_REQUEST =
  'RESET_GET_UNASSIGNED_DATA_REQUEST';

export const UPDATE_ASSIGNEE_REQUEST = 'UPDATE_ASSIGNEE_REQUEST';
export const UPDATE_ASSIGNEE_SUCCESS = 'UPDATE_ASSIGNEE_SUCCESS';
export const UPDATE_ASSIGNEE_FAILURE = 'UPDATE_ASSIGNEE_FAILURE';
export const RESET_UPDATE_ASSIGNEE_REQUEST = 'RESET_UPDATE_ASSIGNEE_REQUEST';

export const GET_FIREBASE_IMAGE_REQUEST = 'GET_FIREBASE_IMAGE_REQUEST';
export const GET_FIREBASE_IMAGE_SUCCESS = 'GET_FIREBASE_IMAGE_SUCCESS';
export const GET_FIREBASE_IMAGE_FAILURE = 'GET_FIREBASE_IMAGE_FAILURE';
export const RESET_GET_FIREBASE_IMAGE_REQUEST =
  'RESET_GET_FIREBASE_IMAGE_REQUEST';

export const GET_ALL_INBOX_CONFIGS_REQUEST = 'GET_ALL_INBOX_CONFIGS_REQUEST';
export const GET_ALL_INBOX_CONFIGS_SUCCESS = 'GET_ALL_INBOX_CONFIGS_SUCCESS';
export const GET_ALL_INBOX_CONFIGS_FAILURE = 'GET_ALL_INBOX_CONFIGS_FAILURE';
export const RESET_GET_ALL_INBOX_CONFIGS_REQUEST =
  'RESET_GET_ALL_INBOX_CONFIGS_REQUEST';

export const GET_INBOX_VIEWS_DATA_REQUEST = 'GET_INBOX_VIEWS_DATA_REQUEST';
export const GET_INBOX_VIEWS_DATA_SUCCESS = 'GET_INBOX_VIEWS_DATA_SUCCESS';
export const GET_INBOX_VIEWS_DATA_FAILURE = 'GET_INBOX_VIEWS_DATA_FAILURE';
export const SET_INBOX_VIEWS_TOTAL_COUNT = 'SET_INBOX_VIEWS_TOTAL_COUNT';
export const SET_SELECTED_INBOX_VIEW = 'SET_SELECTED_INBOX_VIEW';
export const SET_INBOX_SEARCH_TEXT = 'SET_INBOX_SEARCH_TEXT';

export const GET_USER_DESIGNATION_DETAILS_REQUEST =
  'GET_USER_DESIGNATION_DETAILS_REQUEST';
export const GET_USER_DESIGNATION_DETAILS_SUCCESS =
  'GET_USER_DESIGNATION_DETAILS_SUCCESS';
export const GET_USER_DESIGNATION_DETAILS_FAILURE =
  'GET_USER_DESIGNATION_DETAILS_FAILURE';
export const RESET_GET_USER_DESIGNATION_DETAILS_REQUEST =
  'RESET_GET_USER_DESIGNATION_DETAILS_REQUEST';

export const SET_ACTIVE_UNASSIGNED_STAGE = 'SET_ACTIVE_UNASSIGNED_STAGE';
export const SET_UNASSIGNED_SEARCH_TEXT = 'SET_UNASSIGNED_SEARCH_TEXT';

export const GET_USER_DIRECTORY_DESIGNATION_REQUEST =
  'GET_USER_DIRECTORY_DESIGNATION_REQUEST';
export const GET_USER_DIRECTORY_DESIGNATION_SUCCESS =
  'GET_USER_DIRECTORY_DESIGNATION_SUCCESS';
export const GET_USER_DIRECTORY_DESIGNATION_FAILURE =
  'GET_USER_DIRECTORY_DESIGNATION_FAILURE';
export const RESET_GET_USER_DIRECTORY_DESIGNATION_REQUEST =
  'RESET_GET_USER_DIRECTORY_DESIGNATION_REQUEST';

export const SET_SELECTED_FIELD_VALUE = 'SET_SELECTED_FIELD_VALUE';

export const CREATE_VENDOR_ONBOARDING_FORM_REQUEST =
  'CREATE_VENDOR_ONBOARDING_FORM_REQUEST';
export const CREATE_VENDOR_ONBOARDING_FORM_SUCCESS =
  'CREATE_VENDOR_ONBOARDING_FORM_SUCCESS';
export const CREATE_VENDOR_ONBOARDING_FORM_FAILURE =
  'CREATE_VENDOR_ONBOARDING_FORM_FAILURE';
export const RESET_CREATE_VENDOR_ONBOARDING_FORM_REQUEST =
  'RESET_CREATE_VENDOR_ONBOARDING_FORM_REQUEST';

export const HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_REQUEST =
  'HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_REQUEST';
export const HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_SUCCESS =
  'HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_SUCCESS';
export const HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_FAILURE =
  'HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_FAILURE';

export const HANDLE_DOCUMENT_UPLOAD_REQUEST = 'HANDLE_DOCUMENT_UPLOAD_REQUEST';
export const HANDLE_DOCUMENT_UPLOAD_SUCCESS = 'HANDLE_DOCUMENT_UPLOAD_SUCCESS';
export const HANDLE_DOCUMENT_UPLOAD_FAILURE = 'HANDLE_DOCUMENT_UPLOAD_FAILURE';

export const ACCESS_TOKEN_FOR_GUEST_USER_REQUEST =
  'ACCESS_TOKEN_FOR_GUEST_USER_REQUEST';
export const ACCESS_TOKEN_FOR_GUEST_USER_SUCCESS =
  'ACCESS_TOKEN_FOR_GUEST_USER_SUCCESS';
export const ACCESS_TOKEN_FOR_GUEST_USER_FAILURE =
  'ACCESS_TOKEN_FOR_GUEST_USER_FAILURE';
export const RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST =
  'RESET_ACCESS_TOKEN_FOR_GUEST_USER_REQUEST';

export const GET_OTP_FOR_GUEST_USER_REQUEST = 'GET_OTP_FOR_GUEST_USER_REQUEST';
export const GET_OTP_FOR_GUEST_USER_SUCCESS = 'GET_OTP_FOR_GUEST_USER_SUCCESS';
export const GET_OTP_FOR_GUEST_USER_FAILURE = 'GET_OTP_FOR_GUEST_USER_FAILURE';
export const RESET_GET_OTP_FOR_GUEST_USER_REQUEST =
  'RESET_GET_OTP_FOR_GUEST_USER_REQUEST';

export const VALIDATE_GUEST_USER_OTP_REQUEST =
  'VALIDATE_GUEST_USER_OTP_REQUEST';
export const VALIDATE_GUEST_USER_OTP_SUCCESS =
  'VALIDATE_GUEST_USER_OTP_SUCCESS';
export const VALIDATE_GUEST_USER_OTP_FAILURE =
  'VALIDATE_GUEST_USER_OTP_FAILURE';
export const RESET_VALIDATE_GUEST_USER_OTP_REQUEST =
  'RESET_VALIDATE_GUEST_USER_OTP_REQUEST';

export const RESEND_GUEST_USER_OTP_REQUEST = 'RESEND_GUEST_USER_OTP_REQUEST';
export const RESEND_GUEST_USER_OTP_SUCCESS = 'RESEND_GUEST_USER_OTP_SUCCESS';
export const RESEND_GUEST_USER_OTP_FAILURE = 'RESEND_GUEST_USER_OTP_FAILURE';

export const GET_SWR_ID_CARD_REQUEST = 'GET_SWR_ID_CARD_REQUEST';
export const GET_SWR_ID_CARD_SUCCESS = 'GET_SWR_ID_CARD_SUCCESS';
export const GET_SWR_ID_CARD_FAILURE = 'GET_SWR_ID_CARD_FAILURE';

export const GET_ACTIVITY_LOG_REQUEST = 'GET_ACTIVITY_LOG_REQUEST';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const GET_ACTIVITY_LOG_FAILURE = 'GET_ACTIVITY_LOG_FAILURE';

export const GET_TEAM_DESIGNATIONS_REQUEST = 'GET_TEAM_DESIGNATIONS_REQUEST';
export const GET_TEAM_DESIGNATIONS_SUCCESS = 'GET_TEAM_DESIGNATIONS_SUCCESS';
export const GET_TEAM_DESIGNATIONS_FAILURE = 'GET_TEAM_DESIGNATIONS_FAILURE';
export const RESET_GET_TEAM_DESIGNATIONS_REQUEST =
  'RESET_GET_TEAM_DESIGNATIONS_REQUEST';

export const CREATE_NAVEEN_FORM_DATA_REQUEST =
  'CREATE_NAVEEN_FORM_DATA_REQUEST';
export const CREATE_NAVEEN_FORM_DATA_SUCCESS =
  'CREATE_NAVEEN_FORM_DATA_SUCCESS';
export const CREATE_NAVEEN_FORM_DATA_FAILURE =
  'CREATE_NAVEEN_FORM_DATA_FAILURE';
export const RESET_NAVEEN_CREATE_FORM_DATA_REQUEST =
  'RESET_NAVEEN_CREATE_FORM_DATA_REQUEST';

export const SET_GUEST_FORMDATA = 'SET_GUEST_FORMDATA';

export const UPDATE_NAVEEN_FORM_DATA_REQUEST =
  'UPDATE_NAVEEN_FORM_DATA_REQUEST';
export const UPDATE_NAVEEN_FORM_DATA_SUCCESS =
  'UPDATE_NAVEEN_FORM_DATA_SUCCESS';
export const UPDATE_NAVEEN_FORM_DATA_FAILURE =
  'UPDATE_NAVEEN_FORM_DATA_FAILURE';
export const RESET_NAVEEN_UPDATE_FORM_DATA_REQUEST =
  'RESET_NAVEEN_UPDATE_FORM_DATA_REQUEST';

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST';
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS';
export const ORDER_PAY_FAILURE = 'ORDER_PAY_FAILURE';
export const RESET_ORDER_PAY_REQUEST = 'RESET_ORDER_PAY_REQUEST';

export const PAYMENT_CONFIRM_REQUEST = 'PAYMENT_CONFIRM_REQUEST';
export const PAYMENT_CONFIRM_SUCCESS = 'PAYMENT_CONFIRM_SUCCESS';
export const PAYMENT_CONFIRM_FAILURE = 'PAYMENT_CONFIRM_FAILURE';
export const RESET_PAYMENT_CONFIRM_REQUEST = 'RESET_PAYMENT_CONFIRM_REQUEST';

export const SET_RAZORPAY_RESPONSE = 'SET_RAZORPAY_RESPONSE';

export const RESET_CUSTOM_DATA = 'RESET_CUSTOM_DATA';

export const IRCTC_ORDER_PAY_REQUEST = 'IRCTC_ORDER_PAY_REQUEST';
export const IRCTC_ORDER_PAY_SUCCESS = 'IRCTC_ORDER_PAY_SUCCESS';
export const IRCTC_ORDER_PAY_FAILURE = 'IRCTC_ORDER_PAY_FAILURE';
export const RESET_IRCTC_ORDER_PAY_REQUEST = 'RESET_IRCTC_ORDER_PAY_REQUEST';

export const IRCTC_PAYMENT_CONFIRM_REQUEST = 'IRCTC_PAYMENT_CONFIRM_REQUEST';
export const IRCTC_PAYMENT_CONFIRM_SUCCESS = 'IRCTC_PAYMENT_CONFIRM_SUCCESS';
export const IRCTC_PAYMENT_CONFIRM_FAILURE = 'IRCTC_PAYMENT_CONFIRM_FAILURE';
export const RESET_IRCTC_PAYMENT_CONFIRM_REQUEST =
  'RESET_IRCTC_PAYMENT_CONFIRM_REQUEST';

export const GET_IRCTC_CARD_LIST_REQUEST = 'GET_IRCTC_CARD_LIST_REQUEST';
export const GET_IRCTC_CARD_LIST_SUCCESS = 'GET_IRCTC_CARD_LIST_SUCCESS';
export const GET_IRCTC_CARD_LIST_FAILURE = 'GET_IRCTC_CARD_LIST_FAILURE';
export const RESET_GET_IRCTC_CARD_LIST_REQUEST =
  'RESET_GET_IRCTC_CARD_LIST_REQUEST';

export const GET_OTP_FOR_FORM_REQUEST = 'GET_OTP_FOR_FORM_REQUEST';
export const GET_OTP_FOR_FORM_SUCCESS = 'GET_OTP_FOR_FORM_SUCCESS';
export const GET_OTP_FOR_FORM_FAILURE = 'GET_OTP_FOR_FORM_FAILURE';

export const GET_NE_PRODUCT_FILTER_OPTIONS_REQUEST =
  'GET_NE_PRODUCT_FILTER_OPTIONS_REQUEST';
export const GET_NE_PRODUCT_FILTER_OPTIONS_SUCCESS =
  'GET_NE_PRODUCT_FILTER_OPTIONS_SUCCESS';
export const GET_NE_PRODUCT_FILTER_OPTIONS_FAILURE =
  'GET_NE_PRODUCT_FILTER_OPTIONS_FAILURE';
export const RESET_NE_PRODUCT_FILTER_OPTIONS_REQUEST =
  'RESET_NE_PRODUCT_FILTER_OPTIONS_REQUEST';
