/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';

import '../../../styles/form/guestUserForm.css';

const Success = () => {
  return (
    <div id="guest-user-success-root">
      <div className="guest-user-success-logo-wrapper">
        <img
          src={require('./../../../../../assets/images/userOnBoarding/logo-big.png')}
          alt="logo"
          className="logo"
        />
      </div>
      <div className="guest-user-success-content-wrapper">
        <p>{`Successfully uploaded data.`}</p>
      </div>
    </div>
  );
};

export default Success;
