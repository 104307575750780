import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { getMyTeams } from './../actions/drawer';
import TeamList from './teamList';
import { initializeState } from './../actions/drawer';
import { accessTokenForTeam } from '../../workflow/actions/home';
import * as types from './../../../actions/types';
import './../styles/drawer.css';
import withRouter from 'withRouter';

interface IState {
  anchorEl: any;
  allTeams: any[];
  teamId: string;
  openTeam: boolean;
}

interface IProps {
  open: boolean;
  getaccessTokenForTeam: boolean;
  getTeams: boolean;
  myTeams: any[];
  getMyTeams: (a: string | null) => void;
  accessTokenForTeam: (a: string) => void;
  dispatch: Dispatch;
  onChangeTeam: () => void;
  initializeState: () => void;
  router: any;
}

class Header extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      anchorEl: null,
      allTeams: [],
      teamId: '',
      openTeam: false,
    };
  }

  componentDidMount() {
    this.props.getMyTeams(sessionStorage.getItem('userId'));
    this.props.dispatch({
      type: types.RESET_ALL_REPORTS,
    });
  }

  componentDidUpdate() {
    if (this.props.getaccessTokenForTeam) {
      this.props.dispatch({
        type: types.RESET_GET_ACCESS_TOKEN_TEAM_REQUEST,
      });
      this.props.dispatch({
        type: types.SET_TEAM_ID,
        payload: { teamId: this.state.teamId },
      });
    }
    if (this.props.getTeams) {
      this.props.dispatch({
        type: types.RESET_GET_MY_TEAMS_REQUEST,
      });
      this.getTeams(this.props.myTeams);
    }
  }

  getTeams = (teams: any) => {
    const allTeams: any = [];
    teams.length > 0 &&
      teams.map((item: any) => {
        allTeams.push({
          teamId: item.team_id,
          teamName: item.display_name,
        });
        return null;
      });

    const teamId = sessionStorage.getItem('teamId')
      ? sessionStorage.getItem('teamId')
      : allTeams.length > 0
        ? allTeams[0].teamId
        : '';
    this.setState({ allTeams, teamId });
  };

  handleChangeTeam = (team: any) => {
    this.setState({ teamId: team.teamId });
    this.props.accessTokenForTeam(team.teamId);
    sessionStorage.setItem('teamId', team.teamId);
    this.props.dispatch({
      type: types.RESET_ALL_REPORTS,
    });
    if (!this.props.router.location.pathname.includes('/home')) {
      this.props.router.navigate(`/member/home`);
    }
    this.closeTeamList();
  };

  getTeamName = () => {
    if (this.state.allTeams.length > 0) {
      const teams = this.state.allTeams.filter(
        (item: any) => item.teamId === this.state.teamId
      );
      if (teams.length > 0) return teams[0].teamName;
      else return '';
    }
    return '';
  };

  closeTeamList = () => {
    this.props.onChangeTeam();
    this.setState({ openTeam: false });
  };

  logout = () => {
    sessionStorage.clear();
    this.props.initializeState();
    this.props.router.navigate('/login');
  };

  render() {
    return this.state.openTeam ? (
      <TeamList
        handleChangeTeam={this.handleChangeTeam}
        onClose={this.closeTeamList}
        allTeams={this.state.allTeams}
        logout={this.logout}
      />
    ) : (
      <div
        className={`team-user-details-wrapper ${
          this.props.open ? 'padding-left-20' : ''
        }`}
      >
        <div
          className={`team-image-root ${!this.props.open ? 'width-100' : ''}`}
        >
          <div className="team-image-wrapper">
            {this.getTeamName().slice(0, 1)}
          </div>
        </div>
        {this.props.open ? (
          <div className="team-user-details">
            <h6
              className={this.props.open ? 'drawer-open-h6' : 'drawer-close-h6'}
            >
              {this.getTeamName()}
            </h6>
            <p className={this.props.open ? 'drawer-open-p' : 'drawer-close-p'}>
              {sessionStorage.getItem('userName')}
            </p>
          </div>
        ) : (
          ''
        )}
        {this.props.open ? (
          <i
            className="fal fa-exchange-alt"
            onClick={() => {
              this.props.onChangeTeam();
              this.setState({ openTeam: true });
            }}
          ></i>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    getaccessTokenForTeam: state.homeReducer.getaccessTokenForTeam,
    myTeams: state.drawerReducer.myTeams,
    getTeams: state.drawerReducer.getTeams,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      initializeState,
      accessTokenForTeam,
      getMyTeams,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Header));
