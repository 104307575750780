import { put, takeEvery, call, all } from 'redux-saga/effects';
import * as _ from 'lodash';
import moment from 'moment';
import base64js from 'base64-js';
import { ref, uploadBytesResumable } from 'firebase/storage';

import * as types from '../../../actions/types';
import * as teamTypes from '../../../actions/teamTypes';
import * as ReportAPI from '../api/api.report';
// import * as UserOnboardingAPI from './../../userOnBoarding/api/api.userOnboarding';
import { callAPI, callFirestoreAPI } from '../../../api/api';
import { firebaseStorage } from './../../../fireStoreConfig/fireStore';
import {
  isImageExists,
  isNull,
  removeBase64Data,
} from './../../../utils/helper';
import { setCustomToken } from './../../../fireStoreConfig/fireStore';

export function* getAllMyReports(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getAllMyReports,
    action.payload,
    types.GET_ALL_MY_REPORTS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_ALL_MY_REPORTS_SUCCESS,
      payload: response.data,
    });
    if (action.payload.resolve) {
      action.payload.resolve();
    }
  }
}

export function* getInboxData(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getInboxData,
    action.payload,
    types.GET_INBOX_DATA_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_INBOX_DATA_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getReportDetails(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getReportDetails,
    action.payload,
    types.GET_REPORT_DETAILS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_REPORT_DETAILS_SUCCESS,
      payload: response.data,
    });
    if (action.payload.resolve) {
      action.payload.resolve();
    }
  }
}

export function* getFirebaseImage(imgObj: any, isFromForm = false) {
  if (imgObj && imgObj.file_path && imgObj.mime_type) {
    const payload = {
      action: 'read',
      filepath: imgObj.file_path,
      contentType: imgObj.mime_type,
    };

    const signedUrl: { data: any } = yield callAPI(
      ReportAPI.getFirebaseImage,
      payload,
      types.GET_FIREBASE_IMAGE_FAILURE
    );

    if (signedUrl && signedUrl.data && signedUrl.data.url) {
      const imageUrl: { data: any } = yield call(ReportAPI.getBlobUrl, {
        url: signedUrl.data.url,
        contentType: imgObj.mime_type,
      });

      let data = null;
      if (imageUrl) {
        data = imageUrl.data;
      }

      return isFromForm ? { ...imgObj, base64Data: data } : data;
    }
  }

  return null;
}

export function* getFormDataDetails(action: any): any {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getFormDataDetails,
    action.payload,
    types.GET_FORM_DATA_DETAILS_FAILURE
  );
  if (response) {
    /**
     * if image field exists in formdata, then we are fetching image from firebase
     */
    const formData = { ...response.data };
    const data = formData && formData.data ? formData.data : {};
    for (const key in data) {
      const exists = isImageExists(action.payload.fields, key);
      if (exists && data[key] && data[key].length > 0) {
        const imageArray = yield all(
          data[key].map((item: any) => {
            return call(getFirebaseImage, item, action.payload.isFromForm);
          })
        );
        data[key] = imageArray;
      }
    }
    formData['data'] = data;
    yield put({
      type: types.GET_FORM_DATA_DETAILS_SUCCESS,
      payload: formData,
    });
  }
}

export function* createFormData(action: any) {
  const data = {
    meta: {
      template_id: action.payload.templateId,
      view_name: '',
    },
    payload: { ...action.payload.formData },
    isFromGuestUser: action.payload.isFromGuestUser,
  };
  if (!action.payload.isImagePicked) {
    const response: { status: any; data: any; timeout: any } = yield callAPI(
      ReportAPI.createFormData,
      data,
      types.CREATE_FORM_DATA_FAILURE
    );
    if (response) {
      if (response['timeout']) {
        yield put({
          type: types.CREATE_FORM_DATA_SUCCESS,
          payload: {
            data: response.data,
            message: 'Request timed out',
            timedOut: true,
          },
        });
      } else {
        yield put({
          type: types.CREATE_FORM_DATA_SUCCESS,
          payload: {
            data: response.data,
            message: 'Created successfully',
            timedOut: false,
          },
        });
      }
    }
  } else {
    data.payload = removeBase64Data(data.payload);
    const response: { status: any; data: any } = yield callFirestoreAPI(
      ReportAPI.createFormDataFirebase,
      data,
      types.CREATE_FORM_DATA_FAILURE
    );
    if (response) {
      yield put({
        type: types.CREATE_FORM_DATA_SUCCESS,
        payload: {
          data: response.data,
          message: 'Created successfully',
          timedOut: false,
        },
      });
    }
  }
}

export function* updateFormData(action: any) {
  const data = {
    meta: {
      template_id: action.payload.templateId,
      view_name: action.payload.viewName,
    },
    payload: { ...action.payload.formData },
  };
  if (!action.payload.isImagePicked) {
    const response: { status: any; data: any; timeout: any } = yield callAPI(
      ReportAPI.updateFormData,
      data,
      types.UPDATE_FORM_DATA_FAILURE
    );
    if (response) {
      if (response['timeout']) {
        yield put({
          type: types.UPDATE_FORM_DATA_SUCCESS,
          payload: {
            data: response.data,
            message: 'Request timed out',
            timedOut: true,
          },
        });
      } else {
        yield put({
          type: types.UPDATE_FORM_DATA_SUCCESS,
          payload: {
            data: response.data,
            message: 'Update successfully',
            timedOut: false,
          },
        });
      }
    }
  } else {
    data.payload = removeBase64Data(data.payload);
    const response: { status: any; data: any } = yield callFirestoreAPI(
      ReportAPI.createFormDataFirebase,
      data,
      types.UPDATE_FORM_DATA_FAILURE
    );
    if (response) {
      yield put({
        type: types.UPDATE_FORM_DATA_SUCCESS,
        payload: {
          data: response.data,
          message: 'Update successfully',
          timedOut: false,
        },
      });
    }
  }
}

export function* getLookupInstanceIds(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getLookupInstanceIds,
    action.payload,
    types.GET_LOOKUP_INSTANCEIDS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_LOOKUP_INSTANCEIDS_SUCCESS,
      payload: response.data,
    });
    if (action.payload.resolve) {
      action.payload.resolve();
    }
  }
}

export function* getExternalLookupData(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getExternalLookupData,
    action.payload,
    types.GET_EXTERNAL_LOOKUP_DATA_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_EXTERNAL_LOOKUP_DATA_SUCCESS,
      payload: response.data,
    });
    if (action.payload.resolve) {
      action.payload.resolve();
    }
  }
}

export function* getCatalogData(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getCatalogData,
    action.payload,
    types.GET_CATALOG_DATA_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_CATALOG_DATA_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getInboxInstanceIds(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getInboxInstanceIds,
    action.payload,
    types.GET_INBOX_INSTANCEIDS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_INBOX_INSTANCEIDS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getUnassignedData(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getUnassignedData,
    action.payload,
    types.GET_UNASSIGNED_DATA_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_UNASSIGNED_DATA_SUCCESS,
      payload: response.data,
    });
  }
}

export function* updateAssignee(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.updateAssignee,
    action.payload,
    types.UPDATE_ASSIGNEE_FAILURE
  );
  if (response) {
    yield put({
      type: types.UPDATE_ASSIGNEE_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getAllInboxConfigs(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getAllInboxConfigs,
    action.payload,
    types.GET_ALL_INBOX_CONFIGS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_ALL_INBOX_CONFIGS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getInboxViewsData(action: any) {
  const {
    templateId,
    tabDetails,
    filterQuery,
    pageNumber,
    searchText,
    pageSize,
  } = action.payload;
  const viewName = tabDetails && tabDetails['name'] ? tabDetails['name'] : '';
  const fq = tabDetails && tabDetails['fq'] ? tabDetails['fq'] : '';

  let queryString = '';
  if (!isNull(filterQuery)) {
    queryString = `${fq}&${filterQuery}`;
  } else {
    queryString = `${fq}`;
  }

  const response: {
    status: any;
    data: any;
    total_count: number;
  } = yield callAPI(
    ReportAPI.getInboxViewsData,
    {
      templateId,
      viewName,
      queryString,
      pageNumber,
      pageSize,
      search: searchText,
    },
    types.GET_INBOX_VIEWS_DATA_FAILURE
  );
  if (response) {
    yield put({
      type: types.SET_INBOX_VIEWS_TOTAL_COUNT,
      payload: { totalCount: response.total_count },
    });
    yield put({
      type: types.GET_INBOX_VIEWS_DATA_SUCCESS,
      payload: response.data,
    });
  }
}

export function* createVendorOnboardingForm(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.createVendorOnboardingForm,
    action.payload.formData,
    types.CREATE_VENDOR_ONBOARDING_FORM_FAILURE
  );
  if (response) {
    yield put({
      type: types.CREATE_VENDOR_ONBOARDING_FORM_SUCCESS,
      payload: {
        data: response.data,
        message: 'Created successfully',
        timedOut: false,
      },
    });
  }
}

export function* saveAttachment(action: any) {
  const {
    contentType,
    fileName,
    templateId,
    base64Data,
    instanceId,
    internalName,
    teamId,
  } = action.payload;

  const attachmentParams = {
    file_path: '',
    mime_type: contentType,
    upload_status: 'FAILED',
    firestore_id: '',
    base64Data: base64Data,
  };

  try {
    if (!isNull(base64Data) && !isNull(fileName)) {
      const file_path = `/${teamId}/workflow/templateid/${templateId}/${fileName}`;

      attachmentParams['file_path'] = file_path;
      attachmentParams['upload_status'] = 'PENDING';

      const response: { status: any; data: any } = yield callAPI(
        ReportAPI.saveAttachment,
        {
          meta: {
            template_id: templateId,
          },
          payload: {
            _mo_instance_id: instanceId,
            byte_array: base64js.toByteArray(
              base64Data.includes('data:image/png;base64,')
                ? base64Data.replaceAll('data:image/png;base64,', '')
                : base64Data.replaceAll('data:image/jpeg;base64,', '')
            ),
            file_path: file_path,
            content_type: contentType,
            field_internal_name: internalName,
            _mo_recorded_at: moment().utc().valueOf(),
          },
        },
        types.HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_FAILURE
      );

      if (
        isNull(response) ||
        !response.status ||
        !response.data ||
        !response.data['firestore_id']
      ) {
        attachmentParams['upload_status'] = 'FAILED';
      } else {
        attachmentParams['upload_status'] = 'SUCCESS';
        attachmentParams['firestore_id'] = response.data['firestore_id'];
      }
    }
    return attachmentParams;
  } catch (e) {
    return attachmentParams;
  }
}

export function* handleImagesUploadAndDownload(action: any) {
  const {
    templateId,
    teamId,
    instanceId,
    fieldInternalName,
    doc,
    actionType,
    resolve,
    reject,
  } = action.payload;
  try {
    let data = [];
    if (Array.isArray(doc) && doc.length && actionType.match(/write/i)) {
      data = yield all(
        _.map(doc, (item: any) =>
          call(saveAttachment, {
            payload: {
              templateId,
              teamId,
              instanceId,
              internalName: fieldInternalName,
              actionType,
              fileName: item['file_name'],
              contentType: item['mime_type'],
              base64Data: item['url'] || '',
              firestoreId: item['firestore_id'] || '',
              uploadStatus: item['upload_status'] || '',
            },
          })
        )
      );

      const failed = _.some(
        data,
        (item: any) =>
          (!isNull(item) &&
            typeof item['upload_status'] !== 'undefined' &&
            item['upload_status'] === 'FAILED') ||
          item['upload_status'] === 'PENDING'
      );

      if (failed) {
        reject();
      }
      resolve(data);
    } else if (Array.isArray(doc) && doc.length && actionType.match(/read/i)) {
      data = yield all(
        _.map(doc, (item: any) => call(getFirebaseImage, item, true))
      );
      data = _.filter(
        data,
        (item: any) => item && typeof item !== 'undefined' && item !== null
      );
      resolve(data);
    }
  } catch (e) {
    reject();
  }
}

export function docUploadAPI(putFileRef: any, item: any, filePath: any) {
  return putFileRef
    .then((res: any) => {
      if (res && res['state'] && res['state'] === 'success') {
        return {
          file_path: filePath,
          name: item.name,
          content_type: item.type,
          status: res['state'],
        };
      } else {
        return {
          file_path: filePath,
          name: item.name,
          content_type: item.type,
          status: 'error',
        };
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
}

export function* uploadDocument(action: any): any {
  try {
    const { teamId, templateId, instanceId, item } = action.payload;
    const filePath = `/${teamId}/workflow/templateid/${templateId}/instanceid/${instanceId}/${item.name}`;

    const storageRef = ref(firebaseStorage, filePath);

    const fileRef = uploadBytesResumable(storageRef, item);

    const uploadRes = yield call(docUploadAPI, fileRef, item, filePath);

    return uploadRes;
  } catch (e) {
    return null;
  }
}

export function* handleDocumentUpload(action: any) {
  const { teamId, templateId, instanceId, doc, resolve, reject } =
    action.payload;

  try {
    let docRes = [];
    if (Array.isArray(doc) && doc.length) {
      docRes = yield all(
        _.map(doc, (item: any) =>
          call(uploadDocument, {
            payload: {
              teamId,
              templateId,
              instanceId,
              item,
            },
          })
        )
      );

      const failed = _.some(
        docRes,
        (item: any) =>
          !isNull(item) &&
          typeof item['status'] !== 'undefined' &&
          item['status'] === 'error'
      );

      if (failed) {
        reject();
      } else {
        const updatedDocRes = _.compact(docRes);
        resolve(updatedDocRes);
      }
    }
  } catch (e) {
    reject();
  }
}

export function* accessTokenForGuestUser(action: any) {
  const getaccessToken: { status: any; data: any } = yield call(
    ReportAPI.getAccessTokenForGuestUser,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );

  if (getaccessToken.data['access_token']) {
    setCustomToken(getaccessToken.data['access_token']);
    sessionStorage.setItem('accessToken', getaccessToken.data['access_token']);
    if (action.payload.fromGuestUser) {
      yield put({
        type: teamTypes.GET_TEAM_DETAILS_REQUEST,
        payload: {
          teamId: getaccessToken.data.team_id,
        },
      });
    }
    yield put({
      type: types.ACCESS_TOKEN_FOR_GUEST_USER_SUCCESS,
      payload: getaccessToken.data,
    });
  } else {
    yield put({
      type: types.ACCESS_TOKEN_FOR_GUEST_USER_FAILURE,
      payload: {},
    });
  }
}

export function* getOTPForGuestUser(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getOTPForGuestUser,
    action.payload,
    types.GET_OTP_FOR_GUEST_USER_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_OTP_FOR_GUEST_USER_SUCCESS,
      payload: response.data,
    });
  }
}

export function* validateGuestUserOTP(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForGuestUser,
    {
      team_id: action.payload.teamId,
      request_id: action.payload.requestId,
      otp_code: action.payload.otp,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );
  if (accessToken) {
    setCustomToken(accessToken.data.access_token);
    sessionStorage.setItem('accessToken', accessToken.data.access_token);
    yield put({
      type: types.VALIDATE_GUEST_USER_OTP_SUCCESS,
      payload: accessToken.data,
    });
  } else {
    yield put({
      type: types.VALIDATE_GUEST_USER_OTP_FAILURE,
      payload: '',
    });
  }
}

export function* resendGuestUserOTP(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.resendGuestUserOTP,
    action.payload,
    types.RESEND_GUEST_USER_OTP_FAILURE
  );
  if (response) {
    yield put({
      type: types.RESEND_GUEST_USER_OTP_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getActivityLog(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getActivityLog,
    action.payload,
    types.GET_ACTIVITY_LOG_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_ACTIVITY_LOG_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getTeamDesignations(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getTeamDesignations,
    action.payload,
    types.GET_TEAM_DESIGNATIONS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_TEAM_DESIGNATIONS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* createNaveenFormData(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForSubmitForm,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );
  if (accessToken && accessToken.data && accessToken.data.access_token) {
    const data = {
      meta: {
        template_id: action.payload.templateId,
        view_name: '',
      },
      payload: { ...action.payload.formData },
    };
    const response: { status: any; data: any; timeout: any } = yield call(
      ReportAPI.createNaveenFormData,
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        dataType: 'json',
        Authorization: 'bearer ' + accessToken.data.access_token,
      }
    );
    if (response.status) {
      yield put({
        type: types.CREATE_NAVEEN_FORM_DATA_SUCCESS,
        payload: {
          data: response.data,
          message: 'Created successfully',
          timedOut: false,
        },
      });
    } else {
      yield put({ type: types.CREATE_NAVEEN_FORM_DATA_FAILURE, payload: {} });
    }
  }
}

export function* updateNaveenFormData(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForSubmitForm,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );

  if (accessToken && accessToken.data && accessToken.data.access_token) {
    const data = {
      meta: {
        template_id: action.payload.templateId,
        view_name: '_mo_order_details',
      },
      payload: { ...action.payload.formData },
    };
    const response: { status: any; data: any; timeout: any } = yield call(
      ReportAPI.updateNaveenFormData,
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        dataType: 'json',
        Authorization: 'bearer ' + accessToken.data.access_token,
      }
    );
    if (response.status) {
      yield put({
        type: types.UPDATE_NAVEEN_FORM_DATA_SUCCESS,
        payload: {
          data: response.data,
          message: 'Update successfully',
          timedOut: false,
        },
      });
    } else {
      yield put({ type: types.UPDATE_NAVEEN_FORM_DATA_FAILURE, payload: {} });
    }
  }
}

export function* orderPay(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForSubmitForm,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );

  if (accessToken && accessToken.data && accessToken.data.access_token) {
    const data = {
      instanceId: action.payload.instanceId,
    };

    const response: { status: any; data: any; timeout: any } = yield call(
      ReportAPI.orderPay,
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        dataType: 'json',
        Authorization: 'bearer ' + accessToken.data.access_token,
      }
    );
    if (response.status) {
      yield put({
        type: types.ORDER_PAY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: types.ORDER_PAY_FAILURE, payload: {} });
    }
  }
}

export function* paymentConfirm(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForSubmitForm,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );

  if (accessToken && accessToken.data && accessToken.data.access_token) {
    const data = {
      instanceId: action.payload.instanceId,
      orderId: action.payload.orderId,
      transactionId: action.payload.transactionId,
      razorpaySignature: action.payload.razorpaySignature,
    };

    const response: { status: any; data: any; timeout: any } = yield call(
      ReportAPI.paymentConfirm,
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        dataType: 'json',
        Authorization: 'bearer ' + accessToken.data.access_token,
      }
    );
    if (response.status) {
      yield put({
        type: types.PAYMENT_CONFIRM_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: types.PAYMENT_CONFIRM_FAILURE, payload: {} });
    }
  }
}

export function* getTeamDetails(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getTeamDetails,
    action.payload,
    teamTypes.GET_TEAM_DETAILS_FAILURE
  );
  if (response) {
    const data = response.data;
    if (data && data.logo1 && data.logo1.url && data.logo1.mime_type) {
      const payload = {
        filepath: data.logo1.url,
        contentType: data.logo1.mime_type,
      };
      const signedUrl: { status: any; data: any } = yield callAPI(
        ReportAPI.getFirebaseTeamImage,
        payload,
        teamTypes.GET_TEAM_DETAILS_FAILURE
      );
      if (signedUrl) {
        const blobPayload = {
          url: signedUrl.data.url,
          contentType: data.logo1.mime_type,
        };
        const blobUrl: { status: any; data: any } = yield call(
          ReportAPI.getBlobUrl,
          blobPayload
        );
        if (blobUrl) {
          data['logo1'] = {
            ...data['logo1'],
            blobUrl: blobUrl.data,
          };
        }
      }
      yield put({
        type: teamTypes.GET_TEAM_DETAILS_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: teamTypes.GET_TEAM_DETAILS_SUCCESS,
        payload: data,
      });
    }
  }
}

export function* irctcOrderPay(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForSubmitForm,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );

  if (accessToken && accessToken.data && accessToken.data.access_token) {
    const data = {
      instanceIds: action.payload.instanceIds,
    };

    const response: { status: any; data: any; timeout: any } = yield call(
      ReportAPI.irctcOrderPay,
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        dataType: 'json',
        Authorization: 'bearer ' + accessToken.data.access_token,
      }
    );
    if (response.status) {
      yield put({
        type: types.IRCTC_ORDER_PAY_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: types.IRCTC_ORDER_PAY_FAILURE, payload: {} });
    }
  }
}

export function* irctcPaymentConfirm(action: any) {
  const accessToken: { status: any; data: any } = yield call(
    ReportAPI.getAuthorizedAccessTokenForSubmitForm,
    {
      public_id: action.payload.publicId,
    },
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      dataType: 'json',
    }
  );

  if (accessToken && accessToken.data && accessToken.data.access_token) {
    const data = {
      instanceIds: action.payload.instanceIds,
      orderId: action.payload.orderId,
      transactionId: action.payload.transactionId,
      razorpaySignature: action.payload.razorpaySignature,
    };

    const response: { status: any; data: any; timeout: any } = yield call(
      ReportAPI.irctcPaymentConfirm,
      data,
      {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        dataType: 'json',
        Authorization: 'bearer ' + accessToken.data.access_token,
      }
    );
    if (response.status) {
      yield put({
        type: types.IRCTC_PAYMENT_CONFIRM_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({ type: types.IRCTC_PAYMENT_CONFIRM_FAILURE, payload: {} });
    }
  }
}

export function* getIrctcCardList(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getIrctcCardList,
    action.payload,
    types.GET_IRCTC_CARD_LIST_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_IRCTC_CARD_LIST_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getOTPForForm(action: any) {
  const { resolve, reject } = action.payload;
  try {
    let response: { status: any; data: any } = yield callAPI(
      ReportAPI.getOTPForForm,
      action.payload,
      types.GET_OTP_FOR_FORM_FAILURE
    );
    if (response) {
      yield put({
        type: types.GET_OTP_FOR_FORM_SUCCESS,
        payload: response.data,
      });
      resolve(response.data);
    }
  } catch (e) {
    reject(e);
    yield put({
      type: types.GET_OTP_FOR_FORM_FAILURE,
      payload: e,
    });
  }
}

export function* getNeProductFilterOptions(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    ReportAPI.getNeProductFilterOptions,
    action.payload,
    types.GET_NE_PRODUCT_FILTER_OPTIONS_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_NE_PRODUCT_FILTER_OPTIONS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* reportSaga() {
  yield takeEvery(types.GET_INBOX_DATA_REQUEST, getInboxData);
  yield takeEvery(types.GET_REPORT_DETAILS_REQUEST, getReportDetails);
  yield takeEvery(types.GET_FORM_DATA_DETAILS_REQUEST, getFormDataDetails);
  yield takeEvery(types.CREATE_FORM_DATA_REQUEST, createFormData);
  yield takeEvery(types.UPDATE_FORM_DATA_REQUEST, updateFormData);
  yield takeEvery(types.GET_ALL_MY_REPORTS_REQUEST, getAllMyReports);
  yield takeEvery(types.GET_LOOKUP_INSTANCEIDS_REQUEST, getLookupInstanceIds);
  yield takeEvery(
    types.GET_EXTERNAL_LOOKUP_DATA_REQUEST,
    getExternalLookupData
  );
  yield takeEvery(types.GET_CATALOG_DATA_REQUEST, getCatalogData);
  yield takeEvery(types.GET_INBOX_INSTANCEIDS_REQUEST, getInboxInstanceIds);
  yield takeEvery(types.GET_UNASSIGNED_DATA_REQUEST, getUnassignedData);
  yield takeEvery(types.UPDATE_ASSIGNEE_REQUEST, updateAssignee);
  yield takeEvery(types.GET_FIREBASE_IMAGE_REQUEST, getFirebaseImage);
  yield takeEvery(types.GET_ALL_INBOX_CONFIGS_REQUEST, getAllInboxConfigs);
  yield takeEvery(types.GET_INBOX_VIEWS_DATA_REQUEST, getInboxViewsData);
  yield takeEvery(
    types.CREATE_VENDOR_ONBOARDING_FORM_REQUEST,
    createVendorOnboardingForm
  );
  yield takeEvery(
    types.HANDLE_IMAGE_UPLOAD_AND_DOWNLOAD_REQUEST,
    handleImagesUploadAndDownload
  );
  yield takeEvery(types.HANDLE_DOCUMENT_UPLOAD_REQUEST, handleDocumentUpload);
  yield takeEvery(
    types.ACCESS_TOKEN_FOR_GUEST_USER_REQUEST,
    accessTokenForGuestUser
  );
  yield takeEvery(types.GET_OTP_FOR_GUEST_USER_REQUEST, getOTPForGuestUser);
  yield takeEvery(types.VALIDATE_GUEST_USER_OTP_REQUEST, validateGuestUserOTP);
  yield takeEvery(types.RESEND_GUEST_USER_OTP_REQUEST, resendGuestUserOTP);
  yield takeEvery(types.GET_ACTIVITY_LOG_REQUEST, getActivityLog);
  yield takeEvery(types.GET_TEAM_DESIGNATIONS_REQUEST, getTeamDesignations);
  yield takeEvery(types.CREATE_NAVEEN_FORM_DATA_REQUEST, createNaveenFormData);
  yield takeEvery(types.UPDATE_NAVEEN_FORM_DATA_REQUEST, updateNaveenFormData);
  yield takeEvery(types.ORDER_PAY_REQUEST, orderPay);
  yield takeEvery(types.PAYMENT_CONFIRM_REQUEST, paymentConfirm);
  yield takeEvery(teamTypes.GET_TEAM_DETAILS_REQUEST, getTeamDetails);
  yield takeEvery(types.IRCTC_ORDER_PAY_REQUEST, irctcOrderPay);
  yield takeEvery(types.IRCTC_PAYMENT_CONFIRM_REQUEST, irctcPaymentConfirm);
  yield takeEvery(types.GET_IRCTC_CARD_LIST_REQUEST, getIrctcCardList);
  yield takeEvery(types.GET_OTP_FOR_FORM_REQUEST, getOTPForForm);
  yield takeEvery(
    types.GET_NE_PRODUCT_FILTER_OPTIONS_REQUEST,
    getNeProductFilterOptions
  );
}
