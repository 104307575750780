import { call, put, takeEvery } from 'redux-saga/effects';
import * as types from '../../../actions/types';
import * as UserOnboardingAPI from '../api/api.userOnboarding';
import { callAPI } from '../../../api/api';
import { setCustomToken } from './../../../fireStoreConfig/fireStore';

function headers() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    dataType: 'json',
  };
}

export function* getOTPForUserNameAsync(action: any) {
  const navigate = action.payload.navigate;
  const response: { status: any; data: any } = yield callAPI(
    UserOnboardingAPI.getOTPForUserName,
    action.payload,
    types.GET_OTP_FOR_USERNAME_FAILURE
  );
  if (response) {
    if (response.data.message === 'new_user') {
      navigate('/auth/prospect');
    } else {
      yield put({
        type: types.GET_OTP_FOR_USERNAME_SUCCESS,
        payload: response.data,
      });
    }
  }
}

export function* reRequestOTPForUser(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    UserOnboardingAPI.reRequestOTPForUser,
    action.payload,
    types.RESEND_OTP_FAILURE
  );
  if (response) {
    yield put({ type: types.RESEND_OTP_SUCCESS, payload: response.data });
  }
}

export function* validateOTP(action: any) {
  const navigate = action.payload.navigate;
  const response: { status: any; data: any } = yield callAPI(
    UserOnboardingAPI.validateOTP,
    action.payload,
    types.VALIDATE_OTP_FAILURE
  );
  if (response) {
    sessionStorage.setItem('refreshToken', response.data.refresh_token);
    sessionStorage.setItem('userName', response.data.user_name);
    sessionStorage.setItem(
      'permissionProfile',
      response.data.permission_profile
    );
    sessionStorage.setItem('userId', response.data.user_id);
    sessionStorage.setItem('teamId', response.data.member_teams[0].team_id);

    const header = headers();
    const accessToken: { status: any; data: any } = yield call(
      UserOnboardingAPI.getAccessToken,
      {
        grant_type: 'refresh_token',
        team_id:
          response.data.member_teams && response.data.member_teams[0].team_id
            ? response.data.member_teams[0].team_id
            : '',
        refresh_token: response.data.refresh_token,
      },
      header
    );

    if (accessToken) {
      setCustomToken(accessToken.data.access_token);
      sessionStorage.setItem('accessToken', accessToken.data.access_token);
      yield put({
        type: types.VALIDATE_OTP_SUCCESS,
        payload: response.data,
      });

      navigate('/member/home');
      yield put({
        type: types.ACCESS_TOKEN_SUCCESS,
        payload: accessToken.data,
      });
    } else {
      yield put({
        type: types.ACCESS_TOKEN_FAILURE,
        payload: '',
      });
    }
  }
}

export function* getSWRIDCard(action: any) {
  const response: { status: any; data: any } = yield callAPI(
    UserOnboardingAPI.getSWRIDCard,
    action.payload,
    types.GET_SWR_ID_CARD_FAILURE
  );
  if (response) {
    yield put({
      type: types.GET_SWR_ID_CARD_SUCCESS,
      payload: response.data,
    });
  }
}

export function* watchUserOnBoarding() {
  yield takeEvery(types.GET_OTP_FOR_USERNAME_REQUEST, getOTPForUserNameAsync);
  yield takeEvery(types.VALIDATE_OTP_REQUEST, validateOTP);
  yield takeEvery(types.RESEND_OTP_REQUEST, reRequestOTPForUser);
  yield takeEvery(types.GET_SWR_ID_CARD_REQUEST, getSWRIDCard);
}
