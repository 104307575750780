import React, { Component } from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import './../../styles/lookup/lookup.css';
import { isNull } from 'utils/helper';

const styles: any = {
  root: {
    padding: '0 30px 10px',
    margin: 0,
  },
};

class Select extends Component<any, any> {
  wrapperRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      options: [],
      newSelected: [],
    };
  }

  componentDidMount() {
    this.initValues();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps && prevProps.attributes.name !== this.props.attributes.name) {
      this.initValues();
    }
  }

  initValues = () => {
    const attributes = this.props.attributes;
    const newSelected =
      !isNull(attributes) && !isNull(attributes['value'])
        ? attributes.multiple
          ? attributes['value'] || []
          : attributes['value']
        : [];
    this.setState({
      options:
        !isNull(attributes) && !isNull(attributes['options'])
          ? attributes['options']
          : [],
      newSelected,
    });
  };

  renderHeder = () => {
    const attributes = this.props.attributes;
    return (
      <div className="lookup-modal-title-wrapper select-title-wrapper">
        <div className="title">
          <p>{attributes ? `${attributes['label']}` : ''}</p>
        </div>
      </div>
    );
  };

  handleAddPressed = () => {
    const { newSelected } = this.state;
    this.props.extProps.updateValue(this.props.attributes.name, newSelected);
    this.props.closeModal();
  };

  handleChange = (value: any) => {
    const attributes = this.props.attributes;
    let newSelected =
      attributes && attributes.multiple ? this.state.newSelected || [] : value;
    if (attributes && attributes.multiple) {
      newSelected = Array.isArray(newSelected) ? newSelected : [];
      const index = attributes.objectType
        ? newSelected.findIndex(
            (option: any) =>
              option[attributes.primaryKey] === value[attributes.primaryKey]
          )
        : newSelected.indexOf(value);
      if (index === -1) {
        newSelected.push(value);
      } else {
        newSelected.splice(index, 1);
      }
    }

    this.setState({ newSelected: newSelected });
  };

  renderContent = () => {
    const { classes, attributes } = this.props;
    const { options } = this.state;
    const value = this.state.newSelected;

    if (Array.isArray(options) && options.length) {
      return (
        <div style={{ display: 'flex', height: '83%', overflowY: 'auto' }}>
          <FormControl component="fieldset" style={{ margin: 3 }}>
            <FormGroup>
              {options.map((item: any) => {
                let isSelected = false;
                if (attributes && attributes.multiple) {
                  isSelected = attributes.objectType
                    ? value &&
                      value.findIndex(
                        (option: any) =>
                          option[attributes.primaryKey] ===
                          item[attributes.primaryKey]
                      ) !== -1
                    : value && value.indexOf(item) !== -1;
                } else {
                  isSelected = value.indexOf(item) !== -1;
                }

                return (
                  <FormControlLabel
                    key={item}
                    classes={{
                      root: classes.root,
                    }}
                    control={
                      <Checkbox
                        checked={isSelected}
                        onChange={() => this.handleChange(item)}
                        name={item}
                        color={'primary'}
                      />
                    }
                    label={item}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </div>
      );
    }
    return null;
  };

  renderFooter = () => {
    return (
      <div
        onClick={this.handleAddPressed}
        className="checklist-modal-title-wrapper select-footer-wrapper"
      >
        <div className="title">
          <p>Add</p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="lookup-modal-root select-root">
        <div id="lookup-modal-wrapper">
          {this.renderHeder()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Select);
