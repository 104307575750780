import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import * as _ from 'lodash';

import './../styles/teamList.css';

interface IProps {
  allTeams: any[];
  onClose: () => void;
  handleChangeTeam: (obj: any) => void;
  logout: () => void;
}

const TeamList = (props: IProps) => {
  const teams =
    props.allTeams.length > 0 ? _.sortBy(props.allTeams, 'teamName') : [];
  return (
    <div
      id="team-list-root"
      className={props.allTeams.length > 9 ? '' : 'min-height-100'}
    >
      <div className="close-button-wrapper">
        <i className="fal fa-times-circle" onClick={props.onClose}></i>
      </div>
      <div className="team-list-wrapper">
        {teams.length > 0 &&
          teams.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="team-wrapper"
                onClick={() => props.handleChangeTeam(item)}
              >
                <div className={`team-image-root`}>
                  <div className="team-image-wrapper">
                    {item.teamName.slice(0, 1)}
                  </div>
                </div>
                <div className="team-user-details">
                  <h6 className="drawer-open-h6">{item.teamName}</h6>
                </div>
              </div>
            );
          })}
      </div>
      <div className="logo-icon-wrapper">
        <Tooltip title="Logout">
          <i
            className="fas fa-sign-out-alt signout-icon"
            onClick={props.logout}
          />
        </Tooltip>
        <img
          src={require('./../../../assets/images/userOnBoarding/logo-big.png')}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default TeamList;
