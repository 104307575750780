import React, { Component } from 'react';
import { Modal } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactPhoneInput from 'react-phone-input-2';

import { resetErrorMessage } from '../../../../userOnBoarding/actions/actions';
import {
  getOTPForGuestUser,
  validateGuestUserOTP,
  resendGuestUserOTP,
} from '../../../actions/report';
import {
  validatePhoneForForm,
  validateEmail,
  isNull,
} from '../../../../../utils/helper';
import ProgressSpinner from './../../../../userOnBoarding/components/progressSpinner';
import * as types from '../../../../../actions/types';
import SnackbarMessage from '../../../../../common/snackbarMessage';
import '../../../styles/form/otpModal.css';
import withRouter from 'withRouter';

class OTPModal extends Component<any, any> {
  wrapperRef: any;
  intervalId: any;
  constructor(props: any) {
    super(props);
    this.state = {
      otp: '',
      seconds: 60,
      loginType: 'PHONE',
      userName: '',
      emailVerified: true,
      otpReqSuccess: false,
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);

    let initialUserName = '';
    let loginType = 'EMAIL';

    if (!isNull(this.props.email)) {
      initialUserName = this.props.email;
    } else if (!isNull(this.props.phoneNumber)) {
      initialUserName = this.formatPhoneNumberForInput(this.props.phoneNumber);
      loginType = 'PHONE';
    }

    this.setState({ userName: initialUserName, loginType: loginType });
  }

  componentDidUpdate() {
    if (this.props.guestUserOtpSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_OTP_FOR_GUEST_USER_REQUEST,
      });
      this.initTimer();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    clearInterval(this.intervalId);
  }

  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.closeModal();
    }
  };

  initTimer = () => {
    this.intervalId = setInterval(this.timer, 1000);
    this.setState({ otpReqSuccess: true });
  };

  handleClose = () => {
    this.setState({ open: false, message: '' });
    this.props.resetErrorMessage();
  };

  submitOTP = (e: any) => {
    e.preventDefault();
    if (this.state.otp.length === 4) {
      this.props.validateGuestUserOTP(
        this.props.teamId,
        this.props.guestUserRequestId,
        this.state.otp
      );
    }
  };

  resendOtp = (e: any) => {
    e.preventDefault();
    this.setState({ seconds: 60, open: true, message: 'OTP resent' });
    this.props.resendGuestUserOTP(this.props.guestUserRequestId);
  };

  timer = () => {
    this.setState({
      seconds: this.state.seconds - 1,
    });
  };

  onChange = (e: any) => {
    e.preventDefault();
    const pattern = /^[0-9\b]+$/;
    if (e.target.value === '' || pattern.test(e.target.value)) {
      this.setState({ otp: e.target.value });
    }
  };

  renderOTPInput = () => {
    return (
      <>
        <form onSubmit={this.submitOTP}>
          <div className="login-input-title">Verify OTP</div>
          <input
            type="text"
            autoFocus={true}
            className="form-control input-field"
            id="exampleInputotp"
            placeholder="&bull;&bull;&bull;&bull;"
            value={this.state.otp}
            onChange={this.onChange}
            maxLength={4}
            autoComplete="off"
            required={true}
          />
          <div className="under-line" />
          <button
            type="submit"
            className="btn btn-primary submit-button"
            disabled={this.state.otp.length !== 4 || this.props.loginRequest}
          >
            {this.props.loginRequest ? (
              <ProgressSpinner />
            ) : (
              <React.Fragment>
                {' '}
                Verify OTP
                <i className="fas fa-chevron-right paddingLeft-20" />
              </React.Fragment>
            )}
          </button>
        </form>
        {this.state.seconds > 0 ? (
          <p>
            Didn&apos;t receive the OTP ? Request for a new one in{' '}
            <span style={{ color: '#D0021B' }}>
              {this.state.seconds} second
            </span>
          </p>
        ) : (
          <p>
            Didn&apos;t receive the OTP ?{' '}
            <span
              style={{ color: '#00ACF1', cursor: 'pointer' }}
              onClick={this.resendOtp}
            >
              Resend OTP
            </span>
          </p>
        )}
      </>
    );
  };

  submitGetOTP = (e: any) => {
    e.preventDefault();
    if (this.state.loginType === 'PHONE') {
      const phoneValidate = validatePhoneForForm(this.state.userName);
      if (phoneValidate) {
        this.props.getOTPForGuestUser(this.state.userName, 'PHONE');
      } else {
        this.setState({
          message: 'Enter valid mobile number',
          open: true,
        });
      }
    } else {
      if (this.state.emailVerified) {
        this.props.getOTPForGuestUser(
          this.state.userName.toLowerCase(),
          'EMAIL'
        );
      }
    }
  };

  getDisabledCondition = () => {
    const { loginType, userName } = this.state;
    if (loginType === 'PHONE') {
      return !validatePhoneForForm(userName) || this.props.guestUserOtpRequest;
    } else {
      return !validateEmail(userName) || this.props.guestUserOtpRequest;
    }
  };

  getPhoneInput = () => {
    return (
      <ReactPhoneInput
        inputProps={{
          autoFocus: true,
          readOnly: !!this.props.phoneNumber,
        }}
        country={'in'}
        value={this.state.userName || ''}
        onChange={(e: any) => {
          const result = e.replace(/[- )(]/g, '');
          this.setState({ userName: result });
        }}
      />
    );
  };

  getEmailInput = () => {
    return (
      <>
        <input
          type="email"
          autoFocus={true}
          className="form-control input-field"
          id="user-email-id"
          placeholder="Enter your email address"
          required={true}
          autoComplete="off"
          value={this.state.userName}
          onChange={(e: any) =>
            this.setState({
              userName: e.target.value,
              emailVerified: validateEmail(e.target.value),
            })
          }
          onPaste={(e: any) =>
            this.setState({
              userName: e.target.value,
              emailVerified: validateEmail(e.target.value),
            })
          }
        />
        <div className="under-line" />
      </>
    );
  };

  formatPhoneNumberForInput = (num: any) => {
    num = num.replace('+', '');
    if (num.length === 10) {
      return `91${num}`;
    } else if (num.length === 12) {
      return num;
    } else {
      return num;
    }
  };

  renderGetOtp = () => {
    return (
      <>
        <div className="login-input-title">
          <span
            className={this.state.loginType === 'PHONE' ? 'active' : ''}
            onClick={() =>
              this.setState({
                loginType: 'PHONE',
                userName: !this.props.phoneNumber
                  ? ''
                  : this.formatPhoneNumberForInput(this.props.phoneNumber),
                emailVerified: false,
              })
            }
          >
            Mobile
          </span>

          <span>|</span>
          <span
            className={this.state.loginType === 'EMAIL' ? 'active' : ''}
            onClick={() =>
              this.setState({
                loginType: 'EMAIL',
                userName: this.props.email,
                emailVerified: false,
              })
            }
          >
            Email
          </span>
        </div>
        <form onSubmit={this.submitGetOTP}>
          {this.state.loginType === 'PHONE'
            ? this.getPhoneInput()
            : this.getEmailInput()}
          <button
            type="submit"
            className="btn btn-primary submit-button"
            disabled={this.getDisabledCondition()}
          >
            {this.props.guestUserOtpRequest ? (
              <ProgressSpinner />
            ) : (
              <React.Fragment>
                {' '}
                Get OTP
                <i className="fas fa-chevron-right paddingLeft-20" />
              </React.Fragment>
            )}
          </button>
        </form>
      </>
    );
  };

  renderContent = () => {
    if (this.state.otpReqSuccess) {
      return this.renderOTPInput();
    } else return this.renderGetOtp();
  };

  render() {
    return (
      <Modal open={this.props.openModal}>
        <div id="otp-modal-wrapper">
          <div
            className="otp-modal-container"
            ref={(node) => (this.wrapperRef = node)}
          >
            <div className="otp-modal-header">
              <p>Authenticate with OTP</p>
              <i
                className="far fa-times-circle close-button"
                onClick={this.props.closeModal}
              />
            </div>
            <div className="otp-modal-body">
              <div className="otp-modal-content">{this.renderContent()}</div>
            </div>
          </div>
          <SnackbarMessage
            handleClose={this.handleClose}
            message={this.state.message}
            open={this.state.open}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    guestUserRequestId: state.reportReducer.guestUserRequestId,
    guestUserOtpSuccess: state.reportReducer.guestUserOtpSuccess,
    guestUserOtpRequest: state.reportReducer.guestUserOtpRequest,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resendGuestUserOTP,
      getOTPForGuestUser,
      validateGuestUserOTP,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OTPModal));
