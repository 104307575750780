import React from 'react';
import './error404.css';

const ErrorScreen = () => (
  <div className="error-wrapper">
    <p>
      <strong>Something went wrong</strong>
    </p>
    <img
      src={require('../assets/images/userOnBoarding/someting_went_wrong.jpeg')}
      alt="Error"
    />
    <a
      href="/login"
      style={{ color: '#35c1b8', paddingTop: '30px', fontSize: '20px' }}
    >
      Go to Home
    </a>
  </div>
);

export default ErrorScreen;
