import { round } from 'mathjs';
import * as _ from 'lodash';

import { isNull } from './../../../../utils/helper';

const catalogPickKeys = [
  'instance_id',
  'product_code',
  'product_name',
  'price_per_unit',
  'unit_of_measure',
  'product_quantity',
  'currency',
  'brand',
  'category1',
  'category2',
  'category3',
  'status',
  'discount',
  'tax_percentage',
  'discount_percentage',
  'total_dis_amt',
  'amt_pre_tx',
  'tx_amt',
  'amt_post_tx',
  'total_price',
  '_mo_product_image',
];

/**
 * Prevent characters that are not numbers ("e", "+" & "-")
 */
export const formatInput = (e: any, allowDecimal: any) => {
  let checkIfNum;
  if (e.key !== undefined) {
    if (allowDecimal) {
      checkIfNum =
        e.key === 'E' || e.key === 'e' || e.key === '+' || e.key === '-';
    } else {
      checkIfNum =
        e.key === 'E' ||
        e.key === 'e' ||
        e.key === '+' ||
        e.key === '-' ||
        e.key === '.';
    }
  } else if (e.keyCode !== undefined) {
    if (allowDecimal) {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 101 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    } else {
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 101 ||
        e.keyCode === 187 ||
        e.keyCode === 189 ||
        e.keyCode === 190;
    }
  }
  return checkIfNum && e.preventDefault();
};

export const getMaxAllowDiscount = (
  discount: any,
  maxDiscount: any,
  price: any
) => {
  if (discount && maxDiscount && price) {
    const updatePrice: any = round(price, 2);
    const priceAbsolute: any = (round(maxDiscount, 2) / 100) * updatePrice;
    const absoluteMaxDiscount = round(priceAbsolute, 2);
    const absoluteDiscount = round(discount, 2);

    return absoluteMaxDiscount > absoluteDiscount
      ? absoluteDiscount
      : absoluteMaxDiscount;
  }
  return 0;
};

export const getMaxAllowDiscountPercentage = (
  discount: any,
  maxDiscount: any
) => {
  if (discount && maxDiscount) {
    return round(maxDiscount, 2) >= round(discount, 2)
      ? round(discount, 2)
      : round(maxDiscount, 2);
  }
  return 0;
};

export const getAbsoluteValueFromPercentage = (price: any, percentage: any) => {
  const p = !isNull(price) ? round(price, 2) : 0;
  const v = !isNull(percentage) ? round(percentage, 2) : 0;
  const value: any = (v / 100) * p;

  return round(value, 2);
};

export const isMaxDiscountAllow = (
  discount: any,
  maxDiscount: any,
  price: any
) => {
  if (price) {
    if (discount && maxDiscount) {
      const updatePrice: any = round(price, 2);
      const priceAbsolute: any = (round(maxDiscount, 2) / 100) * updatePrice;
      const absoluteMaxDiscount = round(priceAbsolute, 2);
      const absoluteDiscount = round(discount, 2);

      return absoluteDiscount <= absoluteMaxDiscount ? true : false;
    } else {
      return true;
    }
  }
  return false;
};

export const isDiscountOrTaxApplicable = (selectedItem: any) => {
  return (
    _.some(selectedItem, 'discount_percentage') ||
    _.some(selectedItem, 'tax_percentage')
  );
};

export const getSumOfQuantity = (total: any, item: any) => {
  const productQuantity = item.product_quantity;
  let quantity = 0;
  if (productQuantity) {
    quantity = parseInt(productQuantity);
  }

  return parseInt(total) + quantity;
};

export const getTotalItem = (selectedItem: any) => {
  let total: any = 0;
  if (selectedItem && selectedItem.length) {
    total = selectedItem.reduce(getSumOfQuantity, total);
  }
  return parseInt(total);
};

/**
 * Get total price amount(price_per_unit * quantity)
 */
export const totalPrice = (item: any, productQuantity: any) => {
  const { price_per_unit } = item;
  const price = !isNull(price_per_unit) ? round(price_per_unit, 2) : 0;
  const quantity = !isNull(productQuantity) ? round(productQuantity, 2) : 0;
  const res = price * quantity;

  return res ? round(res, 2) : 0;
};

/**
 * Get total discount amount(discount * quantity)
 */
export const totalDiscAmount = (item: any, productQuantity: any) => {
  const { discount } = item;
  const absDiscount = !isNull(discount) ? round(discount, 2) : 0;
  const quantity = !isNull(productQuantity) ? round(productQuantity, 2) : 0;
  const res = absDiscount * quantity;

  return res ? round(res, 2) : 0;
};

/**
 * Get total amount after tax(totalPrice - totalDiscAmount)
 */
export const amountPostTax = (totalPrice: any, totalDiscAmount: any) => {
  const tp = !isNull(totalPrice) ? round(totalPrice, 2) : 0;
  const td = !isNull(totalDiscAmount) ? round(totalDiscAmount, 2) : 0;
  const res = tp - td;

  return res ? round(res, 2) : 0;
};

/**
 * Get total tax amount before tax(amountPostTax * (1 - (1/(1 + tax_percentage)))
 */
export const taxAmount = (item: any, amountPostTax: any) => {
  const { tax_percentage } = item;
  const tax = !isNull(tax_percentage) ? round(tax_percentage, 2) : 0;
  const apt = !isNull(amountPostTax) ? round(amountPostTax, 2) : 0;
  const res = apt * (1 - 1 / (1 + tax / 100));

  return res ? round(res, 2) : 0;
};

/**
 * Get total amount before tax(amountPostTax - taxAmount)
 */
export const amountPreTax = (amountPostTax: any, taxAmount: any) => {
  const apt = !isNull(amountPostTax) ? round(amountPostTax, 2) : 0;
  const ta = !isNull(taxAmount) ? round(taxAmount, 2) : 0;
  const res = apt - ta;

  return res ? round(res, 2) : 0;
};

/**
 * Get total discount from each line item
 */
export const sumTotalDisc = (selectedItems: any) => {
  const result = _.sumBy(selectedItems, 'total_dis_amt');
  return result ? round(result, 2) : 0;
};

/**
 * Get total tax from each line item
 */
export const sumTotalTax = (selectedItems: any) => {
  const result = _.sumBy(selectedItems, 'tx_amt');
  return result ? round(result, 2) : 0;
};

/**
 * Get total amount before tax from each line item
 */
export const sumAmountPreTax = (selectedItems: any) => {
  const result = _.sumBy(selectedItems, 'amt_pre_tx');
  return result ? round(result, 2) : 0;
};

/**
 * Get total amount after tax from each line item
 */
export const sumAmountPostTax = (selectedItems: any) => {
  const result = _.sumBy(selectedItems, 'amt_post_tx');
  return result ? round(result, 2) : 0;
};

export const catalogAggregateDisplay = (selectedItem: any) => {
  let displayTotalItem = '';
  let displayTotalCost = '';
  let totalQ = 0;
  let currency = '';
  let value;

  if (selectedItem.length) {
    totalQ = getTotalItem(selectedItem);
    currency = selectedItem[0].currency;
    displayTotalItem = `${selectedItem.length} items|${totalQ} units`;
    displayTotalCost = `${currency} ${sumAmountPostTax(selectedItem)}`;
    if (isDiscountOrTaxApplicable(selectedItem)) {
      value = {
        label: `${displayTotalItem} ${displayTotalCost}`,
        values: _.map(selectedItem, (item: any) =>
          _.pick(item, catalogPickKeys)
        ),
        summary: {
          total_line_items: selectedItem.length,
          total_units: totalQ || 0,
          total_amount: sumAmountPostTax(selectedItem),
          currency: currency || 'INR',
          total_discount: sumTotalDisc(selectedItem),
          total_tax: sumTotalTax(selectedItem),
          amount_before_tax: sumAmountPreTax(selectedItem),
        },
      };
    } else {
      value = {
        label: `${displayTotalItem} ${displayTotalCost}`,
        values: _.map(selectedItem, (item: any) =>
          _.pick(item, catalogPickKeys)
        ),
        summary: {
          total_line_items: selectedItem.length,
          total_units: totalQ || 0,
          total_amount: sumAmountPostTax(selectedItem),
          currency: currency || 'INR',
          total_discount: 0,
          total_tax: 0,
          amount_before_tax: 0,
        },
      };
    }
    return value;
  }
  return {};
};
