import * as types from '../../../actions/types';

export function initializeState() {
  return {
    type: types.INITIALIZE_STATE,
  };
}

export function getMyTeams(userId: string) {
  return {
    type: types.GET_MY_TEAMS_REQUEST,
    payload: {
      userId,
    },
  };
}
