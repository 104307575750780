import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button } from '@mui/material';

// import SnackbarMessage from '../../../../../common/snackbarMessage';
import Spinner from '../../../../../common/spinner';
import * as types from '../../../../../actions/types';
import {
  resetErrorMessage,
  resetSuccessMessage,
} from '../../../../userOnBoarding/actions/actions';
import {
  orderPay,
  paymentConfirm,
  setRazorpayResponse,
} from '../../../actions/report';
import { isNull } from '../../../../../utils/helper';
import '../../../styles/form/guestSummary.css';
import withRouter from 'withRouter';

interface IState {
  fields: any[];
  openImgModal: boolean;
  imgObj: any;
  openLongtxtModal: boolean;
  longtextData: any;
  openDocumentModal: boolean;
  fieldDocuments: any;
}

interface IProps {
  reportDetails: any;
  formDataDetails: any;
  blobUrl: any;
  error: {
    displayMessage: string;
  };
  success: {
    displayMessage: string;
  };
  open: boolean;
  dispatch: Dispatch;
  resetErrorMessage: () => void;
  resetSuccessMessage: () => void;
  guestTeamId: string;
  guestTemplateId: string;
  guestFormDetails: any;
  naveenCreateFormDataResponse: any;
  orderPay: (instanceId: any, publicId: string) => void;
  orderPayRequest: boolean;
  orderPaySuccess: boolean;
  orderPayDetails: any;
  paymentConfirmRequest: boolean;
  paymentConfirmSuccess: boolean;
  paymentConfirmDetails: any;
  paymentConfirm: (
    publicId: string,
    instanceId: any,
    orderId: string,
    transactionId: string,
    razorpaySignature: string
  ) => void;
  setRazorpayResponse: (data: any) => void;
  router: any;
}

class Summary extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      fields: [],
      openImgModal: false,
      imgObj: null,
      openLongtxtModal: false,
      longtextData: null,
      openDocumentModal: false,
      fieldDocuments: null,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    if (isNull(this.props.reportDetails)) {
      this.props.router.navigate(
        `/p/naveenenterprises/${this.props.router.params.publicId}`
      );
    } else {
      const template = !isNull(this.props.reportDetails)
        ? JSON.parse(this.props.reportDetails.template)
        : null;
      const allFields: any = template ? template.fields : [];
      this.setState({ fields: allFields });
      this.scrollToTop();
      // window.onbeforeunload = event => {
      //   const e = event || window.event;
      //   e.preventDefault();
      //   if (e) {
      //     e.returnValue = '';
      //   }
      //   return '';
      // };
    }
  }

  componentDidUpdate() {
    if (this.props.orderPaySuccess) {
      this.props.dispatch({
        type: types.RESET_ORDER_PAY_REQUEST,
      });
      this.openRazorPayModal();
    }
    if (this.props.paymentConfirmSuccess) {
      this.props.dispatch({
        type: types.RESET_PAYMENT_CONFIRM_REQUEST,
      });
      this.props.router.navigate(
        `/p/naveenenterprises/${this.props.router.params.publicId}/success`
      );
    }
  }

  scrollToTop = () => {
    setTimeout(() => {
      const element: any = document.getElementById('guest-summary-root');
      element.scrollIntoView({ behavior: 'smooth' });
    }, 1);
  };

  callPaymentConfirm = (response: any) => {
    const data = {
      publicId: this.props.router.params.publicId,
      instanceId:
        !isNull(this.props.guestFormDetails) &&
        !isNull(this.props.guestFormDetails['_mo_instance_id'])
          ? this.props.guestFormDetails['_mo_instance_id']
          : null,
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
      signature: response.razorpay_signature,
    };
    this.props.setRazorpayResponse(data);
    this.props.paymentConfirm(
      data.publicId,
      data.instanceId,
      data.orderId,
      data.paymentId,
      data.signature
    );
  };

  openRazorPayModal = () => {
    const options = {
      key: process.env.REACT_APP_NAVEEN_ENT_RZP_KEY_ID,
      order_id: this.props.orderPayDetails.id,
      handler: (response: any) => this.callPaymentConfirm(response),
      prefill: {
        name:
          !isNull(this.props.guestFormDetails) &&
          !isNull(this.props.guestFormDetails['_mo_customer_name'])
            ? this.props.guestFormDetails['_mo_customer_name']
            : '',
        email:
          !isNull(this.props.guestFormDetails) &&
          !isNull(this.props.guestFormDetails['_mo_customer_email_id'])
            ? this.props.guestFormDetails['_mo_customer_email_id']
            : '',
        contact:
          !isNull(this.props.guestFormDetails) &&
          !isNull(this.props.guestFormDetails['_mo_customer_mobileno'])
            ? this.props.guestFormDetails['_mo_customer_mobileno']
            : '',
      },
      theme: {
        color: '#528ff0',
      },
    };
    options.order_id = this.props.orderPayDetails.id;
    const rzp1 = new (window as any).Razorpay(options);
    rzp1.open();
  };

  getCustomDataValue = (data: any) => {
    const summary = data && !isNull(data.summary) ? data.summary : {};
    return (
      <>
        <h5>Total Amount: {summary['total_amount'] || 'NA'}</h5>
        <h5>Total units: {summary['total_units'] || 'NA'}</h5>
        <h5>Total line items:{summary['total_line_items'] || 'NA'}</h5>
      </>
    );
  };

  payNow = () => {
    this.props.orderPay(
      !isNull(this.props.guestFormDetails) &&
        !isNull(this.props.guestFormDetails['_mo_instance_id'])
        ? this.props.guestFormDetails['_mo_instance_id']
        : null,
      this.props.router.params.publicId
    );
  };

  handleClose = () => {
    this.props.resetErrorMessage();
    this.props.resetSuccessMessage();
  };

  catalogItem = (item: any, index: number) => {
    return (
      <div
        key={item['instance_id'] || index}
        className="gsummary-catalog-item-wrapper"
      >
        <div className="gsummary-code-name-wrapper">
          <p>
            {item.product_name}/{item.product_code}
          </p>
          <p className="price">{`${item.currency} ${item.total_price}`}</p>
        </div>
        <div className="gsummary-price-wrapper">
          <p>
            {`Qty: `}
            <span>{`${item.product_quantity}`}</span>
          </p>
        </div>
      </div>
    );
  };

  getSKUSumaary = () => {
    return this.props.guestFormDetails['select_sku'] &&
      !isNull(this.props.guestFormDetails['select_sku']['summary'])
      ? this.props.guestFormDetails['select_sku']['summary']
      : null;
  };

  navigateUpdateForm = () => {
    const searchParams =
      this.props.router.location.state &&
      this.props.router.location.state['searchParams']
        ? this.props.router.location.state['searchParams']
        : '';
    if (!isNull(searchParams))
      this.props.router.navigate(
        `/p/naveenenterprises/${this.props.router.params.publicId}/update${searchParams}`
      );
    else
      this.props.router.navigate(
        `/p/naveenenterprises/${this.props.router.params.publicId}/update`
      );
  };

  render() {
    return (
      <div id="guest-summary-root">
        <div className="guest-summary-wrapper">
          <div className="guest-summary-header">
            <i
              className="fas fa-arrow-left"
              style={{ paddingRight: '20px' }}
              onClick={() => this.navigateUpdateForm()}
            />
            <p>Summary</p>
          </div>
          {(this.props.orderPayRequest || this.props.paymentConfirmRequest) && (
            <Spinner />
          )}
          <div className="guest-summary-content">
            <div className="guest-summary-content-wrapper">
              <div className="name-sku-list-wrapper">
                <div className="name-wrapper">
                  <h5>
                    Ship to:{' '}
                    <span>
                      {this.props.guestFormDetails['_mo_customer_name']}
                      {this.props.guestFormDetails['_mo_customer_state']
                        ? `, ${this.props.guestFormDetails['_mo_customer_state']}`
                        : ''}
                    </span>
                  </h5>
                  <textarea
                    defaultValue={`${this.props.guestFormDetails['_mo_customer_billing_address']}`}
                    rows={
                      this.props.guestFormDetails[
                        '_mo_customer_billing_address'
                      ] &&
                      this.props.guestFormDetails[
                        '_mo_customer_billing_address'
                      ].split('\n').length
                    }
                    className="address-textarea"
                  />
                  <h5 style={{ paddingTop: '10px' }}>
                    {this.props.guestFormDetails['_mo_customer_gstin']}
                  </h5>
                </div>
                {this.props.guestFormDetails['select_sku'] &&
                  this.props.guestFormDetails['select_sku']['values'].length >
                    0 && (
                    <div className="sku-list-title-wrapper">
                      <div className="sku-list-wrapper">
                        <p className="title">
                          <i
                            className="fas fa-check"
                            style={{ paddingRight: '10px', color: '#2AC940' }}
                          />
                          Shopping Cart:{' '}
                          {
                            this.props.guestFormDetails['select_sku']['values']
                              .length
                          }{' '}
                          Items Selected
                        </p>
                        {this.props.guestFormDetails['select_sku'][
                          'values'
                        ].map((item: any, index: number) => {
                          return this.catalogItem(item, index);
                        })}
                      </div>
                    </div>
                  )}
                <div className="button-wrapper">
                  <Button
                    className={`form-update-button ${
                      this.props.orderPayRequest ? 'disabled-button' : ''
                    }`}
                    onClick={() => this.navigateUpdateForm()}
                  >
                    EDIT ORDER
                  </Button>
                </div>
              </div>
              {!isNull(this.getSKUSumaary()) && (
                <div className="price-details-root">
                  <div className="price-details-wrapper">
                    <div className="account-details-wrapper">
                      <div className="value-wrapper">
                        <h5>
                          Subtotal (
                          {(this.props.guestFormDetails['select_sku'] &&
                            this.props.guestFormDetails['select_sku']['values']
                              .length) ||
                            ''}{' '}
                          Items )
                        </h5>
                        <h5>
                          {this.getSKUSumaary()['currency']}{' '}
                          {this.getSKUSumaary()['total_amount'] || 'NA'}
                        </h5>
                      </div>
                      <div className="value-wrapper">
                        <h5>Courier Charges</h5>
                        <h5>
                          {this.getSKUSumaary()['currency']}{' '}
                          {this.props.guestFormDetails['_mo_courier_cost']}
                        </h5>
                      </div>
                      <div className="value-wrapper">
                        <h5>Processing Charges</h5>
                        <h5>
                          {this.getSKUSumaary()['currency']}{' '}
                          {this.props.guestFormDetails['_mo_processing_fee']}
                        </h5>
                      </div>
                      <div className="value-wrapper">
                        <h5>Total Amount</h5>
                        <h5>
                          {this.getSKUSumaary()['currency']}{' '}
                          {this.getSKUSumaary()['total_amount'] +
                            this.props.guestFormDetails['_mo_processing_fee'] +
                            this.props.guestFormDetails['_mo_courier_cost'] ||
                            'NA'}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="button-wrapper">
                    <Button
                      className={`form-update-button ${
                        this.props.orderPayRequest ? 'disabled-button' : ''
                      }`}
                      onClick={this.payNow}
                    >
                      PLACE ORDER
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* <SnackbarMessage
          handleClose={this.handleClose}
          message={
            this.props.error.displayMessage || this.props.success.displayMessage
          }
          open={this.props.open}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    open: state.reportReducer.open,
    error: state.reportReducer.error,
    success: state.reportReducer.success,
    reportDetails: state.reportReducer.reportDetails,
    formDataDetails: state.reportReducer.formDataDetails,
    blobUrl: state.reportReducer.blobUrl,
    guestTemplateId: state.reportReducer.guestTemplateId,
    guestTeamId: state.reportReducer.guestTeamId,
    guestFormDetails: state.reportReducer.guestFormDetails,
    naveenCreateFormDataResponse:
      state.reportReducer.naveenCreateFormDataResponse,

    orderPayRequest: state.reportReducer.orderPayRequest,
    orderPaySuccess: state.reportReducer.orderPaySuccess,
    orderPayDetails: state.reportReducer.orderPayDetails,

    paymentConfirmRequest: state.reportReducer.paymentConfirmRequest,
    paymentConfirmSuccess: state.reportReducer.paymentConfirmSuccess,
    paymentConfirmDetails: state.reportReducer.paymentConfirmDetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    {
      resetErrorMessage,
      resetSuccessMessage,
      orderPay,
      paymentConfirm,
      setRazorpayResponse,
    },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Summary));
