import * as types from '../../../actions/types';

const initialState = {
  getTeams: false,
  myTeams: [],
  teamId: '',
};

const drawerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_MY_TEAMS_REQUEST:
      return {
        ...state,
        getTeams: false,
        myTeams: [],
      };

    case types.GET_MY_TEAMS_SUCCESS:
      return {
        ...state,
        getTeams: true,
        myTeams: action.payload,
      };

    case types.GET_MY_TEAMS_FAILURE:
      return {
        ...state,
        getTeams: false,
        error: {
          displayMessage: action.payload
            ? action.payload
            : 'Unable to process the request',
        },
      };

    case types.RESET_GET_MY_TEAMS_REQUEST:
      return {
        ...state,
        getTeams: false,
      };

    case types.SET_TEAM_ID:
      return {
        ...state,
        teamId: action.payload.teamId,
      };

    default:
      return state;
  }
};

export default drawerReducer;
