import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function initStore(preloadedState: any) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false}
      ).concat(sagaMiddleware),
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
