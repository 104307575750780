import React, { Component } from 'react';

import '../../styles/inbox/details.css';

export default class LongtextField extends Component<any, any> {
  span: any;
  constructor(props: any) {
    super(props);
    this.state = {
      overflowActive: false,
    };
  }

  isEllipsisActive = (e: any) => {
    return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
  };

  componentDidMount() {
    this.setState({ overflowActive: this.isEllipsisActive(this.span) });
  }

  render() {
    const { value, name, openLongtextModal } = this.props;
    return (
      <div
        style={{
          cursor: this.state.overflowActive ? 'pointer' : 'default',
        }}
        className="longtext-wrapper"
        onClick={() => {
          if (this.state.overflowActive) openLongtextModal(value, name);
        }}
      >
        <div className="longtext-span-wrapper" ref={(ref) => (this.span = ref)}>
          <span className="longtext-span">{value}</span>
        </div>
        {this.state.overflowActive && (
          <h6 style={{ width: '20%', padding: 0, color: '#00acf1' }}>
            show more
          </h6>
        )}
      </div>
    );
  }
}
