import React, { Component } from 'react';
import withRouter from 'withRouter';

import '../styles/workflowOptions.css';

interface IProps {
  workflow: any;
  resetReportDetails: any;
  router: any;
}

class WorkflowOptions extends Component<IProps, any> {
  componentDidMount() {
    this.props.resetReportDetails();
  }
  options = (index: number, option: any, templateId: string) => {
    switch (option.action) {
      case 'create':
        return (
          <div
            key={index}
            className="app-function-card"
            onClick={() => {
              this.props.router.navigate(
                `/member/${sessionStorage.getItem(
                  'teamId'
                )}/create/${templateId}`,
                {
                  state: {
                    returnPath:
                      this.props.router.location.pathname.split('/')[
                        this.props.router.location.pathname.split('/').length -
                          1
                      ],
                  },
                }
              );
            }}
          >
            <i className="fal fa-plus-circle" style={{ color: '#6471DE' }} />
            <p>{option.label}</p>
          </div>
        );
      case 'inbox':
        return (
          <div
            key={index}
            className="app-function-card"
            onClick={() => {
              this.props.router.navigate(
                `/member/${sessionStorage.getItem(
                  'teamId'
                )}/inbox/${templateId}`,
                {
                  state: {
                    returnPath:
                      this.props.router.location.pathname.split('/')[
                        this.props.router.location.pathname.split('/').length -
                          1
                      ],
                  },
                }
              );
            }}
          >
            <i className="fal fa-inbox" style={{ color: '#7ed321' }} />
            <p>{option.label}</p>
          </div>
        );
      case 'funnel':
        return (
          <div
            key={index}
            className="app-function-card"
            style={{ cursor: 'default', opacity: 0.4 }}
          >
            <i
              className="fal fa-tachometer-alt-slowest"
              style={{ color: '#00ACF1', cursor: 'initial' }}
            />
            <p>{option.label}</p>
          </div>
        );

      case 'inbox-unassigned':
        return (
          <div
            key={index}
            className="app-function-card"
            onClick={() => {
              this.props.router.navigate(
                `/member/${sessionStorage.getItem(
                  'teamId'
                )}/open/${templateId}`,
                {
                  state: {
                    returnPath:
                      this.props.router.location.pathname.split('/')[
                        this.props.router.location.pathname.split('/').length -
                          1
                      ],
                  },
                }
              );
            }}
          >
            <i
              className={`fal fa-${option.icon}`}
              style={{ color: '#7ed321' }}
            />
            <p>{'Open'}</p>
          </div>
        );
      default:
        return (
          <div
            key={index}
            className="app-function-card"
            style={{ cursor: 'default', opacity: 0.4 }}
          >
            <i
              className={`fal fa-${option.icon}`}
              style={{ color: '#00ACF1', cursor: 'initial' }}
            />
            <p>{option.label}</p>
          </div>
        );
    }
  };

  render() {
    return (
      <div id="app-function-wrapper">
        <div className="app-function-title">
          <h6>{this.props.workflow && this.props.workflow.name}</h6>
        </div>
        <div className="app-function-card-wrapper">
          {this.props.workflow &&
            JSON.parse(this.props.workflow.quick_launch).features.map(
              (item: any, index: number) => {
                if (!item.action.match(/funnel/i)) {
                  return this.options(index, item, this.props.workflow._id);
                }
                return null;
              }
            )}
        </div>
      </div>
    );
  }
}

export default withRouter(WorkflowOptions);
