import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TableFooter,
  TableRow,
  TablePagination,
  Table,
  CircularProgress,
} from '@mui/material';
import * as _ from 'lodash';

import {
  getLookupInstanceIds,
  getExternalLookupData,
} from '../../actions/report';
import SearchData from './../../component/searchData';
import { isNull } from '../../../../utils/helper';
import * as types from '../../../../actions/types';
import './../../styles/lookup/lookup.css';
import withRouter from 'withRouter';

class Lookup extends Component<any, any> {
  wrapperRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      rowsPerPage: 20,
      page: 0,
      lookupOptions: [],
      searchText: '',
      showClearButton: false,
    };
  }

  componentDidMount() {
    const attributes = this.props.attributes;
    this.getLookupIds(attributes);
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.getExternalLookupDataSuccess) {
      this.props.dispatch({
        type: types.RESET_GET_EXTERNAL_LOOKUP_DATA_REQUEST,
      });
      this.generateLookupData();
    }
    if (prevProps && prevProps.attributes.name !== this.props.attributes.name) {
      this.getLookupIds(this.props.attributes);
    }
  }

  getLookupIds = (attributes: any) => {
    if (
      attributes.type === 'lookup' &&
      attributes.data_source &&
      attributes.data_source.referenceId.trim() !== ''
    ) {
      this.getLookupIdsReq(attributes).then(() => {
        this.getLookupData(attributes.data_source.referenceId.trim());
      });
    }
  };

  getLookupIdsReq(attributes: any, searchQuery = '') {
    let sourceTemplateId = this.props.router.params.templateId;
    if (
      this.props.router.location &&
      this.props.router.location.pathname &&
      this.props.router.location.pathname.includes('/public') &&
      this.props.guestTemplateId
    ) {
      sourceTemplateId = this.props.guestTemplateId;
    }
    const referenceTemplateId = attributes.data_source.referenceId.trim();
    const fieldInternalName = attributes.name;
    const lookupDataSource = attributes.data_source;
    let filter = '';
    if (lookupDataSource && lookupDataSource.filterQuery) {
      filter = lookupDataSource.filterQuery;
    }
    if (!isNull(searchQuery)) {
      filter = `${filter}&${searchQuery}`;
    }
    return new Promise((resolve) => {
      this.props.getLookupInstanceIds(
        sourceTemplateId,
        referenceTemplateId,
        fieldInternalName,
        filter,
        resolve
      );
    });
  }

  /**
   * Get external lookup data
   */
  getLookupData = (referenceTemplateId: string) => {
    const startIndex = this.state.page * this.state.rowsPerPage;
    const endIndex =
      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage;
    if (this.props.lookupIds.length > 0) {
      const ids: any = [];
      this.props.lookupIds.slice(startIndex, endIndex).map((item: any) => {
        ids.push(item._mo_instance_id);
        return null;
      });
      this.props.getExternalLookupData(referenceTemplateId, ids);
    } else {
      this.setState({ lookupOptions: [] });
    }
  };

  generateLookupData = () => {
    const options: any = [];
    this.props.externalLookupData.map((item: any) => {
      const data = _.omit(
        item.data,
        '_mo_assignee',
        '_mo_created_at',
        '_mo_owner',
        '_mo_updated_at'
      );
      options.push(data);
      return null;
    });
    this.setState({ lookupOptions: options });
  };

  renderAddLookupButton = () => {
    const attributes = this.props.attributes;
    return this.props.isAddLookupEnable ? (
      <i
        className="fas fa-plus-circle"
        style={{ fontSize: 30, color: '#6471de', paddingLeft: '20px' }}
        onClick={() => {
          this.props.router.navigate(
            `/member/${this.props.router.params.teamId}/create/secondary/${attributes.data_source.referenceId}`,
            {
              state: {
                parentTemplateId: this.props.router.params.templateId,
                fields: this.props.extProps.fields,
                templateName: this.props.templateName,
                returnPathname: this.props.pathname,
              },
            }
          );
        }}
      />
    ) : null;
  };

  searchData = (data: string) => {
    const attributes = this.props.attributes;
    const searchText = this.state.searchText;
    if (searchText.trim() !== '' && data === 'search') {
      const searchQuery = `_ts_=${searchText}`;
      this.setState({ page: 0, showClearButton: true }, () => {
        this.getLookupIdsReq(attributes, searchQuery).then(() => {
          this.getLookupData(attributes.data_source.referenceId.trim());
        });
      });
    } else {
      this.setState({ searchText: '', page: 0, showClearButton: false });
      this.getLookupIdsReq(attributes, '').then(() => {
        this.getLookupData(attributes.data_source.referenceId.trim());
      });
    }
  };

  onChangeSearchData = (event: any) => {
    this.setState(
      { searchText: event.target.value, showClearButton: false },
      () => {
        if (this.state.searchText.trim() === '') this.searchData('search');
      }
    );
  };

  lookupTitle = () => {
    const attributes = this.props.attributes;
    return (
      <div
        className="lookup-modal-title-wrapper lookup-new-wrapper"
        style={{ marginBottom: '20px' }}
      >
        <div className="lookup-new-close-icon">
          <i
            className="far fa-times "
            onClick={() => {
              this.props.closeModal();
            }}
          />
        </div>
        <div className="lookup-new-title-search-wrapper">
          <div className="title">
            <p>{`${attributes['label']}`}</p>
          </div>
          <div className="search-filter-wrapper">
            <SearchData
              search={this.searchData}
              searchText={this.state.searchText}
              onChangeSearchData={this.onChangeSearchData}
              wrapperWidth="150px"
              background="white"
              searchInputWidth="100px"
              color="black"
              showClearButton={this.state.showClearButton}
            />
            {!isNull(attributes) &&
            !isNull(attributes['additional']) &&
            attributes['additional']['hide_inline_creation']
              ? null
              : this.renderAddLookupButton()}
          </div>
        </div>
      </div>
    );
  };

  handleChange = (data: any) => {
    this.props.extProps.updateValue(this.props.attributes.name, data);
    this.props.closeModal();
  };

  generateLookupListUI = () => {
    const attributes = this.props.attributes;
    return (
      <div className="lookup-modal-content-wrapper">
        {this.props.getLookupInstanceIdsSpinner ||
        this.props.getExternalLookupDataSpinner ? (
          <div className="lookup-data-spinner">
            <CircularProgress
              style={{ width: '80px', height: '80px', color: '#6471de' }}
            />
          </div>
        ) : (
          this.state.lookupOptions.length > 0 &&
          this.state.lookupOptions.map((item: any, index: number) => {
            return (
              <div
                key={index}
                className="lookup-options-value"
                onClick={() => this.handleChange(item)}
              >
                {item[attributes.labelKey]}
              </div>
            );
          })
        )}
      </div>
    );
  };

  handleChangePage = (event: any, page: any) => {
    const attributes = this.props.attributes;
    this.setState({ page }, () => {
      this.getLookupData(attributes.data_source.referenceId);
      const element: any = document.getElementById('lookup-modal-wrapper');
      element.scrollIntoView(true);
    });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  pagination = () => {
    return (
      <Table>
        <TableFooter className="tabel-footer">
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[20]}
              count={this.props.lookupIds.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    );
  };

  render() {
    return (
      <div className="lookup-modal-root">
        <div id="lookup-modal-wrapper">
          {this.lookupTitle()}
          {this.generateLookupListUI()}
          {this.pagination()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    error: state.reportReducer.error,
    open: state.reportReducer.open,
    lookupIds: state.reportReducer.lookupIds,
    externalLookupData: state.reportReducer.externalLookupData,
    getExternalLookupDataSuccess:
      state.reportReducer.getExternalLookupDataSuccess,
    getLookupInstanceIdsSpinner:
      state.reportReducer.getLookupInstanceIdsSpinner,
    getExternalLookupDataSpinner:
      state.reportReducer.getExternalLookupDataSpinner,
    guestTemplateId: state.reportReducer.guestTemplateId,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
  ...bindActionCreators(
    { getLookupInstanceIds, getExternalLookupData },
    dispatch
  ),
});

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Lookup));
