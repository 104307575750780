import React from 'react';
import '../styles/basicUser.css';

// Only member in professional or enterprice teams
const User = () => {
  return (
    <div className="basic-container ">
      <div className="upgrade-company-logo">
        <img
          src={require('../../../assets/images/userOnBoarding/logo-big.png')}
          alt="logo"
          className="logo"
        />
      </div>
      <h1>Hope you are having a great experience using MobileOffize</h1>
      <h6>
        Please contact your Team Administrator to access the Admin Portal.
      </h6>
    </div>
  );
};

export default User;
